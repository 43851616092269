import React from 'react';
import Header from "../components/custom/header";
import UploadDocuments from '../components/claims-documents/upload-documents';
import SessionTimeout from "../components/session-timeout";

const UploadDocumentsPage = () => {

    return (
        <React.Fragment>
            {
                <div>
                    <Header />
                    <UploadDocuments/>
                    <SessionTimeout/>
                </div>
            }
        </React.Fragment>
    )
}

export default (UploadDocumentsPage);
