import { makeStyles } from "@material-ui/core/styles";

const useReloadStyles = makeStyles( () => ({

  claims_title: {
    width: '100%',
    marginTop: '30px',
    '& h2' : {
      fontFamily: 'Stag, sans-serif',
      color: '#003DA5',
      fontSize: '32px',
      letterSpacing: '1px',
      fontWeight: '400'
    }
  },

  claims_subtitle: {
    width: '100%',
    marginTop: '30px',
    '& h2' : {
      fontFamily: 'Stag, sans-serif',
      color: '#003DA5',
      fontSize: '13px',
      letterSpacing: '1px',
      fontWeight: '400'
    }
  },

  claims_rectangle: {
    margin: '0 auto',
    height: '406px',
    width: '600px',
    borderRadius: '0 0 8px 8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '2px 2px 8px 0 rgba(20,40,75,0.2)',
    textAlign: 'center',
    paddingTop: '50px'
  },

  member_input: {
    margin: "0 auto",
    width: "320px",
    maxWidth: "320px",
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '& div' : {
      textAlign: 'center',
    },
    '& .MuiFormControl-root.claimNumber' : {
      marginTop: '40px',
    },
    '& button' : {
      marginTop: '40px',
    }
  },

  errorMessage: {
    marginBottom: "10px",
      "& .alert": {
        marginTop: "0px"
      }
  }

}));

export default useReloadStyles;