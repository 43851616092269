import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styled from 'styled-components';
import {mdDownN} from "../ui/common";
import QnectButton from "../components/qnect-ui/qnect-button";
import {_getLang, _hideModal, _setLang, _showModal} from "../store/system/service";

const SectionStyle = styled.div`
    margin: 0 auto;
    text-align: center;
    border: 1px solid rgb(225, 228, 232);
`;

const InputGroupStyle = styled.div`
    width: 346px;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 22px;
`;

const DefaultHeadingStyle = styled.div`
    margin-left: auto;
    margin-right: auto;
    color: #0A1F44;
    font-family: Inter-Medium;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
    display:inline-block;
    ${mdDownN`
        color: #0A1F44;
        font-family: Inter-Medium;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
    `}
`;

const NavigationPage = (props) => {

    return (<>
        <SectionStyle>
            <DefaultHeadingStyle>
                Navigation
            </DefaultHeadingStyle>
            <InputGroupStyle>
                <QnectButton field="login" onClick={() => {props.history.push("/login")}}>
                    Login
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton onClick={() => {props.history.push("/claims")}}>
                    Claim Status Checking
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton onClick={() => {props.history.push("/resetPassword")}}>
                    Reset Password
                </QnectButton>
            </InputGroupStyle>
        </SectionStyle>
    </>);
};

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
};

const mapDispatchToProps = {
    showModal: _showModal,
    hideModal: _hideModal,
    setLang: _setLang
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationPage));
