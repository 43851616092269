import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Background from "../../assets/login/background.png";

const useLoginStyles = makeStyles(theme => ({
  container: {
    width: "100%"
  },
  bg: {
    [theme.breakpoints.down("md")]: {
      display: "block",
      minHeight: "calc(100vh - 64px)",
      backgroundImage: `url(${Background})`,
      backgroundSize: "cover",
      backgroundPosition: "right",
      position: "relative",
      overflow: "hidden"
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 87px)"
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex"
    }
  },
  Mtop: {
    padding: "0 20px"
  },
  Mheader: {
    paddingTop: "22px",
    height: "44px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  Mlogo: {
    width: "70px"
  },
  Mlanguage: {
    color: "#fff",
    fontWeight: "500"
  },
  Mbuttons: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "332px",
    paddingTop: "110px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, #2B2B2B 100%)"
  },
  Mbutton: {
    "& button": {
      width: "245px",
      marginBottom: "20px",
      "&:nth-child(2)": {
        marginBottom: "30px"
      }
    }
  },
  Mbutton_white: {
    "& button": {
      color: "#1d4fbb",
      backgroundColor: "#ffffff",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#fff !important"
      }
    }
  },
  Mform: {
    [theme.breakpoints.down("sm")]: {
      height: "437px",
      width: "100%",
      // paddingTop: "20px",
      borderRadius: "20px 20px 0 0",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 -12px 30px 0 rgba(10,31,68,0.1)",
      position: "absolute",
      bottom: "-437px",
      left: "0",
      transition: "all 1s ease"
      // transform: "translateY(-437px)"
    },
    [theme.breakpoints.up("sm")]: {
      transform: "none !important"
    }
  },
  Cbg_img: {
    flex: 1,
    display: "block",
    minHeight: "calc(100vh - 87px)",
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundPosition: "50% 75%"
  },
  right_content: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.between("sm", "md")]: {
      width: "596px",
      height: "768px",
      margin: "96px auto 0",
      borderRadius: "20px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 0 60px 0 rgba(0,0,0,0.06)",
      overflow: "auto",
      position: "relative"
    },
    [theme.breakpoints.up("lg")]: {
      flex: 1,
      minHeight: "960px",
      position: "relative"
    }
  },

  icon_wrapper: {
    position: "relative",
    width: "434px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.between("sm", "md")]: {
      // paddingTop: "52px",
      margin: "50px auto 30px"
    },
    [theme.breakpoints.up("lg")]: {
      margin: "113px auto 30px"
    }
  },
  
  language_select: {
    color: "#A7AEBB",
    // fontWeight: "bold",
    fontSize: "20px",
    position: "absolute",
    right: "0",
    top: "50%",
    cursor: "pointer",
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "-13.5px"
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "-7.5px"
    }
  },

  title: {
    marginTop: "16px",
    fontFamily: "Stag",
    fontWeight: "500",
    letterSpacing: "1px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "36px",
      color: "#FFFFFF"
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      height: "40px",
      lineHeight: "40px",
      color: "#0a1f44"
    },
    [theme.breakpoints.between("sm", "md")]: {
      margin: "0 auto",
      fontSize: "28px",
      letterSpacing: "0.88px"
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0 auto 20px",
      fontSize: "32px"
    }
  },
  subtitle: {
    fontFamily: "Stag",
    fontWeight: 500,
    letterSpacing: "1px",
    textAlign: "center",
    color: "#0A1F44",
    margin: "50px auto 0",
    maxWidth: "600px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0px 4px",
      marginTop: "20px",
      minHeight: "28px",
      fontSize: 18,
      lineHeight: "24px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px 24px 80px",
      minHeight: "43px",
      fontSize: 32,
      lineHeight: "36px",
    }
  },

  tab_panel: {
    margin: "0 auto",
    maxWidth: "434px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 36px"
    },
    [theme.breakpoints.up("lg")]: {}
  },

  member_input: {
    position: "relative",
    height: "188px",
    fontFamily: "Inter",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#8a94a6",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px"
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "40px"
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "53px"
    }
  },
  forgotPassword: {
    color: "#003DA5",
    fontWeight: 600,
  },
  forgetEmail: {
    position: "absolute",
    top: "0",
    right: "0",
    boxSizing: "border-box",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#8a94a6",
    fontFamily: "Inter",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("lg")]: {}
  },
  button_wrapper: {
    display: "flex",
    marginTop: "70px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: "55px",
      "& button": {
        width: "245px"
      }
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end"
    }
  },
  [theme.breakpoints.down("sm")]: {
    policy_input: props => ({
      "& .BalloonTip": props => ({
        left: props.lang === "en" ? "116px" : "68px"
      })
    })
  },
  [theme.breakpoints.up("sm")]: {
    policy_input: props => ({
      "& .BalloonTip": props => ({
        left: props.lang === "en" ? "130px" : "76px"
      })
    })
  },
  policy_input: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {

      "& > .BalloonTip": {
        position: "absolute",
        top: "0px",
        // left: "116px",
        cursor: "pointer"
      },
      "& > .BalloonTip .contentDiv": {
        bottom: "4px",
        top: "auto",
        left: "0px",
        // minWidth: "auto",
        background: "#4e5d78"
      }
    },
    [theme.breakpoints.up("sm")]: {

      "& > .BalloonTip": {
        position: "absolute",
        top: "0px",
        // left: "130px",
        cursor: "pointer"
      },
      "& > .BalloonTip .contentDiv": {
        bottom: "0px",
        top: "auto",
        width: "380px",
        background: "#4e5d78"
      }
    },
    [theme.breakpoints.up("lg")]: {}
  },
  how_find: {
    display: "inline-block",
    lineHeight: "24px",
    color: "#003da5",
    fontFamily: "Inter",
    textDecoration: "underline",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
      marginBottom: "20px",
      fontSize: "15px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "16px",
      marginBottom: "20px",
      fontSize: "18px"
    }
  },
  qr_code: {
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px !important",
    color: "#8a94a6",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      left: "50%",
      width: "500px",
      marginLeft: "-250px",
      fontSize: "18px"
    },
    [theme.breakpoints.between("sm", "md")]: {
      bottom: "40px"
    },
    [theme.breakpoints.up("lg")]: {
      bottom: "40px"
    }
  },

  no_account: {
    textAlign: "center",
    fontFamily: "Inter",
    color: "#8a94a6",
    position: "relative",
    left: "-121px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: '434px',
      boxSizing: 'border-box',
      paddingLeft: '36px',
      textAlign: 'left',
      marginTop: "30px",
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: "16px",
      lineHeight: "20px",
      left: "0"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px"
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "40px",
      marginBottom: "30px",
      marginLeft: "64px"
    },
    [theme.breakpoints.up("lg")]: {
        marginTop: "40px",
        marginBottom: "30px",
        marginLeft: "64px"
    }
  },
  no_account_zh:{
    [theme.breakpoints.up("sm")]: {
      left: '-173px',
    },

    [theme.breakpoints.down("sm")]: {
        maxWidth: '434px',
        boxSizing: 'border-box',
        paddingLeft: '36px',
        textAlign: 'left',
        marginTop: "30px",
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: "16px",
        lineHeight: "20px",
        left: "0"
    },
  },
  register_link: {
    fontWeight: "bold",
    cursor: "pointer",
    color: "#003da5",
    paddingLeft: "2px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px"
    }
  },
  Mno_account: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center',
      marginTop: "0px",
      paddingLeft: '0px',
    }
  },
  Mregister_link: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '0px',
    }
  },
  PreWrap: {
    whiteSpace: "pre-wrap"
  },
  forgotEmailPassword: {
    textAlign: "center",
    fontFamily: "Inter-Regular",
    marginTop: "38px",
    fontSize: "18px",
    color: "#003DA5",
		fontWeight: 600,
    cursor: "pointer"
  },
  personalLogin: {
    fontFamily: "Stag",
    fontSize: "24px",
    textAlign: "center",
    color: "#003DA5",
    borderBottom: "1px solid #C9CED6",
    paddingBottom: "14px",
    [theme.breakpoints.up("sm")]: {
      width: "434px",
    }
  },
  corporateCustomers: {
    textAlign: "center",
    fontFamily: "Inter-Regular",
    fontSize: "18px",
    color: "#8A94A6",
    width: "403px",
    margin: "38px auto 0",
    ['@media screen and (max-width: 350px)']: {
      maxWidth: 'calc(100vw - 50px)',
      paddingBottom: '30px',
    },
    [theme.breakpoints.between("sm","md")]: {
      margin: "8px auto 0",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '14px',
      maxWidth: 'calc(100vw - 77px)',
      marginTop: '21px',
    }
  },
  login_tooltip: {
    position: 'absolute',
    top: '15px',
    left: 'calc(100% + 10px)',
    zIndex: 9,
    '& .imageDiv img' : {
      left: '-44px',
      top: '24px',
    },
    '& .ToolTipDiv' : {
      top: '27px',
      left: 'calc(100% - 10px)',
      '& .contentDiv' : {
        boxShadow: '4px 4px 12px rgba(20,40,75,0.1)',
        background: '#fff',
        color: '#0A1F44',
        minWidth: '210px',
        padding: '20px',
        '&::before' : {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          position: 'absolute',
          borderTop: '8px solid transparent',
          borderBottom: '8px solid transparent',
          borderRight: '8px solid #fff',
          left: '-8px',
          top: 'calc(50% - 10px)',
          zIndex: '9999',
        },
      },
    },
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.between("sm", "md")]: {},
    [theme.breakpoints.up("lg")]: {}
  },

  errorMessage: {
    marginBottom: "10px",
      "& .alert": {
        marginTop: "0px"
      }
  }

}));

export const LanguageSelectDiv = styled.div`
  color: #a7aebb;
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -7.5px;
  cursor: pointer;
`;

export const StyledModalDiv = styled.div`
  width: 504px;
  text-align: center;

  div.iconDiv {
    margin-top: 40px;
  }

  div.title {
    font-family: Stag-Medium;
    font-size: 24px;
    color: #003da5;
    line-height: 36px;
    margin-top: 16px;
  }

  div.content {
    margin-top: 41px;
    margin-bottom: 40px;
    font-family: Inter-Regular;
    font-size: 18px;
    white-space: pre-wrap
  }

  div.buttonDiv {
    margin-top: 40px;
    margin-left: 161px;
    margin-right: 163px;
  }
  @media (max-width:767.95px) {
     width: auto;
     div.buttonDiv {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export default useLoginStyles;
