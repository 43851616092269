import React, {useEffect, useState} from "react";
import useInvoiceStyles, {
    BackIcon,
    BackWrapper,
    Container,
    StyledNameDiv,
    Title,
    TopWrapper,
    TableWrapper
} from "./invoice-style";
import {
    _getCountry, _getLang, _hideSpinner, _showSpinner,
    _showModal, _hideModal, _showError
}
    from "../../store/system/service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import useConfig from "../qnect-ui/qnect-config";
import Hidden from "@material-ui/core/Hidden";
import Box from "@mui/material/Box/Box";
import {_getClaim} from "../../store/claims/service";
import MaterialUiTable from "../ui/material-ui-table/material-ui-table";
import Typography from "@material-ui/core/Typography/Typography";
import {tagManagerPopup, _getCurrency, formatDate, _formatAmount} from "../../utils/utility";
import InvoiceMethodDialog from "./invoice-method-dialog";
import {_getInvoices, _storeInvoice} from "../../store/invoice/service";

const ViewInvoice = (props) => {
    const cfg = useConfig(props.lang);
    const classes = useInvoiceStyles(props);
    const [data, setData] = useState([]);
    const [openInvoiceMethodDialog, setInvoiceMethodDialog] = useState(false);
    const currency = _getCurrency(props.country);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        loadInvoices();
    }, []);

    const loadInvoices = () => {
        props.showSpinner();
        props.getInvoices().then((response) => {
            if (response.status === 'success' && response.invoices) {
                setData(response.invoices);
            }
            props.hideSpinner();
        }).catch(() => {
            setIsError(true);
            props.hideSpinner();
        });
    }
      
    const back = () => {
        props.history.push("/claimsDetails");
    };

    const MyTitle = ({text, variant}) => (
        <div>
            <Typography variant={variant}
                        style={{whitespace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: '100%'}}>
                {text} - {props.claim?.claimNumber}
            </Typography>
        </div>
    );

    const columns = [
        {title: 'INVOICE NO.', field: 'invoiceNumber', sorting: false,
            render: rowData => <StyledNameDiv onClick={() => handleViewInvoiceDetails(rowData)}>{rowData.invoiceNumber}</StyledNameDiv>
        },
        {title: 'CALCULATION TYPE', field: 'type', sorting: false},
        {title: 'PROVIDER INVOICE NO.', field: 'providerInvoiceNumber', sorting: false},
        {title: props.country !== 'HKG' ? 'INVOICE AMOUNT' : 'INVOICE FEES', field: 'totalFee', sorting: false,
            render: rowData => <div>{currency + ' ' + _formatAmount(rowData.totalFee,true)}</div>
        },
        {title: 'STATUS', field: 'status', sorting: true},
        {title: 'SUBMITTED BY', field: 'submittedBy', sorting: true},
        {title: 'DATE SUBMITTED', field: 'dateSubmitted', sorting: true,
            render: rowData => formatDate(rowData.dateSubmitted)
        }
    ];

    const dummy = [
        {invoiceNumber: 'IN-HK-1000', type: 'By Claim Amount', providerInvoiceNumber: 'INV0001', totalFee: '1,000', status: 'Under Review', dateSubmitted: '2022-06-09-16.00.00.000000'}
    ];

    const localization = {
        body: {
            emptyDataSourceMessage: !isError ?
                <><strong style={{fontSize: '18px'}}>{cfg('invoice.noData1')}</strong><br/>
                    <span style={{fontSize: '14px'}}>{cfg('invoice.noData2')}</span>
                </> :
                <><strong style={{fontSize: '22px'}}>{cfg('invoice.maintenanceData1')}</strong><br/>
                    <span style={{fontSize: '18px'}}>{cfg('invoice.maintenanceData2')}</span>
                </>
        }
    };

    const callInvoiceMethodDialog = () => {
        setInvoiceMethodDialog(true);
        tagManagerPopup('Create Invoice');
    };

    const handleViewInvoiceDetails = (row) => {
        props.storeInvoice(row);
        props.history.push("/viewInvoiceDetails");
    }

    return (
        <>
            <Container>
                <div className={classes.box}>
                    <TopWrapper>
                        <Hidden mdDown>
                            <BackWrapper onClick={() => back()}>
                                <BackIcon/>{cfg('backBtn.label')}
                            </BackWrapper>
                            <Title>{cfg('invoice.yourInvoice')}</Title>
                        </Hidden>
                    </TopWrapper>
                </div>
                <div className={classes.page_container}>
                    <Box sx={{ width: '100%' }}>
                        <br/><br></br>
                        <TableWrapper>
                             <MaterialUiTable
                                columns={columns}
                                data={data}
                                title={<MyTitle variant="h6" text="Submitted Invoices" />}
                                onClickAdd={() => callInvoiceMethodDialog()}
                                addText={cfg('invoice.createInvoice')}
                                localization={localization}
                                isError={isError}
                             />
                        </TableWrapper>
                    </Box>
                </div>

                <InvoiceMethodDialog open={openInvoiceMethodDialog}
                                     onClose={() => setInvoiceMethodDialog(false)}>
                </InvoiceMethodDialog>

            </Container>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        lang: _getLang(state),
        claim: _getClaim(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showModal: _showModal,
    hideModal: _hideModal,
    showError: _showError,
    getInvoices: _getInvoices,
    storeInvoice: _storeInvoice
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewInvoice));
