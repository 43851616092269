import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from '../../assets/Close.svg';
import styled from "styled-components";
import {smDown} from "../../ui/common";

export const CheckBoxWrapper = styled.div`
    & label {
    margin: 0px auto 0;
}
`

export const StyledCheckBoxErrorDiv = styled.div`
    display: block;
    color: #a50000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    padding: 0px;
    margin: 10px 0px 10px 0px;
    text-align: left;
`;

export const StyledCheckBoxContent = styled.div`
    height: 48px;
    color: #000000;
    font-family: Inter;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    
    ${smDown`
            height: 95px;
            width: 265px;
    `}
`;

export const useDialogStyles = makeStyles(theme => ({
    dialog: {
        borderRadius: '12px',
        "& .MuiPopover-root .MuiList-root .MuiMenuItem-root" : {
            height: '40px',
            lineHeight: '20px',
        },
        "& .MuiListItem-root:first-child" : {
            display: 'none',
        },
        ".MuiPopover-root .MuiList-root .MuiListItem-button:hover" : {
            backgroundColor: '#F7F8F9',
            color: '#0A1F44#',
        },
        "& .MuiPopover-paper" : {
            top: '610px',
        },
        "& .MuiDialog-paper": {
            [theme.breakpoints.between("sm", "md")]: {
                padding: "14px 60px 52px"
            },
            [theme.breakpoints.up("lg")]: {
                padding: "14px 76px 52px",
                width: "630px"
            }
        },
        [theme.breakpoints.up("sm")]: {
            "& .MuiDialogTitle-root": {
                padding: "16px 0"
            }
        }
    },
    title: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-15px"
        },
        [theme.breakpoints.up("sm")]: {
            alignItems: "center",
            marginLeft: "30px"
        }
    },
    back: {
        [theme.breakpoints.down("sm")]: {
            width: "24px",
            height: "24px",
            marginRight: "12px"
        },
        [theme.breakpoints.up("sm")]: {
            width: "28px",
            height: "28px",
            marginRight: "16px"
        }
    },
    message: {
        textAlign: "center",
        color: "#0a1f44",
        fontFamily: "Inter",
        [theme.breakpoints.down("sm")]: {
            marginTop: "4px",
            fontSize: "16px",
            lineHeight: "20px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "20px",
            fontSize: "18px",
            lineHeight: "25px"
        }
    },
    actions: {
      marginTop: '40px',
      marginBottom: '40px',
      borderTop: '0',
      minHeight: '82px', /* prevents the dialog to increase in height on button hover */
      '& button, button:hover' : {
        fontFamily: 'Inter Medium, Inter-Medium, Inter, sans-serif',
        fontSize: '14px',
        textAlign: 'center',
        width: 'fit-content',
        borderRadius: '30px',
        padding: '16px 27px !important',
        height: 'fit-content',
        marginLeft: '10px',
        marginRight: '10px',
      },
      '& .MuiDialogActions-spacing > :not(:first-child)' : {
        marginLeft: '0'
      },
      [theme.breakpoints.down("sm")]: {
          marginTop: "40px"
      },
      [theme.breakpoints.up("sm")]: {
          marginTop: "57px"
      }
    },

    modal_bg: {
        position: 'fixed',
        zIndex: '10',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        background: 'rgba(0,0,0,0.7)',
      },
      modal_box_container: {
        position: 'relative',
        '& .MuiDialogTitle-root' : {
          padding: '16px 50px 0 50px',
        },
        '& .MuiDialogTitle-root h2 div:first-child' : {
          fontFamily: 'Stag Medium, Stag-Medium, Stag, sans-serif',
          fontSize: '24px',
          lineHeight: '36px',
          color: "#003DA5",
          width: '100%',
          textAlign: 'center',
          marginBottom: '40px',
          letterSpacing: '1px',
          marginTop: '15px'
        },
        '& .MuiDialogContent-root' : {
          paddingLeft: '100px',
          paddingRight: '100px',
        },
        '& .modal_box' : {
          width: '600px',
          height: 'fit-content',
          borderRadius: '10px',
          background: '#fff',
          border: '1px solid #E1E4E8',
          position: 'absolute',
          top: '100px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: '100',
          padding: '25px 50px 50px 50px',
        },
        '& h2' : {
          fontFamily: 'Inter Semi Bold, Inter-Semibold, Inter Semi-bold, Inter, sans-serif',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#0A1F44',
          textAlign: 'center',
          marginBottom: '40px',
        },
        '& .confirm_action_container' : {
          fontSize: '18px',
          fontFamily: 'Inter Regular, Inter-Regular, Inter, sans-serif',
          color: '#0A1F44',
          lineHeight: '27px',
          textAlign: 'center',
          flexBasis: '100%',
        },
        '& .input_buttons' : {
          flexBasis: '100%',
          display: 'flex',
          justifyContent: 'center',
        },
      },
      modal_box_form_container: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '45px',
        '& .MuiInputBase-input.Mui-disabled' : {
          backgroundColor: '#f1f2f4 !important',
        },
        '& .MuiFormControl-root .MuiInputBase-root.Mui-disabled' : {
          backgroundColor: '#f1f2f4',
        },
        '& .MuiIconButton-root:disabled, .MuiSelect-icon.Mui-disabled' : {
          opacity: '0.3',
        },
        '& .modal_box_form_field' : {
          flex: '50%',
        },
        '& .modal_box_form_field > div' : {
          marginTop: '0',
        },
        '& .MuiFilledInput-adornedEnd' : {
          paddingRight: '0',
        },
        '& .MuiFormLabel-root' : {
          color: '#003DA5 !important',
          fontFamily: 'Inter-Medium, Inter Medium, Inter, sans-serif !important',
          fontWeight: '500',
        },
      },
      small_container: {
        width: '600px !important',
        background: 'red',
      },
      modal_box_container_small: {
        width: '600px',
        height: 'fit-content',
        borderRadius: '10px',
        background: '#fff',
        border: '1px solid #E1E4E8',
        position: 'absolute',
        top: '100px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: '100',
        padding: '25px 50px 50px 50px',
      },
      modal_box_table_container: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '45px',
        '& .modal_box_table_data_container' : {
          flexBasis: '100%',
        },
        '& .modal_box_table_data_container table' : {
          width: '100%',
          fontFamily: 'Inter Regular, Inter-Regular, Inter, sans-serif',
          fontSize: '12px',
          lineHeight: '18px',
          color: '#0A1F44',
          '& th' : {
            width: '18%',
            paddingTop: '10px',
            paddingBottom: '10px',
            borderBottom: '1px solid #000',
            textAlign: 'left',
          },
          '& td' : {
            width: '18%',
            paddingTop: '10px',
            paddingBottom: '10px',
            borderBottom: '1px solid #C9CED6',
          },
          '& th:last-child, td:last-child' : {
            textAlign: 'right',
            paddingRight: '10px',
          },
          '& tr' : {
            display: 'table',
            width: '100%',
          },
          '& tbody' : {
            display: 'block',
            overflowY: 'scroll',
            maxHeight: '300px',
          },
          '& tbody::-webkit-scrollbar' : {
            width: '5px',
          },
          '& tbody::-webkit-scrollbar-track' : {
            background: '#E1E4E8',
          },
          '& tbody::-webkit-scrollbar-thumb' : {
            background: '#b4b9c2',
          },
          '& tbody::-webkit-scrollbar-thumb:hover' : {
            background: '#8A94A6',
          }
        }
      },
      modal_box_title: {
        position: 'relative',
        '& .close_modal' : {
          position: 'absolute',
          top: '-20px',
          right: '-30px',
          width: '16px',
          height: '16px',
          backgroundImage: `url(${CloseIcon})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }
      },

      selectEntityLabel: {
        margin: "0 auto",
        fontFamily: "Inter Semi-bold, Inter-Semi-bold, Inter Semibold, Inter Semi bold, Inter, sans-serif",
        fontWeight: "500",
        color: "#00205B",
        textAlign: "center",
        width: "auto",
        marginBottom: '0px',
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
          lineHeight: "20px"
        },
        [theme.breakpoints.up("sm")]: {
          fontSize: "16px",
          lineHeight: "24px"
        }
      }
}));