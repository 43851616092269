import React, {useEffect, useRef} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import useUploadDocumentStyles from "./upload-document-style";
import {ReactComponent as AddFile} from "../../assets/AddFile.svg"
import Button from "../../ui/components/buttons/button";
import {
    _getCountry,
    _getLang,
    _hideModal,
    _hideSpinner,
    _showError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import {_getClaim} from "../../store/claims/service";
import useConfig from "../qnect-ui/qnect-config";
import {_addFile, _deleteFile, _getDocument, _uploadDocuments} from "../../store/documents/service";
import {_validateForm, createUniqueIdentifier, tagManagerClickEvent, tagManagerPopup} from "../../utils/utility";
import QnectTextField from "../qnect-ui/qnect-text-field";
import FileItem from "../ui/file-item/file-item";

const UploadDocument = ({attachments, setAttachments, identifier, setIdentifier,...props}) => {

    const cfg = useConfig(props.lang);
    const classes = useUploadDocumentStyles(props);
    const fileInputRef = useRef(null);
    const formId = 'uploadDocsForm';
    const ref = useRef(null);
    const MAX_NUMBER_UPLOAD_FILE_LIMIT = 20;

    useEffect(() => {
        setIdentifier(createUniqueIdentifier());
    }, []);

    const deleteFile = (item) => {
        if(item && item.docID && item.docFileName){
            props.showSpinner();
            props.deleteFile(item.docID, identifier).then(resp => {
                if(resp.status === 200 && attachments && attachments.length > 0){
                    let data = attachments.filter(docForm => docForm.docID !== item.docID && docForm.docFileName !== item.docFileName);
                    setAttachments([]);
                    setAttachments(data);
                }
                props.hideSpinner();
            }).catch(error => {
                console.error("delete file",  error);
                props.hideSpinner();
                props.showError(['Remove File Error', error.message || error]);
                tagManagerPopup('Remove File Error', props.claim?.claimNumber);
            });
        }
        tagManagerClickEvent('Icon-click', 'delete');
    }

    const addFile = (event) => {
        if (attachments.length >= MAX_NUMBER_UPLOAD_FILE_LIMIT) {
            props.showError(['File Attachment Error', cfg('uploadDocuments.maxFilesUpload')]);
            return;
        }

        if(event.target.files && event.target.files.length > 0){
            const file = event.target.files[0];
            if (isFileValid(file)) {
                const newAttachments = [];
                if(attachments && Array.isArray(attachments)){
                    newAttachments.push(...attachments);
                }
                props.showSpinner();
                props.addFile(file, identifier).then(resp => {
                    if(resp.status === 200){
                        if(resp.data && resp.data.documentForm){
                            newAttachments.push({...resp.data.documentForm});
                            setAttachments(newAttachments);
                        } else {
                            // Upload failed
                            if (resp.data.errorMsg) {
                                props.showError(['File Attachment Error', resp.data.errorMsg]);
                            } else {
                                const errors = Array.isArray(resp.data.errors) ? [ ...resp.data.errors] : [resp.data.errors];
                                props.showError(['File Attachment Error', ...errors]);
                            }
                            tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
                        }
                    }
                    props.hideSpinner();
                }).catch(error => {
                    console.error("add file error",  error);
                    props.hideSpinner();
                    props.showError(['File Attachment Error', error.message || error]);
                    tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
                });
                
            }
        }
        event.target.value = null;
    }

    const isFileValid = (file) => {
        let valid = false;
        const fileName = file.name || '';
        const fileExt = fileName.substring(fileName.lastIndexOf('.')+1, fileName.length) || fileName;
        if (file.size > cfg('uploadDocuments.maxFileSize')) {
            props.showError(['File Attachment Error','Only files with 20MB size or less is allowed']);
            tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
        } else if (fileName.length > cfg('uploadDocuments.maxFileNameLength')) {
            props.showError(['File Attachment Error','The file name cannot be longer than 80 characters']);
            tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
        } else if('DOC,DOCX,XLS,XLSX,PDF,TXT,JPEG,JPG,PNG,GIF,AVI,MGG,MP4,MOV,WMV'.indexOf(fileExt.toUpperCase()) === -1){
            props.showError(['File Attachment Error','Only .doc, .docx, .xls, .xlsx, .pdf, .txt, .jpeg, .jpg, .png, .gif, .avi, .mgg, .mp4, .mov, .wmv files are allowed']);
            tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
        } else if (isFileDuplicate(file)) {
            props.showError(['File Attachment Error','File already added.']);
            tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
        } else {
            valid = true;
        }
        return valid;
    }

    const isFileDuplicate = (file) => {
        return attachments.some(element => element.docFileName === file.name);
    }

    const handleAddFiles = () => {
        if (_validateForm(formId)) {
            fileInputRef.current.click();
        }
    };

    const setDescriptionToArray = (index, value) => {
        setAttachments(prev => {
            return prev.map((obj, i) => {
                if (i === index) {
                    return {...obj, description: value};
                }
                return obj;
            });
        });
    };

    return (
        <>
            <div className={classes.allowedFormatSize}>{cfg('uploadDocuments.allowedFormatSize')}</div>
            <div style={{marginTop: '40px', marginBottom: '20px'}}>
                <input type="file" hidden ref={fileInputRef} onChange={addFile}></input>
                <Button color="secondary" onClick={() => handleAddFiles()}>
                    <div className={classes.whiteButtonStyle}>
                        <div style={{marginRight: "11px"}}>Add files</div>
                        <AddFile/></div>
                </Button>
            </div>
            <div  className={classes.Mform} ref={ref}>
                {attachments && attachments.map((item, index) => (
                    <div key={index} style={{marginLeft: "100px", marginRight: "100px"}}>
                        <FileItem key={item.docFileName + index}
                                  clickCross={() => deleteFile(item)}>
                            {item.docFileName}
                        </FileItem>
                        <QnectTextField
                            field="fileDescription"
                            onBlur={event => setDescriptionToArray(index, event.target.value)}
                            value={item.description}
                            formId={formId}
                        />
                    </div>
                    )
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        lang: _getLang(state),
        claim: _getClaim(state),
        document: _getDocument(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showModal: _showModal,
    hideModal: _hideModal,
    showError: _showError,
    addFile: _addFile,
    deleteFile: _deleteFile,
    uploadDocuments: _uploadDocuments
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadDocument));
