import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import useConfig from "../qnect-ui/qnect-config";
import {_getAdminLogin, _getCountry, _getLang} from "../../store/system/service";
import BuildNumber from '../../assets/build.number';
import {_privacyPolicyLink, _termsOfUseLink} from "../../utils/utility";
import "./video-modal.css";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DonutLargeRoundedIcon from '@mui/icons-material/DonutLargeRounded';
import { _getUser } from "../../store/user/service";

const useStyles = makeStyles(theme => ({
    root: {
        boxSizing: "border-box",
        position: "absolute",
        bottom: 0,
        width: "100%",
        fontFamily: "Inter",
        fontSize: "16px",
        lineHeight: "20px"
    },
    flexbox: {
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    topic: {
        cursor: "default",
        "&:first-child": {
            marginLeft: 0
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft: "8px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginLeft: "20px"
        },
        [theme.breakpoints.up("lg")]: {
            marginLeft: "30px"
        },
        "& a": {
            "&:visited, &:link": {
                color: "#fff"
            }
        }
    },
    version: {
        fontSize: "8px",
        color: "#8a94a6",
        marginTop: "16px",
        "&:first-child": {
            marginLeft: 0
        },
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
        [theme.breakpoints.up("md", "lg")]: {
            marginLeft: "10px"
        }
    },
    right: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            width: "88px"
        },
        [theme.breakpoints.up("md")]: {
            width: "136px"
        }
    },
    iconWrapper: {
        borderRadius: "50%",
        background: "rgba(255, 255, 255, 0.2)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            width: "24px",
            height: "24px"
        },
        [theme.breakpoints.up("md")]: {
            width: "32px",
            height: "32px"
        }
    },
    facebookIcon: {
        [theme.breakpoints.down("xs")]: {
            width: "12px",
            height: "12px"
        },
        [theme.breakpoints.up("md")]: {
            width: "16px",
            height: "16px"
        }
    },
    iconStyle: {
        [theme.breakpoints.down("xs")]: {
            width: "12px",
            height: "12px"
        },
        [theme.breakpoints.up("md")]: {
            width: "16px",
            height: "16px"
        }
    },
    footerMain: {
        background: "#191919",
        color: "#fff",
        height: "64px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            padding: "16px 46px 20px",
            height: "87px",
            fontSize: "14px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            padding: "0 24px"
        },
        [theme.breakpoints.up("lg")]: {
            padding: "0 165px"
        },
        ['@media screen and (max-width: 350px)']: {
            padding: "16px 20px 20px!important",
        },
    },
    chromeOnlyDiv: {
        background: "#EDEFF9",
        height: "56px",
        color: "#0A1F44",
        fontFamily: "Inter-Regular",
        [theme.breakpoints.down("xs")]: {
            padding: "15px 38px 14px",
            height: "87px",
            fontSize: "14px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            padding: "20px 24px 0 24px"
        },
        [theme.breakpoints.between("md", "lg")]: {
            padding: "9px 5px 0 24px"
        },
        [theme.breakpoints.up("lg")]: {
            padding: "20px 84px 0 165px"
        },
        ['@media screen and (max-width: 350px)']: {
            padding: "11px 10px 6px!important",
            height: "80px",
            fontSize: "14px"
        },
    }
}));

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Footer = (props) => {
    const classes = useStyles();
    const cfg = useConfig(props.lang);
    const [videoModal, setVideoModal] = useState(false);
    const [videoLoading, setVideoLoading] = useState(true);

    function openVideoModal(){
        setVideoModal(!videoModal);
    };

    const spinner = () => {
        setVideoLoading(!videoLoading);
    };

    const [versionNumber, setVersionNumber] = useState("");
    useEffect(() => {
        fetch(BuildNumber)
            .then(response => response.text())
            .then(data => {
                setVersionNumber(data);
            });
    },[])


    const checkIfChrome = () => {
        const isUsingChrome = !!window.chrome;
        if (isUsingChrome) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.chromeOnlyDiv} style={checkIfChrome() ? {display: "none"} : {}}>
                {cfg('footer.chromeOnly')}
            </div>
            <div className={classes.footerMain}>
                <Hidden mdUp>
                    <div className={classes.topic}>
                    { props.lang === 'en'
                        ?
                        <div>Copyright QBE {new Date().getFullYear()}. All Rights Reserved.</div>
                        :
                        cfg('footer.copyright')
                    }
                    </div>
                </Hidden>
                <div className={classes.flexbox}>
                    <Left>
                        <Hidden xsDown>
                            <div className={classes.topic}>
                            { props.lang === 'en'
                                ?
                                <div>Copyright QBE {new Date().getFullYear()}. All Rights Reserved.</div>
                                :
                                cfg('footer.copyright')
                            }
                            </div>
                        </Hidden>
                        <div className={classes.topic}>
                            <a href={cfg(_privacyPolicyLink(props.country?.toLowerCase()))}
                               target='_blank'>
                                {cfg('footer.privacyPolicy')}
                            </a>
                        </div>
                        <div className={classes.topic}>
                            <a href={cfg(_termsOfUseLink(props.country?.toLowerCase()))}
                               target='_blank'>
                                {cfg('footer.termsOfUse')}
                            </a>
                        </div>
                        { props.user?.user && (
                            <div className={classes.topic} >
                                <a href="javascript:void(0)" onClick={openVideoModal}>{cfg('footer.faq')}</a>
                            </div>
                        )}
                        
                        <div className={classes.topic}>
                            {videoModal ? (
                                <section className="modal-bg">
                                    <div className="modal-align">
                                        <div className="modal-content" modal={videoModal.toString()}>
                                            <CancelOutlinedIcon
                                                className="modal-close"
                                                arial-label="Close"
                                                onClick={()=>setVideoModal(false)}
                                            />
                                            <div className="modal-video-align">
                                                {videoLoading ? (
                                                    <div className="modal-spinner">
                                                        <DonutLargeRoundedIcon className="modal-spinner-style" fadein="none"/>
                                                    </div>
                                                ) : null}
                                                <iframe
                                                    className="modal-video-style"
                                                    onLoad={spinner}
                                                    loading="lazy"
                                                    width="800"
                                                    height="500"
                                                    src="https://www.youtube.com/embed/M3GQ48c59Ds"
                                                    title="QBE Claims Provider Portal"
                                                    border="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    referrerPolicy="strict-origin-when-cross-origin"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            ) : null}
                        </div>
                    </Left>

                    <div className={classes.version}>
                        {props.adminLogin &&
                            <span>{ props.adminLogin } - </span>
                        }
                        {versionNumber}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        country: _getCountry(state),
        adminLogin: _getAdminLogin(state),
        user: _getUser(state)
    }
}

export default connect(mapStateToProps)(Footer);
