import {POLICY_LIST_RESET, POLICY_LIST_STORE, RESET_USER, SET_USER} from "./types";
import {updateObject} from "../../utils/utility";

const INITIAL_STATE = {
    email: '',
    country: '',
    status: '',
    userType: '',
    provider: ''
};

const updateUserState = (state, action) => {
    return updateObject(
        state,
        action.data
    )
}

const updatePolicyListState = (state, action) => {
    return updateObject(
      state,
      { policyPortfolio: action.data }
    )

}

const resetPolicyListState = (state, action) => {
    return updateObject(
      state,
      { policyPortfolio: [] }
    )
}

const resetState = () => {
    INITIAL_STATE.email = "";
    return INITIAL_STATE;
}

export const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER:
            return updateUserState(state, action);
        case POLICY_LIST_STORE:
            return updatePolicyListState(state, action);
        case POLICY_LIST_RESET:
            return resetPolicyListState(state, action);
        case RESET_USER:
            return resetState();
        default:
            return state;
    }
}


