import React from "react";
import Header from "../components/custom/header";
import ReloadEvents from "../components/reload-events/reload-events";
import SessionTimeout from "../components/session-timeout";

const ReloadEventsPage = () => {

    return (

        <div>
            <Header />
            <ReloadEvents />
            <SessionTimeout />
        </div>
    )
}

export default (ReloadEventsPage);