import {css} from 'styled-components';

export const breakpoints = {
    xs: 375, // To be used sparingly, to solve problems not addressed by mobile designs which are 375px wide
    sm: 600, // This is the main breakpoint at which mobile becomes desktop
    md: 768, // To be used sparingly, when screen real estate is too constrained for desktop styles
    lg: 960,
};

export const xsUp = breakpointUp(breakpoints.xs);
export const xsDown = breakpointDown(breakpoints.xs);
export const smUp = breakpointUp(breakpoints.sm);
export const smDown = breakpointDown(breakpoints.sm);
export const mdUp = breakpointUp(breakpoints.md);
export const mdDown = breakpointDown(breakpoints.md);
export const lgUp = breakpointUp(breakpoints.lg);
export const lgDown = breakpointDown(breakpoints.lg);

function breakpointUp(breakpoint) {
    return (...ruleFragments) => css`
        @media (min-width: ${breakpoint}px) {
            ${css(...ruleFragments)}
        }
    `;
}

function breakpointDown(breakpoint) {
    return (...ruleFragments) => css`
        @media (max-width: ${breakpoint - 0.02}px) {
            ${css(...ruleFragments)}
        }
    `;
}

export const mdDownN = breakpointDownNoCutting(breakpoints.md);
export const mdUpN = breakpointUpCutting(breakpoints.md);

function breakpointUpCutting(breakpoint) {
    return (...ruleFragments) => css`
        @media (min-width: ${breakpoint + 0.02}px) {
            ${css(...ruleFragments)}
        }
    `;
}
function breakpointDownNoCutting(breakpoint) {
    return (...ruleFragments) => css`
        @media (max-width: ${breakpoint}px) {
            ${css(...ruleFragments)}
        }
    `;
}

