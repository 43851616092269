import React from 'react';
import propTypes from 'prop-types';
import RadioBox from "../radio-box";
import useConfig from "./qnect-config";
import {_getLang} from "../../store/system/service";
import {connect} from "react-redux";

const QnectRadioBox = React.forwardRef((
    {
        lang,
        field,
        formId,
        value,
        options,
        errorMessage,
        disabled,
        onChange,
        ...rest
    },
    ref
) => {

    const cfg = useConfig(lang);

        return (
                <RadioBox
                    options={options ? options : cfg(field, "options")}
                    value={value}
                    disabled={disabled}
                    onClick={onChange}
                    errorMessage={cfg(errorMessage)}
                />
        )
});

QnectRadioBox.propTypes = {
    field: propTypes.string,
    formId: propTypes.string,
    value: propTypes.string,
    className: propTypes.string,
    errorMessage: propTypes.string,
    onChange: propTypes.func,
    options: propTypes.arrayOf(propTypes.shape({
        optionLabel: propTypes.string,
        optionValue: propTypes.string,
        image: propTypes.string,
    })),
    disabled: propTypes.bool
};

QnectRadioBox.displayName = 'QnectRadioBox';

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
};

export default connect(mapStateToProps)(QnectRadioBox);
