import React from 'react';
import Login from '../components/login/login';
import {connect} from "react-redux";
import {_getCountry} from "../store/system/service";
import OutageBanner from "../components/outage-banner";

const LoginPage = (props) => {


    return (
        <>
            { props.country !== "" &&
              <OutageBanner/>
            }
            <Login/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state)
    }
};


export default connect(mapStateToProps)(LoginPage);
