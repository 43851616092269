import * as type from './types';
import { updateObject, _sendLog } from "../../utils/utility";

const INITIAL_STATE = {
    lang: '',
    spinner: false,
    showModal: false,
    modal: {},
    showError: false,
    errors: [],
    iv: '',
    otpToken: '',
    noLogin: false,
    country: '',
    cpToken: 'CP',
    securityToken: '',
    policyToken: '',
    documentToken: '',
    showOtpModal: false,
    portfolioToken: '',
    showPageBroken: false,
    adminLogin: '',
    claimsToken: '',
    timerStarted: false,
    timerId: null
};

const setLang = (state, action) => {
    return updateObject(
        state,
        { lang: action.lang }
    )
}

const showModalError = (state, action) => {
    console.error('showModalError called');
    console.error(action);
    _sendLog(['System Reducer - showModalError called', ...action.errors]);
    return updateObject(
        state,
        {
            showError: true,
            errors: action.errors
        }
    )
}

const clearModalError = (state, action) => {
    return updateObject(
        state,
        {
            showError: false
        }
    )
}

const showModal = (state, action) => {
    return updateObject(
        state,
        {
            showModal: true,
            modal: action.data
        }
    )
}

const hideModal = (state, action) => {
    return updateObject(
        state,
        {
            showModal: false
        }
    )
}

const showSpinner = (state, action) => {
    return updateObject(
        state,
        {
            spinner: true
        }
    )
}

const hideSpinner = (state, action) => {
    return updateObject(
        state,
        {
            spinner: false
        }
    )
}

const storeOtpToken = (state, action) => {
    let otpToken = action.data;
    return updateObject(state, {
        iv: otpToken.iv,
        otpToken: otpToken.token
    });
}

const storeCpToken = (state, action) => {
    return updateObject(
        state,
        { cpToken: action.token, iv: action.iv }
    )
}

const resetCpToken = (state, action) => {
    return updateObject(
        state,
        { cpToken: '' }
    )
}

const setClaimsToken = (state, action) => {
    return updateObject(
        state,
        { claimsToken: action.token }
    )
}

const resetClaimsToken = (state, action) => {
    return updateObject(
        state,
        { claimsToken: '' }
    )
}

const setDocumentToken = (state, action) => {
    return updateObject(
        state,
        { documentToken: action.token }
    )
}

const resetDocumentToken = (state, action) => {
    return updateObject(
        state,
        { documentToken: '' }
    )
}

const setNoLogin = (state, action) => {
    return updateObject(
        state,
        { noLogin: action.noLogin }
    )
}

const setCountry = (state, action) => {
    return updateObject(
        state,
        { country: action.country }
    )
}

const resetPortfolioToken = (state, action) => {
    return updateObject(
      state,
      { portfolioToken: '' }
    )
}

const setShowOtpModal = (state, action) => {
    return updateObject(state,
        { showOtpModal: action.showOtpModal}
        );
}

const storePortfolioToken = (state, action) => {
    return updateObject(state, {
        portfolioToken: action.data
    });
}

const resetState = (state) => {
    let obj = {...INITIAL_STATE};
    obj.country = state.country;
    obj.lang = state.lang;
    return obj;
}

const setShowPageBroken = (state, action) => {
    return updateObject(state, {
        showPageBroken: action.showPageBroken
    });
}

const setAdminLogin = (state, action) => {
    return updateObject(state,
        { adminLogin: action.adminLogin}
    );
}

const setTimerStarted = (state, action) => {
    return updateObject(state, {timerStarted: action.data.value, timerId: action.data.id});
}

export function systemReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case type.SET_LANG:
            return setLang(state, action);
        case type.SHOW_ERROR:
            return showModalError(state, action);
        case type.CLEAR_ERROR:
            return clearModalError(state, action);
        case type.SHOW_MODAL:
            return showModal(state, action);
        case type.HIDE_MODAL:
            return hideModal(state, action);
        case type.SHOW_SPINNER:
            return showSpinner(state, action);
        case type.HIDE_SPINNER:
            return hideSpinner(state, action);
        case type.SET_OTP_TOKEN:
            return storeOtpToken(state, action);
        case type.SET_CP_TOKEN:
            return storeCpToken(state, action);
        case type.SET_NO_LOGIN:
            return setNoLogin(state, action);
        case type.SET_COUNTRY:
            return setCountry(state, action);
        case type.RESET_PORTFOLIO_TOKEN:
            return resetPortfolioToken(state, action);
        case type.SET_DOCUMENT_TOKEN:
            return setDocumentToken(state, action);
        case type.SET_SHOW_OTP_MODAL:
            return setShowOtpModal(state, action);
        case type.SET_PORTFOLIO_TOKEN:
            return storePortfolioToken(state, action);
        case type.RESET_STATE:
            return resetState(state);
        case type.SET_PAGE_BROKEN:
            return setShowPageBroken(state, action);
        case type.SET_ADMIN_LOGIN:
            return setAdminLogin(state, action);
        case type.SET_CLAIMS_TOKEN:
            return setClaimsToken(state, action);
        case type.RESET_CLAIMS_TOKEN:
            return resetClaimsToken(state, action);
        case type.RESET_DOCUMENT_TOKEN:
            return resetDocumentToken(state, action);
        case type.SET_TIMER_STARTED:
            return setTimerStarted(state, action);
        default:
            return state;
    }
}
