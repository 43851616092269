import styled from "styled-components";
import React, {useState} from "react";
import RadioCard from "../ui/components/form-controls/radio-card";
import propTypes from 'prop-types';
import {mdDownN} from "../ui/common";

const RadioBoxGroupsStyle = styled.div`
    position: relative;
    //width: 600px;
    //height: 96px;
    display: grid;
    grid-template-columns: 189px 189px 189px;
    //padding-left: 128px;
    text-align: left;
    ${mdDownN`
        margin-top:42px;
        width: calc(100vw - 30px);
        padding: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        // grid-template-columns: 1fr 1fr;
        // justify-items: center;
        // grid-column-gap: 0px;
    `}
`;

const RadioGroupStyle = styled.div`
    position: relative;
    margin-top: 16px;
    width: 158px;
    height:76px;
    display: inline-block;
    margin-right: 25px;
    ${mdDownN`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        margin-left: 0px;
        margin-top: 0px;
        width: 133px;
        height:auto;
        :last-child{
            margin-right: 0px;
        }
    `}
   
`;


const RadioBoxErrorStyle = styled.div`
    display: block;
    color: #a50000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    padding: 0px;
    margin: 10px 0px 10px 0px;
    text-align: left;
`;

const RadioBox = (props) => {
    const [selectedValue, setSelectedValue] = useState (props.value);

    const selectValue = (value) => {
        setSelectedValue(value);
        if(!!props.onClick){
            props.onClick(value);
        }
    };

    return (
        <RadioBoxGroupsStyle>
            {props.options.map(({label, value, image}, index) =>
                <RadioGroupStyle index={index} key={value + index}>
                    <RadioCard
                        label={label}
                        value={value}
                        image={image}
                        disabled={props.disabled}
                        checked={value === selectedValue}
                        onChange={(event) => {
                            selectValue(value);
                            if(!!props.onChange){
                                props.onChange(event);
                            }
                        }}
                        name={!!props.name ? props.name : "radioBox"}
                    />
                </RadioGroupStyle>
            )}
            { props.errorMessage &&
            <RadioBoxErrorStyle>
                {props.errorMessage}
            </RadioBoxErrorStyle>
            }

        </RadioBoxGroupsStyle>
    );
}

RadioBox.propTypes = {
    field: propTypes.string,
    formId: propTypes.string,
    label: propTypes.string,
    value: propTypes.string,
    className: propTypes.string,
    errorMessage: propTypes.string,
    onChange: propTypes.func,
    disabled: propTypes.bool,
    onBlur: propTypes.func,
};

RadioBox.displayName = 'RadioBox';

export default RadioBox;
