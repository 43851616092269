import React from "react";
import Header from "../components/custom/header";
import ViewInvoice from "../components/invoice/view-invoice";
import SessionTimeout from "../components/session-timeout";
import ClaimAmountCalculation from "../components/invoice/calculation/by-claim-amount";
import ProviderCalculation from "../components/invoice/calculation/by-provider";
import ServiceFeeCalculation from "../components/invoice/calculation/by-service-fee";

const SubmitInvoicePage = (props) => {

    return (
        <React.Fragment>
            {
                <div>
                    <Header />
                    {
                        !props.match.params.method && <ViewInvoice />
                        ||
                        props.match.params.method === 'byFixedRate' && <ClaimAmountCalculation />
                        ||
                        props.match.params.method === 'byHourlyRate' && <ProviderCalculation />
                        ||
                        props.match.params.method === 'others' && <ServiceFeeCalculation/>
                    }
                    <SessionTimeout/>
                </div>
            }
        </React.Fragment>
    )
};

export default (SubmitInvoicePage);