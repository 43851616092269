import React, {useEffect, useRef, useState} from "react";
import useDocumentsStyles, { BackIcon, BackWrapper, Container, 
    Title, TopWrapper, StyledModalDiv } 
    from "../claims-documents/documents-style";
import {_getCountry, _getLang, _hideSpinner, _showSpinner, 
    _showModal, _hideModal, _showError} 
    from "../../store/system/service";
import { _getClaim} from "../../store/claims/service";
import {_addFile, _deleteFile, _getDocument, _uploadDocuments} from "../../store/documents/service";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import useConfig from "../qnect-ui/qnect-config";
import Hidden from "@material-ui/core/Hidden";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileItem from "../ui/file-item/file-item";
import {ReactComponent as AddFile} from "../../assets/AddFile.svg"
import Button from "../../ui/components/buttons/button";
import FieldWrapper from "../custom/field-wrapper";
import QnectTextField from "../qnect-ui/qnect-text-field";
import QnectButton from "../qnect-ui/qnect-button";
import {_validateForm, createUniqueIdentifier, tagManagerClickEvent, tagManagerPopup} from "../../utils/utility";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import QnectError from "../qnect-ui/qnect-error";

const UploadDocuments = (props) => {
    const cfg = useConfig(props.lang);
    const classes = useDocumentsStyles(props);
    const history = useHistory();
    const fileInputRef = useRef(null);
    const [attachments, setAttachments] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const formId = 'uploadDocsForm';
    const ref = useRef(null);
    const [identifier, setIdentifier] = useState("") ;
    const MAX_NUMBER_UPLOAD_FILE_LIMIT = 20;

    useEffect(() => {
        setErrorMessage("");
        setIdentifier(createUniqueIdentifier());
    }, []);

    const deleteFile = (item) => {
        setErrorMessage("");
        if(item && item.docID && item.docFileName){
            props.showSpinner();
            props.deleteFile(item.docID, identifier).then(resp => {
                if(resp.status === 200 && attachments && attachments.length > 0){
                    let data = attachments.filter(docForm => docForm.docID !== item.docID && docForm.docFileName !== item.docFileName);
                    setAttachments([]);
                    setAttachments(data);
                }
                props.hideSpinner();
            }).catch(error => {
                props.hideSpinner();
                props.showError(['Remove File Error', error.message || error]);
                tagManagerPopup('Remove File Error', props.claim?.claimNumber);
            });
        }
        tagManagerClickEvent('Icon-click', 'delete');
    }

    const addFile = (event) => {
        setErrorMessage("");
        if (attachments.length >= MAX_NUMBER_UPLOAD_FILE_LIMIT) {
            props.showError(['File Attachment Error', cfg('uploadDocuments.maxFilesUpload')]);
            return;
        }

        if(event.target.files && event.target.files.length > 0){
            const file = event.target.files[0];
            if (isFileValid(file)) {
                const newAttachments = [];
                if(attachments && Array.isArray(attachments)){
                    newAttachments.push(...attachments);
                }
                props.showSpinner();
                props.addFile(file, identifier).then(resp => {
                    if(resp.status === 200){
                        if(resp.data && resp.data.documentForm){
                            newAttachments.push({...resp.data.documentForm});
                            setAttachments(newAttachments);
                        } else {
                            // Upload failed
                            if (resp.data.errorMsg) {
                                props.showError(['File Attachment Error', resp.data.errorMsg]);
                            } else {
                                const errors = Array.isArray(resp.data.errors) ? [ ...resp.data.errors] : [resp.data.errors];
                                props.showError(['File Attachment Error', ...errors]);
                            }
                            tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
                        }
                    }
                    props.hideSpinner();
                }).catch(error => {
                    props.hideSpinner();
                    props.showError(['File Attachment Error', error.message || error]);
                    tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
                });
            }
        }
        event.target.value = null;
    }
    
    const uploadFiles = () => {

        if (attachments.length === 0) {
            setErrorMessage(cfg('uploadDocuments.plsAddFiles'));
            return;
        }

        if (attachments.length > MAX_NUMBER_UPLOAD_FILE_LIMIT) {
            props.showError(['File Attachment Error', cfg('uploadDocuments.maxFilesUpload')]);
            return;
        }

        if (_validateForm(formId)) {
            let documentForm = {
                identifier: identifier,
                documents: attachments
            };

            props.showSpinner();
            props.uploadDocuments(documentForm).then(resp => {
                props.hideSpinner();
                if(resp.status === 200){
                    if (resp.data.errorMsg) {
                        props.showError(['File Upload Error', resp.data.errorMsg]);
                        tagManagerPopup(cfg('File Upload Error'), props.claim?.claimNumber);
                    } else {
                        tagManagerPopup('Upload successful', props.claim?.claimNumber, attachments.length);
                        showModalSuccess(cfg('uploadDocuments.uploadSuccessfulLabel1'), cfg('uploadDocuments.uploadSuccessfulLabel2'));
                    }
                } else {
                    props.showError([cfg('errorMessage.internalServerError')]);
                    tagManagerPopup(cfg('errorMessage.internalServerError'), props.claim?.claimNumber);
                }
            }).catch(error => {
                console.error("upload file error",  error);
                props.hideSpinner();
                props.showError(['File Upload Error', error.message || error]);
                tagManagerPopup('File Upload Error', props.claim?.claimNumber);
            });
        }
    };

    const isFileValid = (file) => {
        let valid = false;
        const fileName = file.name || '';
        const fileExt = fileName.substring(fileName.lastIndexOf('.')+1, fileName.length) || fileName;
        if (file.size > cfg('uploadDocuments.maxFileSize')) {
            props.showError(['File Attachment Error','Only files with 20MB size or less is allowed']);
            tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
        } else if (fileName.length > cfg('uploadDocuments.maxFileNameLength')) {
            props.showError(['File Attachment Error','The file name cannot be longer than 80 characters']);
            tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
        } else if('DOC,DOCX,XLS,XLSX,PDF,TXT,JPEG,JPG,PNG,GIF,AVI,MGG,MP4,MOV,WMV'.indexOf(fileExt.toUpperCase()) === -1){
            props.showError(['File Attachment Error','Only .doc, .docx, .xls, .xlsx, .pdf, .txt, .jpeg, .jpg, .png, .gif, .avi, .mgg, .mp4, .mov, .wmv files are allowed']);
            tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
        } else if (isFileDuplicate(file)) {
            props.showError(['File Attachment Error','File already added.']);
            tagManagerPopup('File Attachment Error', props.claim?.claimNumber);
        } else {
            valid = true;
        }
        return valid;
    }


    const isFileDuplicate = (file) => {
        return attachments.some(element => element.docFileName === file.name);
    }

    const handleAddFiles = () => {
        if (_validateForm(formId)) {
            fileInputRef.current.click();
        }
    }

    const closeDialog = () => {
        props.hideModal();
        setIdentifier(createUniqueIdentifier());
        setAttachments([]);
    }

    const callUploadedDocs = () => {
        props.hideModal();
        props.document.showUploadedDocs = true;
        history.push('/viewDocuments');
    };

    const setDescriptionToArray = (index, value) => {
        setErrorMessage("");
        setAttachments(prev => {
            return prev.map((obj, i) => {
                if (i === index) {
                    return {...obj, description: value};
                }
                return obj;
            });
        });
    };

    const showModalSuccess = (title, msg) => {
        props.showModal({
            flat: true,
            content: <StyledModalDiv>
                <div className="iconDivSuccess"><CheckCircleIcon fontSize='large'/></div>
                <div className="title">{title}</div>
                <div className="subtitle">{msg}</div>
                <div className="buttonDiv">
                    <div style={{float: 'left'}}>
                        <Button color="secondary" onClick={() => {closeDialog()}}>
                            <div className={classes.whiteButtonStyle}>
                                Close
                            </div>
                        </Button>
                    </div>
                    <div style={{float: 'right'}}>
                        <QnectButton
                            field="viewUploadedDocs"
                            onClick={() => callUploadedDocs()}
                        />
                    </div>
                </div>
            </StyledModalDiv>
        });
    }

    return (
        <>
            <Container>
            <div className={classes.box}>
                <TopWrapper>
                <Hidden mdDown>
                    <BackWrapper onClick={() => history.push("/claimsDetails")}>
                    <BackIcon/>{cfg('backBtn.label')}
                    </BackWrapper>
                    <Title>{cfg('uploadDocuments.title')}</Title>
                </Hidden>
                </TopWrapper>
            </div>
            </Container> 
            <div style={{overflowY: "auto !important"}}>
                <div className={classes.rectangle}>
                    <div style={{backgroundColor: '#F7F8F9'}}>
                        <AttachFileIcon style={{fontSize: 40, color: '#003DA5', marginTop: '30px'}} />
                        <div className={classes.fileUploadTitle}><h2>{cfg('uploadDocuments.fileUpload')}</h2></div>
                    </div>

                    <div className={classes.claimNumber}>{cfg('claimDetails.claimNumber')}: {props.claim.claimNumber}</div>
                    
                    <div className={classes.allowedFormatSize}>{cfg('uploadDocuments.allowedFormatSize')}</div>
                    
                    <div style={{marginBlock: '40px'}}>
                        <input type="file" hidden ref={fileInputRef} onChange={addFile}></input>
                        <Button color="secondary" onClick={() => handleAddFiles()}>
                            <div className={classes.whiteButtonStyle}>
                                <div style={{marginRight: "11px"}}>Add files</div>
                                <AddFile/></div>
                        </Button>
                    </div>
                   
                    <div  className={classes.Mform} ref={ref}>
                        {attachments && attachments.map((item, index) => (
                            <div key={index} style={{marginLeft: "100px", marginRight: "100px"}}>
                                <FileItem key={item.docFileName + index}
                                            clickCross={() => deleteFile(item)}>
                                    {item.docFileName}
                                </FileItem>

                                <QnectTextField
                                    field="fileDescription"
                                    onBlur={event => setDescriptionToArray(index, event.target.value)}
                                    value={item.description}
                                    formId={formId}
                                />
                            </div>
                            )
                        )}
                        
                        {errorMessage && (
                            <div className={classes.errorMessage}>
                                <QnectError errorTitle={"Error"} errorMessage={errorMessage} />
                            </div>
                        )}
                        <div className={classes.member_input}>
                            <FieldWrapper>
                                <QnectButton
                                    field="upload" 
                                    onClick={() => uploadFiles()}                              
                                />
                            </FieldWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        lang: _getLang(state),
        claim: _getClaim(state),
        document: _getDocument(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showModal: _showModal,
    hideModal: _hideModal,
    showError: _showError,
    addFile: _addFile,
    deleteFile: _deleteFile,
    uploadDocuments: _uploadDocuments
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadDocuments));
