import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import clamp from 'lodash/clamp';
import get from 'lodash/get';
import property from 'lodash/property';
import times from 'lodash/times';
import {breakpoints, mdDown, mdUp} from '../../../common/breakpoints';
import {cssIf, cssIfElse} from '../../../common/conditional-styles';
import typographyStyles from '../../../common/typography-styles';
import visuallyHidden from '../../../common/visually-hidden';
import dash from '../../../../assets/StepperDash.svg';
import Done from '../../../../assets/Done.svg';

const DESKTOP_STEP_CIRCLE_SIZE_PX = 30;
const DESKTOP_STEP_CIRCLE_BORDER_WIDTH_PX = 2;
const DESKTOP_PROGRESS_THICKNESS_PX = 2;
const DESKTOP_VERTICAL_PADDING_PX = 0;

const MOBILE_PROGRESS_THICKNESS_PX = 3;
const MOBILE_PROGRESS_GAP_PX = 2;

const Container = styled.div`
    ${mdDown`
        box-sizing: border-box;
        padding: 0 16px;
        max-width: 100%;
        min-height: 58px;
    `}

    ${mdUp`
        position: relative;
        min-height: 101px;
    `}
`;

const Steps = styled.ol`
    ${mdDown`
        margin: 0;
        padding: 16px 0 4px;
        list-style-type: none;
        counter-reset: step -1;
    `}

    ${mdUp`
        display: flex;
        margin: 0 auto;
        padding: ${DESKTOP_VERTICAL_PADDING_PX}px 0;
        width: ${breakpoints.sm}px;
        max-width: ${breakpoints.sm}px;
        list-style-type: none;
        counter-reset: step -1;
    `}
`;

const Step = styled.li`
    ${typographyStyles({
        type: 'text',
        defaultSize: 'small',
        defaultWeight: 'regular',
    })}

    ${mdDown`
        display: flex;
        counter-increment: step;

        ${cssIfElse('scCurrent')`
            position: relative;
            margin: 0;
            padding: 0;
            color: ${property('theme.color.white')}
        ``
            ${visuallyHidden}
        `}

        &::before {
            content: counter(step);

            ${visuallyHidden}
        }
    `}

    ${mdUp`
        display: flex;
        flex: 1 1 0px; /* stylelint-disable-line length-zero-no-unit */ /* 0px instead of 0 needed by IE11 */
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
        color: ${property('theme.color.white')};
        counter-increment: step;

        ${cssIfElse(({scCurrent, scDone}) => scCurrent || scDone)`
            color: #003DA5;
            font-family: Inter-Bold, sans-serif;
            font-size: 14px;
            font-weight: bold;
        ``
            color: ${property('theme.color.grey60')};
            font-family: Inter-Medium, sans-serif;
            font-size: 14px;
            font-weight: normal;
        `}

        &:first-of-type,
        &:last-of-type {
            ${visuallyHidden}
        }

        &::before {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            border: ${DESKTOP_STEP_CIRCLE_BORDER_WIDTH_PX}px solid;
            border-radius: 50%;
            width: ${DESKTOP_STEP_CIRCLE_SIZE_PX}px;
            height: ${DESKTOP_STEP_CIRCLE_SIZE_PX}px;
            content: counter(step);

            ${cssIfElse(({scCurrent, scDone}) => scCurrent || scDone)`
                border-color: #003DA5;
            ``
                border-color: ${property('theme.color.grey60')};
            `}

            ${cssIf('scCurrent')`
                background: #003DA5;
                color: ${property('theme.color.white')};
            `}

            ${cssIf('scDone')`
                background-color:#003DA5;
                background-image: url(${Done});
                background-repeat: no-repeat;
                background-size: 60%;
                background-position: center;
                color: transparent;
            `}
        }
    `}
`;

const StepLabel = styled.span`
    ${mdDown`
        flex-grow: 1;
        padding-right: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `}

    ${mdUp`
        box-sizing: border-box;
        padding: 16px 16px 0 16px;
        width: 100%;
        text-align: center;
    `}
`;

const StepCount = styled.span`
    ${mdDown`
        white-space: nowrap;
    `}

    ${mdUp`
        display: none;
     `}
`;

/* eslint-disable indent */
const Progress = styled.div`
    ${mdDown`
        height: ${MOBILE_PROGRESS_THICKNESS_PX}px;

        ${({
            theme,
            'aria-valuemax': maxSteps,
            'aria-valuenow': activeStep,
        }) => {
            const progressSegments = maxSteps - 1;

            if (progressSegments) {
                const segmentWidthPercent = 100 / progressSegments;
                const segmentOffsetPercent = 100 / (progressSegments - 1);

                return `
                    background-image: ${
                        times(
                            progressSegments,
                            (index) => `linear-gradient(to right, ${
                                times(2, () => index + 1 > activeStep ? theme.color['grey60'] : '#00C1CC').join(', ')
                            })`
                        )
                            .join(', ')
                    };
                    background-size: ${
                        times(
                            progressSegments,
                            () => `calc(${segmentWidthPercent}% - ${MOBILE_PROGRESS_GAP_PX}px) ${MOBILE_PROGRESS_THICKNESS_PX}px`
                        )
                            .join(', ')
                    };
                    background-position: ${
                        times(
                            progressSegments,
                            (index) => `calc(${segmentOffsetPercent * index}% + ${MOBILE_PROGRESS_GAP_PX / 2}px) 0`
                        )
                            .join(', ')
                    };
                    background-repeat: no-repeat;
                `;
            }

            return null;
        }}
    `}

    ${mdUp`
        position: absolute;
        top: ${DESKTOP_VERTICAL_PADDING_PX + ((DESKTOP_STEP_CIRCLE_SIZE_PX - DESKTOP_PROGRESS_THICKNESS_PX) / 2)}px;
        left: 50%;
        transform: translateX(-50%);
        margin: 0 auto;
        height: ${DESKTOP_PROGRESS_THICKNESS_PX}px;

        ${({
            theme,
            'aria-valuemax': maxSteps,
            'aria-valuenow': activeStep,
        }) => {
            const progressSegments = maxSteps - 2;

            if (progressSegments) {
                const segmentWidthPx = breakpoints.sm / (progressSegments + 1);
                const widthPx = segmentWidthPx * progressSegments;

                return `
                    background-image: ${
                        times(
                            progressSegments,
                            (index) => index+2>activeStep?`url(${dash})`:`linear-gradient(to right, ${
                                times(2, () => index + 2 > activeStep ? theme.color['grey60'] : '#003DA5').join(', ')
                            })`
                        )
                            .join(', ')
                    };
                    background-size: ${
                        times(
                            progressSegments,
                            () => `${segmentWidthPx - DESKTOP_STEP_CIRCLE_SIZE_PX}px ${DESKTOP_PROGRESS_THICKNESS_PX}px`
                        )
                            .join(', ')
                    };
                    background-position: ${
                        times(
                            progressSegments,
                            (index) => `${(DESKTOP_STEP_CIRCLE_SIZE_PX / 2) + (index * segmentWidthPx)}px 0`
                        )
                            .join(', ')
                    };
                    background-repeat: no-repeat;
                    width: ${widthPx}px;
                    max-width: ${widthPx}px;
                `;
            }

            return null;
        }}
    `}
`;
/* eslint-enable indent */

const ProgressContent = styled.span`
    ${visuallyHidden}
`;

export default function Stepper({
    className,
    steps = [],
    activeStep = 0,
}) {
    const effectiveActiveStep = clamp(activeStep, 0, steps.length - 1);

    return (
        <Container className={classnames('Stepper', className)}>
            <Steps>
                {steps.map(({id, name}, index) => (
                    <Step
                        key={id}
                        scCurrent={index === activeStep}
                        scDone={index < activeStep}
                        scStepCount={steps.length - 2}
                    >
                        <StepLabel>
                            {name}
                        </StepLabel>

                        {index < steps.length - 1 && (
                            <StepCount aria-hidden="true">
                                {`${index} of ${steps.length - 2}`}
                            </StepCount>
                        )}
                    </Step>
                ))}
            </Steps>
            <Progress
                role="progressbar"
                aria-valuemin={0}
                aria-valuemax={steps.length - 1}
                aria-valuenow={activeStep}
            >
                <ProgressContent>
                    {`Step ${activeStep + 1} of ${steps.length}: ${get(steps, [effectiveActiveStep, 'name'])}`}
                </ProgressContent>
            </Progress>
        </Container>
    );
}

Stepper.propTypes = {
    className: propTypes.string,
    steps: propTypes.arrayOf(propTypes.shape({
        id: propTypes.string.isRequired,
        name: propTypes.string.isRequired,
    })),
    activeStep: propTypes.number,
};
