import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import {_getCountry, _getCpToken, _hideModal, _hideSpinner, _setPortfolioToken, _showModal, _showSpinner} from "../../store/system/service";
import {_getUser, _resetPassword} from "../../store/user/service";
import FieldWrapper from "../custom/field-wrapper";
import Header from "../custom/header";
import QnectTextField from "../qnect-ui/qnect-text-field";
import QnectButton from "../qnect-ui/qnect-button";
import useConfig from "../../utils/qnect-config";
import {StyledModalDiv} from "../login/login-style";
import {useHistory} from "react-router-dom";
import {CheckBoxWrapper, StyledCheckBoxContent} from "../custom/dialog-style";
import QnectCheckBox from "../qnect-ui/qnect-check-box";
import {_privacyPolicyLink, _termsOfUseLink, tagManagerPopup} from "../../utils/utility";
import QnectError from '../qnect-ui/qnect-error';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: "100%",
        background: "rgb(246,248,250)",
        minHeight: "calc(100vh - 64px)",
        '& .MuiFormControl-root.TextField' : {
            marginBottom: '10px',
            marginTop: '10px',
        },
        [theme.breakpoints.down("sm")]: {
            minHeight: "calc(100vh + 28px)",
        },
        [theme.breakpoints.up("lg")]: {}
    },
    topBg: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("lg")]: {
            height: "432px"
        }
    },
    topRow: {
        margin: "0 auto",
        width: "100%",
        maxWidth: "1440px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            height: "44px",
            padding: "0 12px",
            alignItems: "center",
            borderBottom: "1px solid #E1E4E8"
        },
        [theme.breakpoints.up("lg")]: {
            alignItems: "flex-end",
            height: "75px"
        }
    },
    logo: {
        [theme.breakpoints.down("sm")]: {
            width: "70px"
        },
        [theme.breakpoints.up("lg")]: {
            width: "122px",
            height: "35px"
        }
    },
    main: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px 0 rgba(0,0,0,0.04)",
        [theme.breakpoints.down("sm")]: {
            margin: "20px auto",
            padding: "20px 16px",
            height: "454px",
            width: "92%"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 auto",
            padding: "48px 70px",
            height: "fit-content",
            width: "600px"
        },
        fontFamily: "Inter-Regular",
        fontSize: "16px",
    },
    title: {
        fontFamily: "Stag-Medium",
        fontSize: "24px",
        fontWeight: "500",
        letterSpacing: "0",
        lineHeight: "28px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "0px",
            color: "#003DA5",
            fontSize: "21px"
        },
        [theme.breakpoints.up("lg")]: {
            marginBottom: "57px",
            color: "#003DA5"
        }
    },
    message: {
        color: "#0A1F44",
        fontFamily: "Inter",
        letterSpacing: "0",
        textAlign: "center",
        lineHeight: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "normal",
            "&:last-child": {
                marginTop: "4px"
            }
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "18px",
            "&:last-child": {
                marginTop: "37px"
            }
        }
    },
    image: {
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            "& svg": {
                width: "260px"
            }
        },

    },
    outerTitle: {
        [theme.breakpoints.down("sm")]: {
            margin: "20px auto",
            padding: "20px 16px",
            height: "454px",
            width: "92%",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 auto",
            width: "600px",
            height: '88px',
        },
        position: "relative",
        top: "0",
        textAlign: "center",
        fontFamily: "Stag-Medium",
        fontSize: "24px",
        backgroundColor: '#f1f2f4',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '2px solid #009AE4'
    },
    firstLine: {
        marginBottom: "17px",
        textAlign: "center",
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: '28px',
    },
    secondLine: {
        marginBottom: "17px",
        textAlign: "center",
        fontSize: "16px",
        lineHeight: '28px',
    },

    errorMessage: {
        marginBottom: "10px",
        "& .alert": {
        marginTop: "0px"
        }
    }
    
}));

const RequirementsDiv = styled.div`
    margin-bottom: 20px;

    ul {
        padding-left: 32px;
    }
    
    li {
        padding-top: 8px;
    }
`;

const StyledList = styled.li`
    padding-left: 7px;
    margin-left: -7px; 
    
    ${({passed, cond2Status}) => {
        if (passed) {
            return css`
                        list-style-type:  '✓'; 
                        color: #009AE4;
                    `
        } else {
            if (cond2Status) {
                return css`
                        list-style-type:  disc;   
                        opacity: 30%;
                        margin-left: 2px;
                    `
            } else {
                return css`
                        list-style-type:  '✖';   
                        color: #D54040;
                    `
            }
            
        }
    }}
`;

const PasswordInputsDiv = styled.div`
    padding: 0 45px;
`;

const SavePasswordDiv = styled.div`
    text-align: center;
    button {
        border-radius: 27px;
        height: 52px;
        padding: 0 27px;
        margin-top: 20px;
    }
`

const TermsOfUseDiv = styled.div`
    text-align: center;
    margin-top: 30px;
    fontSize: 16px,
`

const StyledModalDiv2 = styled(StyledModalDiv)`
    padding-top: 32.2px;
    width: 600px;
    height: 257px;
    .title {
        margin-bottom: 40px;
    }
    
    div.content {
        margin-bottom: 40px;
    }
    
    div.buttonDiv {
        margin: 0 auto;
        
        button {
            width: 180px;
        }
    }

`;

const ResetPassword = (props) => {

    const classes = useStyles();
    const history = useHistory();
    const cfg = useConfig();

    const [cond1, setCond1] = useState(false);
    const [cond2, setCond2] = useState(false);
    const [cond3, setCond3] = useState(false);
    const [cond4, setCond4] = useState(false);
    const [cond5, setCond5] = useState(false);
    const [cond6, setCond6] = useState(false);
    const [cond7, setCond7] = useState(false);

    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const isAdmin = ['SU', 'ADM'].indexOf(props.user?.userType?.toUpperCase()) !== -1;
    const isTermsRequired = !isAdmin && props.isRegistration;
    const passwordLength = isAdmin ? 16: 14;
    const isCyberarkPasswordExpired = isAdmin && props.user.email?.toUpperCase().startsWith("LO-CSAP")
        && props.isPasswordExpired;

    useEffect(() => {
        if (password.length >= passwordLength) {
            setCond1(true);
        } else {
            setCond1(false);
        }

        let cond2Checker = 0;

        if (password.match(/[a-z]/g)){
            cond2Checker++;
            setCond3(true);
        } else {
            setCond3(false);
        }

        if (password.match(/[A-Z]/g)){
            cond2Checker++;
            setCond4(true);
        } else {
            setCond4(false);
        }

        if (password.match(/\d/g)){
            cond2Checker++;
            setCond5(true);
        } else {
            setCond5(false);
        }

        if (password.match(/((?=.*[!@#$%&*()_+=|<>?{}\[\]~-]).+$)/g)){
            cond2Checker++;
            setCond6(true);
        } else {
            setCond6(false);
        }

        if (cond2Checker > 2) {
            setCond2(true);
        } else {
            setCond2(false);
        }

        if (password) {
            setCond7(!containsUsername(password));
        }

        // confirmPassword validation
        let confirmPasswordValidCount = 0;

        if (confirmPassword.match(/[a-z]/g)) {
            confirmPasswordValidCount++;
        }

        if (confirmPassword.match(/[A-Z]/g)){
            confirmPasswordValidCount++;
        }

        if (confirmPassword.match(/\d/g)){
            confirmPasswordValidCount++;
        }

        if (confirmPassword.match(/((?=.*[!@#$%&*()_+=|<>?{}\[\]~-]).+$)/g)){
            confirmPasswordValidCount++;
        }

        if (confirmPassword.length >= passwordLength && confirmPasswordValidCount >= 3 && !containsUsername(confirmPassword)) {
            setIsConfirmPasswordValid(true);
        } else {
            setIsConfirmPasswordValid(false);
        }

        setErrorMessage([]);
    }, [password, confirmPassword]);


    const containsUsername = (password) => {
        let username = props.user.email?.split("@")[0]?.toLowerCase();
        if (username && password) {
            username = username.replace(/[^a-zA-Z0-9]/g, '');
            let passwordVal = password.replace(/[^a-zA-Z0-9]/g, '');
            if (passwordVal?.toLowerCase().indexOf(username) !== -1) {
                return true;
            }
        }
        return false;
    };

    const handleResetPasswordSubmit = () => {
        setErrorMessage([]);
        if (password !== confirmPassword) {
            setErrorMessage(cfg('resetPassword.errorMessages.passwordNotMatch'));
            return;
        }

        props.showSpinner();
        props.resetPassword(props.user.email, password, currentPassword).then((resp) => {
            if (resp.success) {
                props.showModal({
                    flat: true,
                    content: <StyledModalDiv2>
                        {resp.isRegistration ? (
                            <>
                                <div className="title">
                                    {cfg('registration.title')}
                                </div>
                                <div className="content">{cfg('registration.successMsg')}</div>
                            </>
                         ):(
                            <>
                                <div className="title">
                                    {cfg('forgotPassword.setPassword')}
                                </div>
                                <div className="content">{cfg('forgotPassword.resetPwMessage')}</div>
                            </>
                        )}
                        <div className="buttonDiv">
                            <QnectButton
                                onClick={handleContinue}
                                field="close"
                            ></QnectButton>
                        </div>
                    </StyledModalDiv2>,
                    disableBackDropClick: true
                });
                tagManagerPopup(resp.isRegistration ? cfg('registration.title') : cfg('forgotPassword.setPassword'));
            } else {
                if (resp?.errorcode === "P01") {
                    setErrorMessage(cfg('resetPassword.errorMessages.incorrectCurrentPassword'));
                } else if (resp?.errorcode === "P02") {
                    setErrorMessage(cfg('resetPassword.errorMessages.notLast8'));
                } else {
                    setErrorMessage(cfg('errorMessage.serverError'));
                }

            }
            props.hideSpinner();
        }, (error) => {
            if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                setErrorMessage(cfg('errorMessage.serverError'));
            } else {
                setErrorMessage(error.response.statusText);
            }
            props.hideSpinner();
        });
    };

    const handleContinue = () => {
        props.hideModal();
        if (isCyberarkPasswordExpired) {
            props.setPortfolioToken(props.cpToken);
            history.push('/adminDetails');
        } else {
            history.push('/login');
        }
    };

    return (
        <div className={classes.wrapper}>
            <Header />
            <div style={{marginTop: '60px', paddingBottom: '60px'}}>
                <div className={classes.outerTitle} style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}>
                    {cfg(props.isRegistration ? 'registration.title' :
                        'forgotPassword.resetPassword')}
                </div>
                <div className={classes.main}>
                    <RequirementsDiv>
                        {props.isRegistration &&
                            <div className={classes.firstLine}>{cfg("forgotPassword.setPassword")}</div>
                        }
                        {!props.isRegistration &&
                        <div className={classes.firstLine}>{cfg("forgotPassword.last8PassUsed")}</div>
                        }
                        <ul>
                            <StyledList passed={cond7}>{cfg("forgotPassword.notContainUsername")}</StyledList>
                            <StyledList passed={cond1}>{ isAdmin ? (cfg("forgotPassword.atLeast16Char")) : (cfg("forgotPassword.atLeast14Char"))}</StyledList>
                            <StyledList passed={cond2}>{cfg("forgotPassword.haveAtleast3CharTypes")}</StyledList>
                            <ul>
                                <StyledList passed={cond3} cond2Status={cond2}>{cfg("forgotPassword.aLowerCase")}</StyledList>
                                <StyledList passed={cond4} cond2Status={cond2}>{cfg("forgotPassword.anUpperCase")}</StyledList>
                                <StyledList passed={cond5} cond2Status={cond2}>{cfg("forgotPassword.aNumeric")}</StyledList>
                                <StyledList passed={cond6} cond2Status={cond2}>{cfg("forgotPassword.aSpecial")}</StyledList>
                            </ul>
                        </ul>
                    </RequirementsDiv>
                    <PasswordInputsDiv>
                        { isCyberarkPasswordExpired &&
                         <FieldWrapper>
                            <QnectTextField
                                field="currentPassword"
                                type="password"
                                onChange={(evt) => {
                                    setCurrentPassword(evt.target.value);
                                }}
                            />
                         </FieldWrapper>
                        }
                        <FieldWrapper>
                            <QnectTextField
                                field="resetPasswordNewPW"
                                type="password"
                                errorMessage={passwordErrorMessage}
                                onChange={(evt) => {
                                    setPassword(evt.target.value);
                                    setPasswordErrorMessage('');
                                }}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <QnectTextField
                                field="resetPasswordVerifyNewPW"
                                type="password"
                                errorMessage={confirmPasswordErrorMessage}
                                onChange={(evt) => {
                                    setConfirmPassword(evt.target.value);
                                    setConfirmPasswordErrorMessage('');
                                }}
                            />
                        </FieldWrapper>
                    </PasswordInputsDiv>
                    {errorMessage && (
                        <div className={classes.errorMessage}>
                            <QnectError errorTitle={"Error"} errorMessage={errorMessage} />
                        </div>
                    )}
                    {isTermsRequired &&
                    <TermsOfUseDiv>
                        <CheckBoxWrapper>
                            <QnectCheckBox
                                onChange={(val) => {
                                    setHasAcceptedTerms(val);
                                }}>
                                <StyledCheckBoxContent>
                                    {cfg('registration.terms')}
                                    <a href={cfg(_termsOfUseLink(props.country?.toLowerCase()))}
                                       target='_blank'>
                                        {cfg('footer.termsOfUse')}
                                    </a>
                                    {cfg('registration.and')}
                                    <a href={cfg(_privacyPolicyLink(props.country?.toLowerCase()))}
                                       target='_blank'>
                                        {cfg('footer.privacyPolicy')}
                                    </a>
                                </StyledCheckBoxContent>
                            </QnectCheckBox>
                        </CheckBoxWrapper>
                    </TermsOfUseDiv>
                    }
                    <SavePasswordDiv>
                        <FieldWrapper>
                            {props.isRegistration ? (
                                <QnectButton
                                    field="registerNow"
                                    onClick={handleResetPasswordSubmit}
                                    disabled={ !cond1 || !cond2 || !isConfirmPasswordValid ||
                                    (!hasAcceptedTerms && isTermsRequired)}
                                />
                            ) : (
                                <QnectButton
                                    field="resetPassword"
                                    onClick={handleResetPasswordSubmit}
                                    disabled={ !cond1 || !cond2 || !isConfirmPasswordValid}
                                />
                            )}
                        </FieldWrapper>
                    </SavePasswordDiv>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: _getUser(state),
        country: _getCountry(state),
        cpToken: _getCpToken(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    resetPassword: _resetPassword,
    showModal: _showModal,
    hideModal: _hideModal,
    setPortfolioToken: _setPortfolioToken
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
