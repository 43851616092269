import React, {useEffect, useState} from "react";
import { BackIcon, BackWrapper, Container, Title, TopWrapper } from "../claims-documents/documents-style";
import {
    _getCountry, _getLang, _hideSpinner, _showSpinner,
    _showModal, _hideModal, _showError, _setDocumentToken
}
    from "../../store/system/service";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import useConfig from "../qnect-ui/qnect-config";
import Hidden from "@material-ui/core/Hidden";
import Box from "@mui/material/Box/Box";
import TabPanel from "../custom/tab-panel";
import MaterialUiTable from "../ui/material-ui-table/material-ui-table";
import Typography from "@material-ui/core/Typography/Typography";
import QnectButton from "../qnect-ui/qnect-button";
import {_getClaim, _getClaimDocs, _loadDocument} from "../../store/claims/service";
import {
    base64ToArrayBuffer,
    convertDateToMoment, formatDate, sortDateFunction,
    tagManagerClickEvent,
    tagManagerPopup
} from "../../utils/utility";
import {saveAs} from "file-saver";
import useDocumentsStyles from "./documents-style";
import Tabs from "@mui/material/Tabs/Tabs";
import Tab from "@mui/material/Tab/Tab";
import {_getDocument, _updateDocument} from "../../store/documents/service";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import UpdateDocumentDialog from "./update-document-dialog";
import {StyledModalDiv} from "../admin/admin-style";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Button from "../../ui/components/buttons/button";
import {ReactComponent as Arrow} from "../../assets/Arrow.svg"
import { FormControlLabel, Grid, Switch } from "@mui/material";

const ViewDocuments = (props) => {
    const cfg = useConfig(props.lang);
    const classes = useDocumentsStyles(props);
    const history = useHistory();
    const [data, setData] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [action, setAction] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isEnabledMultipleDocDownload, setIsEnabledMultipleDocDownload] = useState(false);

    useEffect(() => {
        handleTabChange(null,props.document.showUploadedDocs ? 1 : 0);
        resetShowUploadedDocs();
    }, []);

    const loadDocuments = (docsType) => {
        props.showSpinner();
        props.getClaimDocs(docsType).then((response) => {
            if (response.status === 'success') {
                if (response.documents) {
                    setData(response.documents);
                    props.setDocumentToken(response.token);
                } else {
                    setData([]);
                    props.setDocumentToken('');
                }
            }
            props.hideSpinner();
        }).catch(error => {
            console.log('getClaimDocs - error occurred', error);
            props.hideSpinner();
        });
    }

    const ColumnAction = ({selectedRowData}) => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (e) => {
            setAnchorEl(e.currentTarget);
            tagManagerClickEvent('Menu-Click','Actions', props.claim.claimNumber);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const callUpdateDocDialog = (actionValue, selRowData) => {
            setAnchorEl(null);
            setSelectedRow(selRowData);
            setAction(actionValue);
            tagManagerClickEvent('Menu-Click',actionValue === 'update' ?
                'Edit Document' : 'Delete Document', props.claim.claimNumber);
            tagManagerPopup(actionValue === 'update' ?
            'Edit Document' : 'Delete Document', props.claim?.claimNumber);
        };

        const saveAsFile = (rowData) => {
            if (tabValue === 0) {
                tagManagerClickEvent('Download Click','Received Documents', props.claim.claimNumber);
            } else {
                tagManagerClickEvent('Menu-Click','Download', props.claim.claimNumber);
            }
    
            const ddocName = rowData.dDocName;
            const fileName = rowData.docFileName;
    
            props.showSpinner();
            props.loadDocument(ddocName).then((doc) => {
                if (doc) {
                    const docData = base64ToArrayBuffer(doc.documentBytes);
                    const blob = new Blob([docData], {type: 'application/pdf'});
                    saveAs(blob, fileName);
                }
                props.hideSpinner();
            }).catch(error => {
                props.hideSpinner();
                props.showError(['Download Error', error.message || error]);
                tagManagerPopup('Download Error', props.claim?.claimNumber);
            });
        };

        return (
            <div>
                    { tabValue === 0 &&
                         <QnectButton field="downloadDoc" onClick={() => saveAsFile(selectedRowData)}/>
                    }
                    { tabValue === 1 &&
                        <>
                            <div>
                                <Button color="secondary" onClick={handleClick} >
                                    <div className={classes.whiteButtonStyle}>
                                        <div style={{marginRight: "11px"}}>Actions</div>
                                        <div style={{fill: '#1D4FBB'}}><Arrow/></div>
                                    </div>
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    disableScrollLock={true}>
                                    <MenuItem onClick={() => {callUpdateDocDialog('update', selectedRowData)}}>Edit</MenuItem>
                                    <Divider sx={{ my: 0.5 }} />
                                    <MenuItem onClick={() => {saveAsFile(selectedRowData)}}>Download</MenuItem>
                                </Menu>
                            </div>
                        </>
                    }
                </div>
        )
    }

    const columns = [
        {title: tabValue === 0 ? 'UPLOADED BY' : 'PROVIDER' , field: 'author', sorting: false},
        {title: 'DOCUMENT NAME', field: 'docFileName', sorting: false},
        {title: 'DESCRIPTION', field: 'description', sorting: false},
        {title: 'UPLOAD DATE', field: 'uploadDate', sorting: true,
            render: rowData => <div>{formatDate(rowData.uploadDate)}</div>},
        {title: '', sorting: false, render: (rowData) => (
            <ColumnAction selectedRowData={rowData}></ColumnAction>
        )}
    ];

    const MyTitle = ({text, variant}) => (
        <div>
            <Typography variant={variant}
                        style={{whitespace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: '100%'}}>
                {text} - {props.claim.claimNumber}
            </Typography>
        </div>
    );

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        loadDocuments(newValue === 0 ? 'R': 'U');
        if (event) {
            tagManagerClickEvent('Tab-Click', newValue === 0 ? 'Received Documents': 'Uploaded Documents');
        }
    };

    const callUploadDocument = () => {
        history.push('/uploadDocuments');
    };

    const back = () => {
        history.goBack();
    };

    const resetShowUploadedDocs = () => {
        props.document.showUploadedDocs = false;
        props.document.downloadDocs = [];
    };

    const updateDocument = (docData) => {
        props.showSpinner();
        props.updateDocument(docData, action).then((resp) => {
            props.hideSpinner();
            if (resp && resp.data === 'success') {
                showUserStatusMessage(action);
                loadDocuments('U');
            } else {
                props.showError([cfg('errorMessage.internalServerError')]);
                tagManagerPopup(cfg('errorMessage.internalServerError'), props.claim?.claimNumber);
            }
        }).catch(error => {
            props.hideSpinner();
            props.showError(['', error.message || error]);
            tagManagerPopup(error.message, props.claim?.claimNumber);
        });
        setAction(null);
    };


    const showUserStatusMessage = (userStatus) => {
        userStatus === 'update' ?
            showModalSuccess('Document edited', 'The document has been successfully edited.') :
            showModalSuccess('Document deleted', 'The document has been deleted.');
    };

    const showModalSuccess = (title, msg) => {
        props.showModal({
            flat: true,
            content: <StyledModalDiv>
                <div className="iconDivSuccess"><CheckCircleIcon fontSize='large'/></div>
                <div className="title">{title}</div>
                <div className="subtitle">{msg}</div>
                <div className="buttonDiv">
                    <QnectButton
                        field="close"
                        onClick={() => {onClickOk()}}
                    />
                </div>
            </StyledModalDiv>
        });
        tagManagerPopup(title, props.claim?.claimNumber, 1);
    };

    const onClickOk = () => {
        props.hideModal();
    };

    const handleSelectionChange = (rows) => {
        props.document.downloadDocs = rows;
    }

    const MultipleDownloadSection = () => {

        const handleSwitchChange = (event) => {
            setIsEnabledMultipleDocDownload(event.target.checked);
            if (!event.target.checked) {
                props.document.downloadDocs = [];
            }
        }

        const downloadMultipleDocs = async () => {
            if (props.document.downloadDocs.length === 0) {
                props.showError(['Please select documents to download.']);
            } else {
                props.document.downloadDocs.forEach((rowData) => {
                    console.log(rowData);
                    const ddocName = rowData['dDocName'];
                    const fileName = rowData['docFileName'];
        
                    props.showSpinner();
                    props.loadDocument(ddocName).then((doc) => {
                        if (doc) {
                            const docData = base64ToArrayBuffer(doc.documentBytes);
                            const blob = new Blob([docData], {type: 'application/pdf'});
                            saveAs(blob, fileName);
                        }
                        props.hideSpinner();
                    }).catch(error => {
                        props.hideSpinner();
                        props.showError(['Download Error', error.message || error]);
                        tagManagerPopup('Download Error', props.claim?.claimNumber);
                    });
                });
            }
        }

        return (
            <>
                {tabValue===0 && 
                    <span>
                        <FormControlLabel control={<Switch checked={isEnabledMultipleDocDownload} 
                                    onChange={handleSwitchChange} 
                                    inputProps={{ 'aria-label': 'controlled' }} />} 
                                    label={cfg("receivedDocuments.multipleDocDownload")} />
                        <QnectButton
                            disabled={!isEnabledMultipleDocDownload}
                            onClick={downloadMultipleDocs}>
                            {cfg("receivedDocuments.downloadDocuments")}
                        </QnectButton>
                    </span>
                }
            </>
        )
    }

    return (
        <>
            <Container>
            <div className={classes.box}>
                <TopWrapper>
                <Hidden mdDown>
                    <BackWrapper onClick={() => back()}>
                    <BackIcon/>{cfg('backBtn.label')}
                    </BackWrapper>
                    <Title>{cfg('viewDocuments.title')}</Title>
                </Hidden>
                </TopWrapper>
            </div>

            <div className={classes.page_container}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Grid direction='row' container spacing={1}>
                                <Grid container item sm={7}>
                                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                        <Tab label={cfg("viewDocuments.receivedDocuments")} {...a11yProps(0)} />
                                        <Tab label={cfg("viewDocuments.uploadedDocuments")} {...a11yProps(1)} />
                                    </Tabs>
                                </Grid>
                                <Grid container item sm={5}>
                                    <MultipleDownloadSection/>
                                </Grid>
                            </Grid>
                        </Box>
                        <br/><br></br>
                        <TabPanel value={tabValue} index={0}>
                            <MaterialUiTable
                                columns={columns} data={data}
                                title={<MyTitle variant="h6" text={cfg("viewDocuments.receivedDocuments")} />}
                                selection={isEnabledMultipleDocDownload}
                                onSelectionChange={handleSelectionChange}
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <MaterialUiTable
                                columns={columns} data={data}
                                title={<MyTitle variant="h6" text={cfg("viewDocuments.uploadedDocuments")} />}
                                onClickAdd={() => callUploadDocument()}
                                addText={cfg("uploadDocuments.title")}
                            />
                        </TabPanel>
                    </Box>
            </div>

             <UpdateDocumentDialog open={action !== null}
                                   onClose={() => setAction(null)}
                                   onOk={(docData) => {updateDocument(docData)}}
                                   rowData={selectedRow}
                                   action={action}>
             </UpdateDocumentDialog>

           </Container>
        </>
    );
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        lang: _getLang(state),
        claim: _getClaim(state),
        document: _getDocument(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showModal: _showModal,
    hideModal: _hideModal,
    showError: _showError,
    getClaimDocs: _getClaimDocs,
    loadDocument: _loadDocument,
    setDocumentToken: _setDocumentToken,
    updateDocument: _updateDocument
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewDocuments));
