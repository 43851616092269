import {property} from "lodash";
import * as type from "./types";
import axios from "axios";
import {_getCpToken, _getClaimsToken} from "../system/service";
import {_sendLog, formatDate} from "../../utils/utility";
import moment from "moment";

const authCpHeader = (state) => {
    const cpToken = _getCpToken(state);
    if (cpToken) {
        return { 'Authorization': 'Bearer ' + cpToken };
    } else {
        return {};
    }
}

export const _storeInvoice = (invoiceDetail) => {
    let invoiceData = formatInvoiceDetailsData(invoiceDetail);
    return (dispatch) => {
      dispatch({
        type: type.SET_INVOICE,
        data: invoiceData
      })
    };
}

export const _resetInvoice = () => {
    return (dispatch) => {
      dispatch({
        type: type.RESET_INVOICE
      })
    };
}

export const _getInvoiceRaterDetails = (calculationMethod) => {
  return (_dispatch, getState) => {
      return new Promise((resolve, reject) => {
          let config = {
              headers: authCpHeader(getState()),
              timeout: 60000
          };
          const requestBody = {
              calculationMethod: calculationMethod,
              claims: _getClaimsToken(getState())
          };
          axios.post(process.env.REACT_APP_GET_INVOICE_RATER_DETAILS, requestBody, config)
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                  _sendLog(['Supplier Portal React - Invoice Service - _getInvoiceRaterDetails', JSON.stringify(error)],
                      'error');
                  reject(error);
              })
      })
  }
}

export const _submitInvoice = (invoiceDetailsForm) => {
  return (_dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const requestBody = {
        invoiceDetailsForm: JSON.stringify(invoiceDetailsForm),
        claimsToken: _getClaimsToken(getState())
      };

      let config = {
        headers: authCpHeader(getState())
      };

      axios.post(process.env.REACT_APP_SUBMIT_INVOICE, requestBody, config).then(resp => {
          resolve(resp);
      }).catch(error => {
          _sendLog(['Supplier Portal React - Invoice Service - _submitInvoice', JSON.stringify(error)],
                'error');
          reject(error);
      })
    })
  }
}

export const _getInvoices = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    claim: _getClaimsToken(getState())
                },
                timeout: 60000
            };

            axios.get(process.env.REACT_APP_GET_INVOICE_LIST, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - Invoice Service - _getInvoices', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}


const formatInvoiceDetailsData = (data) => {
  let formattedRawData = {};

  if (data) {
      formattedRawData.invoiceNumber = data.invoiceNumber;
      formattedRawData.type = data.type;
      formattedRawData.providerInvoiceNumber = data.providerInvoiceNumber;
      formattedRawData.totalFee = data.totalFee;
      formattedRawData.status = data.status;
      formattedRawData.submittedBy = data.submittedBy;
      formattedRawData.statusLong = getInvoiceStatus(data.status);
      formattedRawData.dateSubmitted = formatDate(data.dateSubmitted);
      formattedRawData.rejectionReason = data.rejectionReason;
      formattedRawData.rejectionComments = data.rejectionComments;

      if (data?.rejectionReason?.toLowerCase() === 'others') {
          formattedRawData.rejectionReason += " : " + data?.rejectionReasonOthers;
      }
  }
  return formattedRawData;
}

export const getInvoiceStatus = (status) => {
  let statDesc = '';
  let stats = StatusRemarks.find(statusRemark => (status !== null || status !== '') &&
    statusRemark.value.toLowerCase() === status.toLowerCase());
  if (stats) statDesc = stats.description;

  return statDesc;
}

export const StatusRemarks =
  [
    {
        value:  "Under Review",
        status:  "invoice.status.underReview",
        description: "invoice.status.underReviewDesc"
    },
    {
        value:  "Rejected",
        status:  "invoice.status.rejected",
        description: "invoice.status.rejectedDesc"
    },
    {
        value:  "Approved",
        status:  "invoice.status.approvedDesc",
        description: "invoice.status.approvedDesc"
    },
    {
        value:  "Paid",
        status:  "invoice.status.paid",
        description: "invoice.status.paidDesc"
    }
  ]

export const _getInvoice = property ( 'invoice' );
