import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Back } from "../../assets/login/back_sharp.svg";

export const BackIcon = styled(Back)`
  margin-right: 8px;
`;

export const Container = styled.div`
  padding-bottom: 64px;
`;

export const TopWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1110px;
  height: 100%;
  position: relative;
`;

export const BackWrapper = styled.div`
  margin-top: 40px;
  width: 87px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #1d4fbb;
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
`;

export const Title = styled.div`
  margin-left: 20px;
  margin-top: 40px;
  font-size: 40px;
  line-height: 48px;
  font-family: Stag;
  font-weight: 500;
  letter-spacing: 1px;
  color: #003da5;
`;

const useClaimsDetailsStyles = makeStyles(() => ({

  member_input: {
    margin: "0 auto",
    width: "auto",
    maxWidth: "320px",
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '& div' : {
      textAlign: 'center',
    },
    '& .MuiFormControl-root.claimNumber' : {
      marginTop: '40px',
    },
    '& button' : {
      marginTop: '30px',
    }
  },

  errorMessage: {
    marginBottom: "10px",
      "& .alert": {
        marginTop: "0px"
      }
  },

  claimsdetails_header_rectangle: {
    margin: '0 auto',
    height: 'auto',
    width: '600px',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '2px 2px 8px 0 rgba(20,40,75,0.2)',
    padding: '25px',
    color: '#003da5'
  },

  claimsdetails_header_content: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  claimsdetails_header_item: {
    flex: '30%',
    width: '100%'
  },  

  claimsdetails_details_rectangle: {
    margin: '0 auto',
    height: 'auto',
    width: '600px',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '2px 2px 8px 0 rgba(20,40,75,0.2)',
    marginTop: '40px',
    padding: '25px',
    color: '#003da5'
  },

  product_type_icon: {
    height: '43.71px',
    width: '46.77px'
  },

  download_icon: {
    height: '13.46px',
    width: '11.08px',
    marginLeft: '10px'
  },

  product_type_label: {
    height: '25px',
    width: '100%',
    color: '#003DA5',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: '25px',
    flex: '100%'
  },

  claim_number_label: {
    height: 'auto',
    width: 'auto',
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 'bold',
    flex: '50%'
  },

  claim_number_status_box: {
    height: 'auto',
    width: 'auto',
    borderRadius: '6px',
    background: 'rgba(0,193,204,0.08)',
    padding: '5px',
    textAlign: 'justify',
    overflowWrap: 'break-word'
  },

  claim_number_line: {
    height: '4px',
    width: 'auto',
    backgroundColor: '#F1F2F4',
    marginTop: '2%'
  },

  data_label: {
    height: '21px',
    width: '390.2px',
    color: '#8A94A6',
    fontFamily: 'Inter',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '21px',
    paddingTop: '20px'
  },

  data_content: {
    height: 'auto',
    width: 'auto',
    color: '#0A1F44',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0',
    paddingTop: '4%',
    whiteSpace: 'pre-wrap', 
    overflowWrap: 'break-word'
  },

  downloadDocument: {
      marginLeft: '50px !important',
      marginTop: '9px !important',
      textAlign: "right",
      flex: '50%',
      width: '100%'
  },

  claim_num_label: {
      height: '21px',
      width: '100%',
      color: '#8A94A6',
      fontFamily: 'Inter',
      fontSize: '14px',
      letterSpacing: '0',
      lineHeight: '21px',
      paddingTop: '20px',
      flex: '50%'
  },

  claim_num_content: {
      height: '24px',
      width: '100%',
      color: '#0A1F44',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0',
      lineHeight: '24px',
      paddingTop: '20px',
      paddingBottom: '20px',
      flex: '50%'
  },

  selectBtn: {
        height: "44px",
        border: "1px solid #1D4FBB",
        borderRadius: "35px",
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
        paddingLeft: "25px",
        paddingRight: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        lineHeight: "54px",
        color: "#1D4FBB",
        fontFamily: "Inter",
        fontSize: "15px",
        fontWeight: 500
  },

  whiteButtonStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  group_button: {
        margin: "0 auto",
        width: "auto",
        maxWidth: "370px",
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        '& div' : {
            textAlign: 'center',
        }
  }

}));

export default useClaimsDetailsStyles;