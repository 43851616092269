import React, {useEffect, useRef, useState} from "react";
import {
    _clearToken, _getCpToken,
    _getLang,
    _getTimerStarted,
    _hideModal,
    _setTimerStarted,
    _showModal
} from "../store/system/service";
import {connect} from "react-redux";
import QnectButton from "./qnect-ui/qnect-button";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import useConfig from "./qnect-ui/qnect-config";
import {_logout, _resetUser} from "../store/user/service";
import {_resetUserList} from "../store/admin/service";
import {_resetClaim} from "../store/claims/service";
import IdleTimer from 'react-idle-timer'

export const StyledModalDiv = styled.div`
  width: 504px;
  text-align: center;

  div.iconDiv {
    margin-top: 40px;
  }

  div.title {
    font-family: Stag-Medium;
    font-size: 24px;
    color: #003da5;
    line-height: 36px;
    margin-top: 16px;
  }

  div.content {
    margin-top: 41px;
    margin-bottom: 40px;
    font-family: Inter-Regular;
    font-size: 18px;
    white-space: pre-wrap
  }

  div.buttonDiv {
    margin-top: 40px;
    margin-left: 161px;
    margin-right: 163px;
  }
  @media (max-width:767.95px) {
     width: auto;
     div.buttonDiv {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const StyledQnectButton = styled(QnectButton)`
  width: 180px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
`;

export const SessionTimeout = (props) => {
    const history = useHistory();
    const cfg = useConfig(props.lang);
    const [timeoutDuration, setTimeoutDuration] = useState(3600000); // expire session after 1 hour
    const [isTimedOut, setIsTimedOut] = useState(false);
    const idleTimer = useRef();

    const logout = () => {
        props.clearToken();
        props.resetClaim();
        props.resetUser();
        props.resetUserList();
        history.push("/login");
    };

    const onAction = () => {
        setIsTimedOut(false);
    };

    const onActive = () => {
        setIsTimedOut(false);
    };

    const onIdle = () => {
        if (!isTimedOut) {
            idleTimer.current.reset();
            setIsTimedOut(true);
        }
    };

    useEffect(() => {
        if (isTimedOut) {
            props.logout();
            props.showModal({
                flat: true,
                content: <StyledModalDiv>
                    <div className="title">
                        {cfg('labels.sessionTimeout')}
                    </div>
                    <div className="content">{cfg('labels.sessionTimeoutMessage')}</div>
                    <div className="buttonDiv">
                        <StyledQnectButton
                            field="continue"
                            onClick={() => logout()} />
                    </div>
                </StyledModalDiv>
            });
        }
    }, [isTimedOut]);

    return (
        <>
            <div>
                <IdleTimer
                    ref={idleTimer}
                    element={document}
                    onActive={onActive}
                    onIdle={onIdle}
                    onAction={onAction}
                    debounce={250}
                    timeout={timeoutDuration} />
            </div>
        </>
    );

};

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        timerStarted: _getTimerStarted(state),
        cpToken: _getCpToken(state)
    }
};

const mapDispatchToProps = {
    showModal: _showModal,
    hideModal: _hideModal,
    resetUser: _resetUser,
    setTimerStarted: _setTimerStarted,
    clearToken: _clearToken,
    resetUserList: _resetUserList,
    resetClaim: _resetClaim,
    logout: _logout
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionTimeout);

