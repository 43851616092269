import React, {useEffect, useState} from "react";
import QnectTextField from "../qnect-ui/qnect-text-field";
import QnectSelectField from "../qnect-ui/qnect-select-field";
import QnectCheckBox from "../qnect-ui/qnect-check-box";
import FieldWrapper from "../custom/field-wrapper";
import QnectButton from "../qnect-ui/qnect-button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../custom/dialog-title";
import DialogSecondaryTitle from "../custom/dialog-secondary-title";
import Dialog from '../custom/dialog';
import {useDialogStyles, StyledCheckBoxErrorDiv, CheckBoxWrapper, StyledCheckBoxContent} from "../custom/dialog-style";
import useConfig from "../qnect-ui/qnect-config";
import { _validateForm, _isNotDecimalChar } from "../../utils/utility";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export const AddEditUserDialog = ({open = false, onClose, onOk, isAdmin=false, providers, rowData, country, ...props}) => {
    const classes = useDialogStyles();
    const cfg = useConfig();
    const formId = 'addEditUserForm';

    const [userId, setUserId] = useState('');
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [mobileNo, setMobileNo] = useState(null);
    const [providerName, setProviderName] = useState(null);
    const [userType, setUserType] = useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [providerNameErrMsg, setProviderNameErrMsg] = useState('');
    const [providerEntityErrMsg, setProviderEntityErrMsg] = useState('');
    const [providerHKG, setProviderHKG] = useState(false);
    const [providerHGI, setProviderHGI] = useState(false);
    const [providerEntity, setProviderEntity] = useState(false);

    const isAdd = rowData === null ? true : false;
    
    useEffect(() => {
        if (isAdd) {
            setUserId('');
            setName(null);
            setEmail(null);
            setMobileNo(null);
            setProviderName(null);
            setProviderHKG(false);
            setProviderHGI(false);
            setProviderEntity(country !== 'HKG' && country !== 'HGI' ? country : null);
            setUserType(null);
        } else {
            setUserId(rowData?.userId);
            setName(rowData?.name);
            setEmail(rowData?.email);
            setMobileNo(rowData?.mobileNo);
            setProviderName(rowData?.providerName);
            setProviderHKG(rowData?.providerHKG);
            setProviderHGI(rowData?.providerHGI);
            setProviderEntity(rowData?.providerEntity);
            setUserType(rowData?.userType);
        }
        setInputValue('');
        setProviderNameErrMsg('');
        setProviderEntityErrMsg('');
    }, [rowData, open, country, isAdd]);

    const onClickOk = () => {
        if (_validateForm(formId) && validateOtherFields()) {
            let obj = {
                userId: userId,
                name: name,
                email: email,
                mobileNo: mobileNo,
                providerName: providerName,
                providerHKG: providerHKG,
                providerHGI: providerHGI,
                providerEntity: providerEntity,
                userType: userType,
            }
            onOk(obj);
        }
    }

    const validateOtherFields = () => {
        setProviderNameErrMsg('');
        setProviderEntityErrMsg('');
        let valid = true;
        if (!isAdmin) {
            if (providerName === null) {
                valid = false;
                setProviderNameErrMsg(cfg('errorMessage.providerNameRequired'));
            }
            if (country === 'HKG' && !providerHKG && !providerHGI) {
                valid = false;
                setProviderEntityErrMsg(cfg('errorMessage.entityTypeRequired'));
            }
        }
        return valid;
    }

    return (
        <div>
            <Dialog open={open} onClose={onClose} className={classes.modal_box_container}>
                <DialogTitle className={classes.title}
                    title={
                        <div>
                            {isAdd &&
                            <>
                                <AddCircleOutlineIcon fontSize='large'/><br/>
                                {cfg("userDetailModals.addUser.title")}
                            </>}
                            {!isAdd &&
                            <>
                                <EditIcon fontSize='large'/><br/>
                                {cfg("userDetailModals.editUser.title")}
                            </>}
                        </div>}
                        closable={false} lang={props.lang}
                />
                <DialogSecondaryTitle title={cfg("userDetailModals.addUser.subTitle")} />
                <DialogContent>
                     <div className={classes.modal_box_form_container}>
                         <div className='modal_box_form_field input_name'>
                             <FieldWrapper >
                                 <QnectTextField
                                     formId={formId}
                                     field="nameUser"
                                     value={name}
                                     onChange={ val => {
                                         setName(val.target.value)
                                     }}
                                 />
                             </FieldWrapper>
                         </div>
                         <div className='modal_box_form_field input_name'>
                             <FieldWrapper >
                                 <QnectTextField
                                     formId={formId}
                                     field="emailUser"
                                     disabled={!isAdd}
                                     value={email}
                                     onChange={ val => {
                                         setEmail(val.target.value)
                                     }}
                                 />
                             </FieldWrapper>
                         </div>
                         <div className='modal_box_form_field input_name'>
                             <FieldWrapper >
                                 <QnectTextField
                                     formId={formId}
                                     field="mobileNoUser"
                                     value={mobileNo}
                                     onChange={ val => {
                                        setMobileNo(val.target.value)
                                     }}
                                     onKeyPress={event => {
                                        if (_isNotDecimalChar(event.key)) {
                                          event.preventDefault();
                                        }
                                     }}
                                 />
                             </FieldWrapper>
                         </div>
                         {isAdmin === false && 
                         <>
                         <div className='modal_box_form_field input_name'>
                             <FieldWrapper >
                                 <Autocomplete
                                    formId={formId}
                                    disablePortal
                                    id="combo-box-demo"
                                    value={providerName}
                                    onChange={(event, newValue) => {
                                        setProviderName(newValue);
                                        setProviderNameErrMsg('');
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                        setProviderNameErrMsg('');
                                    }}
                                    options={providers}
                                    sx={{ width: 383, backgroundColor: '#f1f2f4'}}
                                    renderInput={(params) => 
                                        <TextField {...params} label="Provider Name" variant="standard"/>}
                                    />
                                    { providerNameErrMsg &&
                                    <StyledCheckBoxErrorDiv>
                                        {providerNameErrMsg}
                                    </StyledCheckBoxErrorDiv>
                                    }
                             </FieldWrapper>
                         </div>
                            {country === 'HKG' &&
                            <>
                                <div className={classes.selectEntityLabel}>{cfg("entity.label")}</div>
                                <div className='modal_box_form_field input_name'>
                                    <CheckBoxWrapper>
                                        <QnectCheckBox
                                            formId={formId}
                                            checked={providerHKG}
                                            onChange={(val) => {
                                                setProviderHKG(val);
                                            }}
                                            >
                                            <StyledCheckBoxContent>{'HKG'}</StyledCheckBoxContent>
                                        </QnectCheckBox>
                                    </CheckBoxWrapper>
                                    <CheckBoxWrapper>
                                        <QnectCheckBox
                                            formId={formId}
                                            checked={providerHGI}
                                            onChange={(val) => {
                                                setProviderHGI(val);
                                            }}
                                            >
                                            <StyledCheckBoxContent>{'HGI'}</StyledCheckBoxContent>
                                        </QnectCheckBox>
                                        { providerEntityErrMsg &&
                                        <StyledCheckBoxErrorDiv>
                                            {providerEntityErrMsg}
                                        </StyledCheckBoxErrorDiv>
                                        }
                                    </CheckBoxWrapper>
                                </div>
                            </>}
                         </>}
                         
                        {isAdmin === true && 
                        <div className='modal_box_form_field input_name'>
                            <FieldWrapper >
                                <QnectSelectField
                                    formId={formId}
                                    field="userType"
                                    value={userType}
                                    onChange={ (val) => setUserType(val) }
                                />
                            </FieldWrapper>
                        </div>}
                     </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <QnectButton onClick={onClose} field="cancel" />
                    <QnectButton onClick={() => onClickOk()} field="confirm"/>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddEditUserDialog;

