import React from 'react';
import Header from "../components/custom/header";
import ViewInvoiceDetails from '../components/invoice/view-invoice-details';
import SessionTimeout from "../components/session-timeout";

const ViewInvoiceDetailsPage = () => {

    return (
        <React.Fragment>
            {
                <div>
                    <Header />
                    <ViewInvoiceDetails/>
                    <SessionTimeout/>
                </div>
            }
        </React.Fragment>
    )
}

export default (ViewInvoiceDetailsPage);
