import React from "react";
import ResetPassword from "../components/reset/reset-password";

const ResetCyberarkPasswordPage = () => {
    return (
        <div>
            <ResetPassword isRegistration={false} isPasswordExpired={true} />
        </div>
    )
};

export default (ResetCyberarkPasswordPage);