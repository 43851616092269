import propTypes from 'prop-types';
import React from 'react';
import TextField from "../../ui/components/form-fields/text-field";
import {QnectValidator} from "./qnect-validator";
import useConfig from "./qnect-config";

const QnectTextField = React.forwardRef((
    {
        field,
        formId,
        label,
        value,
        placeholder,
        errorMessage,
        helperText,
        maxLength,
        onChange,
        onBlur,
        endAdornment,
        type,
        hasCustomValidation,
        isPatternEnforced,
        pattern,
        forceRefresh,
        ...rest
    },
    ref
) => {

    const cfg = useConfig();

    return (
        <QnectValidator
            formId={formId}
            field={field}
            onChangeCallback="onChange"
            onBlurCallback="onBlur"
            errorMessage={errorMessage}
            validators={cfg(field, "validators")}
            hasCustomValidation={hasCustomValidation}
            forceRefresh={forceRefresh}
        >
            <TextField
                label={label || cfg(field, "label")}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                helperText={helperText}
                errorMessage={cfg(errorMessage)}
                placeholder={placeholder}
                maxLength={maxLength}
                endAdornment={endAdornment}
                type={type}
                pattern={pattern}
                isPatternEnforced={isPatternEnforced}
                {...rest}
            >
            </TextField>
        </QnectValidator>
    )
});

QnectTextField.propTypes = {
    field: propTypes.string,
    formId: propTypes.string,
    label: propTypes.string,
    value: propTypes.string,
    className: propTypes.string,
    maxLength: propTypes.number,
    helperText: propTypes.string,
    placeholder: propTypes.string,
    errorMessage: propTypes.string,
    onChange: propTypes.func,
    onBlur: propTypes.func,
};

QnectTextField.displayName = 'QnectTextField';

export default QnectTextField;
