import React from 'react';

import styled from "styled-components";
import propTypes from "prop-types";
import IconAlert from "../../ui/components/icons/legacy/alert";
import useConfig from "./qnect-config";
import {_getLang} from "../../store/system/service";
import {connect} from "react-redux";

const StyledErrorIconAlert = styled(IconAlert)`
    display: inline-block !important;
    vertical-align: top;
    margin-right: 10px;
    align-self: flex-start;
`;

const StyledErrorDiv = styled.div`
    display: block;
    padding: 0px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    .TopContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 7.5px;
        color: #A50000;
        font-family: Inter-Bold;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
    }
    
`;

const StyledErrorLabel = styled.div`
    margin-top: 3px;
    font-family: Inter Medium, Inter-Medium, Inter, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: #D54040;
    vertical-align: top;
    text-align: center;
    padding: 10px 4px 10px 4px;
`;


const StyledErrorList = styled.ul`
    margin-top: 5px;
    margin-bottom: 5px;
`;


const QnectError= ({lang, errorTitle, errorMessage, errorList}) => {

    const cfg = useConfig(lang);

    return (
        <>
        {
            (errorMessage || errorList) &&
            <StyledErrorDiv>
                <StyledErrorLabel className={'alert'}> {cfg(errorMessage) ? cfg(errorMessage) : errorMessage}
                    {
                        errorList &&
                        <StyledErrorList>
                            {
                                errorList.map(item => (
                                    <li>{item}</li>
                                ))
                            }
                        </StyledErrorList>
                    }
                </StyledErrorLabel>
            </StyledErrorDiv>
        }
        </>
    );
}
QnectError.propTypes = {
    errorTitle: propTypes.string,
    errorMessage: propTypes.string
}

QnectError.displayName = 'QnectError';

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
};

export default connect(mapStateToProps)(QnectError);
