import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';
import {MenuItem} from '@material-ui/core';

import DropDown from '../dropdowns/drop-down';

const StyledMenuItem = styled(MenuItem)`
    &.MuiListItem-button {
        background: ${property('theme.color.grey10')};
        white-space: break-spaces;
      
        &:hover {
            background: ${property('theme.color.grey30')};
            color: ${property('theme.color.primary')};
        }
    }
`;

const SelectField = React.forwardRef((
    {
        className,
        name,
        label,
        helperText,
        value,
        onChange,
        onBlur,
        errorMessage,
        disabled,
        placeholder,
        options = [],
        field,
        ...rest
    },
    ref
) => (
    <DropDown
        {...rest}
        ref={ref}
        className={classnames('SelectField', className)}
        name={name}
        label={label}
        value={value || ''}
        isDisabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        helperText={errorMessage || helperText}
        isError={!!errorMessage}
        field={field}
    >
        { /*<StyledMenuItem
            value=""
            disabled
        >
            {placeholder}
        </StyledMenuItem>
        */}
        {(options || []).map(({optionLabel, optionValue}) => (
            <StyledMenuItem
                key={optionValue}
                value={optionValue}
            >
                {optionLabel}
            </StyledMenuItem>
        ))}
    </DropDown>
));

SelectField.propTypes = {
    className: propTypes.string,
    name: propTypes.string,
    label: propTypes.string,
    helperText: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    onBlur: propTypes.func,
    errorMessage: propTypes.string,
    options: propTypes.arrayOf(propTypes.shape({
        value: propTypes.string,
        text: propTypes.string,
    })),
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    placeholder: propTypes.string,
};

SelectField.displayName = 'SelectField';

export default SelectField;
