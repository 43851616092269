import React, {useState} from "react";
import QnectButton from "../qnect-ui/qnect-button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../custom/dialog-title";
import Dialog from '../custom/dialog';
import {useDialogStyles} from "../custom/dialog-style";
import {_validateForm} from "../../utils/utility";
import {MonetizationOn} from "@material-ui/icons";
import QnectRadioBox from "../qnect-ui/qnect-radio-box";
import {DialogContent, RadioBoxStyle} from "./invoice-style";
import DialogSecondaryTitle from "../custom/dialog-secondary-title";
import ServiceFeeIcon from "../../assets/invoice/service_fee.svg";
import ClaimAmountIcon from "../../assets/invoice/claim_amount.svg";
import ProviderIcon from "../../assets/invoice/provider.svg";
import { useHistory } from "react-router-dom";
import useConfig from "../qnect-ui/qnect-config";


export const InvoiceMethodDialog = ({open = false, onClose}) => {
    const cfg = useConfig();
    const classes = useDialogStyles();
    const formId = 'methodForm';
    const [method, setMethod] = useState('');
    const history = useHistory();

    const typeOptions = [
        {
            label: cfg('invoice.calculations.byClaimAmount'),
            value: 'BCA',
            image: ClaimAmountIcon
        },
        {
            label: cfg('invoice.calculations.byProvider'),
            value: 'BP',
            image: ProviderIcon
        },
        {
            label: cfg('invoice.calculations.byServiceFee'),
            value: 'BSF',
            image: ServiceFeeIcon
        }
    ];

    const onClickOk = () => {
        if (_validateForm(formId)) {
            if (method === 'BCA') {
                history.push("/invoice/byFixedRate");
            } else if (method === 'BP') {
                history.push("/invoice/byHourlyRate");
            } else if (method === 'BSF') {
                history.push("/invoice/others");
            }
        }
    };

    return (
        <div>
            <Dialog open={open} onClose={onClose} className={classes.modal_box_container}>
                <DialogTitle className={classes.title}
                             title={
                                 <div>
                                     <MonetizationOn fontSize='large'/><br/>
                                        {cfg("invoice.dialog.title")}
                                 </div>}
                                 closable={false}
                    />
                <DialogSecondaryTitle title={cfg("invoice.dialog.select")} />
                    <DialogContent>
                        <RadioBoxStyle>
                            <QnectRadioBox
                                name="calculationMethod"
                                options={typeOptions}
                                onChange={(value) => {
                                setMethod(value);
                                }}
                            />
                        </RadioBoxStyle>
                    </DialogContent>
                <DialogActions className={classes.actions}>
                    <QnectButton onClick={onClose} field="cancel" />
                    <QnectButton onClick={() => onClickOk()} field="confirm"/>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default InvoiceMethodDialog;
