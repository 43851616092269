import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Back } from "../../assets/login/back_sharp.svg";

export const StyledModalDiv = styled.div`
  width: 504px;
  text-align: center;
  
  div.iconDiv {
    margin-top: 40px;
  }

  div.iconDivSuccess {
    margin-top: 40px;
    color: #00865A;
  }
  div.iconDivFail {
    margin-top: 40px;
    color: #D43900;
  }

  div.title {
    font-family: Stag-Medium;
    font-size: 24px;
    color: #003da5;
    line-height: 36px;
    margin-top: 16px;
  }

  div.subtitle {
    font-family: Stag-Medium;
    font-size: 18px;
    color: #00205B;
    line-height: 36px;
    margin-top: 18px;
  }

  div.content {
    margin-top: 41px;
    margin-bottom: 40px;
    font-family: Inter-Regular;
    font-size: 18px;
    white-space: pre-wrap
  }

  div.buttonDiv {
    margin-top: 20px;
    margin-left: 70px;
    margin-right: 70px;
  }
  @media (max-width:767.95px) {
     width: auto;
     div.buttonDiv {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const BackIcon = styled(Back)`
  margin-right: 8px;
`;

export const Container = styled.div`
  padding-bottom: 30px;
  background-color: #FFFFFF;
`;

export const TopWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1110px;
  height: 100%;
  position: relative;
`;

export const BackWrapper = styled.div`
  margin-top: 40px;
  width: 87px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #1d4fbb;
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
`;

export const Title = styled.div`
  margin-left: 20px;
  margin-top: 40px;
  font-size: 40px;
  line-height: 48px;
  font-family: Stag;
  font-weight: 500;
  letter-spacing: 1px;
  color: #003da5;
`;

const useUploadDocumentStyles = makeStyles(theme => ({

    rectangle: {
        margin: '5vh auto',
        minHeight: '500px',
        width: '600px',
        borderRadius: '0 0 8px 8px',
        backgroundColor: '#FFFFFF',
        boxShadow: '2px 2px 8px 0 rgba(20,40,75,0.2)',
        textAlign: 'center'
    },

    fileUploadIcon: {
        color: "#003DA5",
        paddingTop: "20px"
    },

    fileUploadTitle: {
        width: '100%',
        paddingBottom: '3px',
        '& h2' : {
            fontFamily: 'Stag, sans-serif',
            color: '#003DA5',
            fontSize: '32px',
            letterSpacing: '1px',
            fontWeight: '400'
        }
    },

    claimNumber: {
        color: '#000000',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '24px',
        marginTop: '20px'
    },

    allowedFormatSize: {
        color: '#00205B',
        fontFamily: 'Inter',
        fontSize: '14px',
        letterSpacing: 0,
        lineHeight: '20px',
        margin: '5px 50px 5px 50px',
    },

    whiteButtonStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },

    page_container: {
        marginTop: '15px',
        width: '1250px',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
        [theme.breakpoints.between("1024", "1250")]: {
            width: '95%',
        },
        "& .MuiToolbar-regular" : {
            borderBottom: '1px solid #8A94A6',
            minHeight: '75px',
        },
        "& .MuiInputBase-root.MuiInput-formControl" : {
            minHeight: '42px',
            minWidth: '300px',
            padding: '8px 12px 8px 20px',
            border: '0',
            color: '#455571',
            fontFamily: 'Inter, Inter Regular, Inter-Regular, sans-serif',
            fontSize: '14px',
            lineHeight: '20px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '10px center',
            backgroundColor: '#F7F8F9',
            "& .MuiInputAdornment-positionEnd button" : {
                display: 'none',
            },
        },
        "& .MuiInputBase-root.MuiTablePagination-input" : {
            minHeight: '42px',
            border: '0',
            color: '#455571',
            fontFamily: 'Inter, Inter Regular, Inter-Regular, sans-serif',
            fontSize: '14px',
            lineHeight: '20px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '10px center',
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before" : {
            borderBottom: '0',
        },
        "& .MuiInput-underline:before" : {
            borderBottom: '0',
        },
        "& .MuiInput-underline:after" : {
            borderBottom: '0',
        },
        "& .MuiTableBody-root" : {
            fontSize: '12px',
            "& .tableTitle" : {
                color: '#0A1F44',
            },
            "& td": {
                fontFamily: 'Inter, Inter Regular, Inter-Regular, sans-serif',
                fontSize: '400',
            },
        },
        "& .MuiTableCell-root" : {
            padding: '20px 16px',
            paddingLeft: '25px'
        },
        "& .MuiTableFooter-root .MuiTablePagination-toolbar" : {
            minHeight: '75px',
        },
    },

    rowTitle: {
        display: 'flex'
    },

    toggle: {
        paddingLeft: '20px'
    },

    member_input: {
        margin: "0 auto",
        width: "320px",
        maxWidth: "320px",
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '40px',
        paddingBottom: '40px',
        '& div' : {
            textAlign: 'center',
        },
        '& .MuiFormControl-root.fileDescription' : {
            marginTop: '40px',
        }
    },

    errorMessage: {
        marginBottom: "10px",
        "& .alert": {
            marginTop: "0px"
        }
    }

}));

export default useUploadDocumentStyles;