import { Hidden } from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import { useHistory, withRouter } from "react-router-dom";
import useConfig from "../../qnect-ui/qnect-config";
import { _getCountry, _getLang, _hideSpinner, _showSpinner, _showModal, _hideModal, _showError } from "../../../store/system/service";
import { _getClaim } from "../../../store/claims/service";
import useCalculationStyles, { BackIcon, BackWrapper, Container, DivItemStyle, ItemControl, ItemStyle, Title, TopWrapper } from "./calculation-style";
import {ReactComponent as ProviderIcon} from "../../../assets/invoice/provider_selected.svg";
import { connect } from "react-redux";
import QnectError from "../../qnect-ui/qnect-error";
import QnectTextField from "../../qnect-ui/qnect-text-field";
import FieldWrapper from "../../custom/field-wrapper";
import QnectCheckBox from "../../qnect-ui/qnect-check-box";
import QnectButton from "../../qnect-ui/qnect-button";
import UploadDocument from "../../upload-document/upload-document";
import {
    _formatAmount,
    _formatDecimalAmount,
    _formatNumber,
    _getCurrency,
    _removeDecimalFormatting,
    _validateForm
} from "../../../utils/utility";
import { Button, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import RoundButton from "../../../ui/components/buttons/button";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import QnectSelectField from "../../qnect-ui/qnect-select-field";
import {ReactComponent as AddFile} from "../../../assets/AddFile.svg"
import {ReactComponent as Cross} from "../../../assets/Cross.svg";
import { _getInvoiceRaterDetails, _submitInvoice } from "../../../store/invoice/service";
import BalloonTip from "../../ui/balloon-tip/balloon-tip";
import SubmitInvoiceDialog from "./submit-invoice-dialog";

export const ProviderCalculation = (props) => {
    const classes = useCalculationStyles(props);
    const history = useHistory();
    const cfg = useConfig(props.lang);
    const ref = useRef(null);
    const formId = 'byProvider';
    const ratesFormId = 'providerRates';
    const [errorMessage, setErrorMessage] = useState("");
    const [providerInvoiceNo, setProviderInvoiceNo] = useState("");
    const [providerLevelRole, setProviderLevelRole] = useState("");
    const [noOfHours, setNoOfHours] = useState("");
    const [noOfMinutes, setNoOfMinutes] = useState("");
    const [roleBaseRates, setRoleBaseRates] = useState([]);
    const [isDiscount, setIsDiscount] = useState(false);
    const [discountPercentage, setDiscountPercentage] = useState("");
    const [discountAmount, setDiscountAmount] = useState("");
    const [selectedDiscountBasis, setSelectedDiscountBasis] = useState("");
    const [isQbeShare, setIsQbeShare] = useState(false);
    const [qbeShareRate, setQbeShareRate] = useState("");
    const [isDisbursement, setIsDisbursement] = useState(false);
    const [disbursements, setDisbursements] = useState([]);
    const [totalFee, setTotalFee] = useState("0.00");
    const currency = _getCurrency(props.country);
    const MAX_PERCENT_LIMIT = 100;
    const [gst, setGST] = useState("");
    const [totalInvoiceAmount, setTotalInvoiceAmount] = useState("0.00");
    const [providerLevelOptions, setProviderLevelOptions] = useState([]);
    const [invoiceRateDetails, setInvoiceRateDetails] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [identifier, setIdentifier] = useState("") ;
    const [isSubmit, setIsSubmit] = useState(false);
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);
    const [invoiceForm, setInvoiceForm] = useState(null);

    useEffect(() => {
        resetData();
        loadInvoiceRate();
    }, []);

    useEffect(() => {
        if (roleBaseRates && roleBaseRates.length > 0) {
            calculateTotalFee();
        }
    }, [gst]);

    useEffect(() => {
        if (isSubmit) {
            submit();
            setIsSubmit(false);
        }
    },[isSubmit]);

    const loadInvoiceRate = () => {
        props.showSpinner();
        props.getInvoiceRaterDetails('BP').then((response) => {
            setInvoiceRateDetails(response?.providerRateList);
            const options = response?.providerRateList.map(elem => ({
                "optionLabel": elem.role,
                "optionValue": elem.role
            }))

            if (options && options.length === 0) {
                setErrorMessage("No rate configuration found.");
            }

            setProviderLevelOptions(options);
            props.hideSpinner();
        }, (error) => {
            console.log('loadInvoiceRate - error occurred', error);
            props.hideSpinner();
        })
    };

    const changeDiscount = (evt) => {
        setIsDiscount(evt);
        if (evt) {
            setSelectedDiscountBasis("discountPercentageRadio");
        } else {
            setDiscountAmount("");
            setDiscountPercentage("");
        }
    };

    const changeDiscountBasis = (value) => {
        setSelectedDiscountBasis(value);
        if (value === 'discountPercentageRadio') {
            setDiscountAmount("");
        } else {
            setDiscountPercentage("");
        }
    };

    const changeDisbursement = (evt) => {
        setIsDisbursement(evt);
        setDisbursements([]);
        if (evt) {
            handleAddRow();
        }
    };

    const handleUpdateDisbursementFields = (index, field, val) => {
        setErrorMessage([]);
        setDisbursements(prev => {
            const newState = prev.map((obj, i) => {
                if (i === index ) {
                    if (field === 'disbursementDesc') {
                        return {...obj, disbursementDesc: val};
                    } else if (field === 'disbursementAmt') {
                        return {...obj, disbursementAmt: val};
                    }
                }
                return obj;
            });
            return newState;
        });
    };

    const changeQbeShare = (evt) => {
        setIsQbeShare(evt);
    };

    const handleRemoveRow = (idx) => {
        setErrorMessage([]);

        const data = disbursements.filter((item, i) => i !== idx);
        const newArr = [];
        const item = {
            disbursementDesc: '',
            disbursementAmt: ''
        };
        setDisbursements(prev => {
            if (prev.length === 1) {
                return [...newArr, item];
            } else  {
                return data;
            }
        });
    };

    const handleAddRow = () => {
        setErrorMessage([]);
        if (disbursements.length >= 10) {
            setErrorMessage("Maximum number of rows reached for disbursements.");
            return;
        }
        const item = {
            disbursementDesc: '',
            disbursementAmt: ''
        };
        setDisbursements([...disbursements, item]);
    };

    const validateDiscountLimit = (val) => {
        const v = _removeDecimalFormatting(val);
        if (v > MAX_PERCENT_LIMIT) {
            return;
        } else {
            setDiscountPercentage(val);
        }
    };

    const validateQbeShareLimit = (val) => {
        const v = _removeDecimalFormatting(val);
        if (v > MAX_PERCENT_LIMIT) {
            return;
        } else {
            setQbeShareRate(val);
        }
    };

    const addProviderRate = () => {
        if (roleBaseRates.length >= 10) {
            setErrorMessage("Maximum number of rows reached for staff level rates.");
            return;
        }

        if (_validateForm(ratesFormId)) {
            const providerLevelRateObj = invoiceRateDetails.find(obj => {
                return (obj.role === providerLevelRole)
            });
            const totalAmount = parseFloat(_removeDecimalFormatting(providerLevelRateObj?.hourlyRate)) * 
                    parseFloat(parseFloat(noOfHours) + parseFloat(parseFloat(noOfMinutes) / 60))
            const item = {
                providerLevel: providerLevelRole,
                noOfHours: noOfHours,
                noOfMinutes: noOfMinutes,
                rateAmount: _formatAmount(totalAmount, true)
            };
            setRoleBaseRates(prev => [...prev, item]);

            //clear fields
            setProviderLevelRole('');
            setNoOfHours('');
            setNoOfMinutes('');
        }
    };

    const removeProviderRate = (index) => {
        setErrorMessage([]);
        const data = roleBaseRates.filter((item, i) => i !== index);
        setRoleBaseRates(data);
    };

    const resetData = () => {
        setErrorMessage([]);
        setProviderInvoiceNo("");
        setProviderLevelRole("");
        setNoOfHours("");
        setNoOfMinutes("");
        setRoleBaseRates([]);
        setIsDiscount(false);
        setDiscountPercentage("");
        setDiscountAmount("");
        setSelectedDiscountBasis("");
        setIsQbeShare(false);
        setQbeShareRate("");
        setIsDisbursement(false);
        setDisbursements([]);
        setTotalFee("0.00");
        setTotalInvoiceAmount("0.00");
        setProviderLevelOptions([]);
        setInvoiceRateDetails([]);
    };

    const resetTotalFees = () => {
        setErrorMessage([]);
        setTotalFee('0.00');
        setTotalInvoiceAmount('0.00');
    };

    const calculateTotalProviderLevelRates = () => {
        let totalAmount = 0;
        if (roleBaseRates && roleBaseRates.length > 0) {
            roleBaseRates.map((item, _idx) => (
                totalAmount += parseFloat(_removeDecimalFormatting(item.rateAmount))
            ));
        }
        return totalAmount;
    };

    const calculateTotalFee = () => {
        resetTotalFees();
        _validateForm(formId);
        
        let calculatedInvoiceFee = calculateTotalProviderLevelRates();

        if (disbursements && disbursements.length > 0) {
            let totalDisbursement = 0;
            disbursements.forEach((item) => {
                totalDisbursement += parseFloat(_removeDecimalFormatting(item.disbursementAmt ? item.disbursementAmt : "0"))
            });
            calculatedInvoiceFee += totalDisbursement;
        }


        if (isDiscount) {
            if (discountPercentage) {
                let discountRate = (100 - parseFloat(discountPercentage)) / 100;
                calculatedInvoiceFee = parseFloat((parseFloat(calculatedInvoiceFee) * discountRate));
            } else if (discountAmount) {
                calculatedInvoiceFee -= parseFloat(_removeDecimalFormatting(discountAmount));
            }
        }

        if (isQbeShare && qbeShareRate) {
            let rate = parseFloat(qbeShareRate)/100;
            calculatedInvoiceFee *= rate;
        }

        setTotalFee(_formatAmount(calculatedInvoiceFee, true));

        if (props.country !== 'HKG' && gst) {
            calculatedInvoiceFee += parseFloat(_removeDecimalFormatting(gst));
        }

        setTotalInvoiceAmount(_formatAmount(calculatedInvoiceFee, true));
    };

    const submit = () => {
        if (_validateForm(formId) && _validateForm("uploadDocsForm") && errorMessage.length === 0) {
            if (roleBaseRates.length === 0) {
                setErrorMessage("Please add staff level.");
                return;
            }

            let invoiceDetailsForm = {
                providerInvoiceNo: providerInvoiceNo,
                providerLevelRates: unformatProviderLevelRates(),
                discountPercentage: discountPercentage,
                discountAmount: _formatAmount(discountAmount, false),
                qbeShareRate: qbeShareRate,
                disbursements: unformatDisbursementAmount(),
                totalFee: _formatAmount(totalFee, false),
                gst: _formatAmount(gst, false),
                totalInvoiceAmount: _formatAmount(totalInvoiceAmount, false),
                type: cfg('invoice.calculations.byProvider'),
                documentForm: {
                    identifier: identifier,
                    documents: attachments
                }
            };

            setShowSubmitDialog(true);
            setInvoiceForm(invoiceDetailsForm);
        }
    };

    const unformatDisbursementAmount = () => {
        const array = [...disbursements];
        let unformattedArr = array.map(elem => (
            elem.disbursementAmt ? {...elem, disbursementAmt: _formatAmount(elem.disbursementAmt, false)} : elem
        ))

        return unformattedArr;
    };

    const unformatProviderLevelRates = () => {
        const array = [...roleBaseRates];
        let unformattedArr = array.map(elem => (
            elem.rateAmount ? {...elem, rateAmount: _formatAmount(elem.rateAmount, false)} : elem
        ))

        return unformattedArr;
    };

    return (
        <>
        <Container>
            <div className={classes.box}>
                <TopWrapper>
                    <Hidden mdDown>
                        <BackWrapper onClick={() => history.push("/viewInvoice")}>
                            <BackIcon/>{cfg('backBtn.label')}
                        </BackWrapper>
                        <Title>{cfg('invoice.createInvoice')}</Title>
                    </Hidden>
                    
                </TopWrapper>
            </div>
            <div style={{overflowY: "auto !important"}}>
                <div className={classes.rectangle}>
                    <div style={{backgroundColor: '#F7F8F9'}}>
                        <ProviderIcon className={classes.calculationMethodIcon}/>
                        <div className={classes.calculationMethodTitle}><h2>{cfg('invoice.calculations.byProvider')}</h2></div>
                    </div>
                    <div className={classes.subLabel}>{cfg('claimDetails.claimNumber')}: {props.claim?.claimNumber}</div>
                    <div className={classes.subLabel1}>{cfg('invoice.plsInputDetails')}</div>

                    <div  className={classes.Mform} ref={ref}>
                        <div className={classes.member_input}>
                            <FieldWrapper>
                                <QnectTextField
                                    field="providerInvoiceNo"
                                    value={providerInvoiceNo}
                                    onChange={event => {
                                        setErrorMessage([]);
                                        setProviderInvoiceNo(event.target.value);
                                    }}
                                    formId={formId}
                                    maxLength={80}
                                />
                            </FieldWrapper>

                            <FieldWrapper>
                                <QnectSelectField
                                    formId={ratesFormId}
                                    field="providerLevel"
                                    value={providerLevelRole}
                                    options={providerLevelOptions}
                                    onChange={val => {
                                        setErrorMessage([]);
                                        setProviderLevelRole(val);
                                    }}
                                />
                            </FieldWrapper>

                            <FieldWrapper>
                                <Grid direction='row' container spacing={1}>
                                    <Grid container item sm={6}>
                                        <QnectTextField
                                            formId={ratesFormId}
                                            field="noOfHours"
                                            value={noOfHours}
                                            onChange={event => {
                                                setErrorMessage([]);
                                                setNoOfHours(event.target.value);
                                            }}
                                            onInput = {(e) =>{
                                                e.target.value = _formatNumber(e.target.value);
                                            }}
                                            maxLength={2}
                                        />
                                    </Grid>
                                    <Grid container item sm={6}>
                                        <QnectTextField
                                            formId={ratesFormId}
                                            field="noOfMinutes"
                                            value={noOfMinutes}
                                            onChange={event => {
                                                setErrorMessage([]);
                                                setNoOfMinutes(event.target.value);
                                            }}
                                            onInput = {(e) =>{
                                                e.target.value = _formatNumber(e.target.value);
                                            }}
                                            maxLength={2}
                                        />
                                    </Grid>
                                </Grid>
                            </FieldWrapper>

                            <FieldWrapper>
                                <RoundButton color="secondary" onClick={addProviderRate}>
                                    <div className={classes.whiteButtonStyle}>
                                        <div style={{marginRight: "11px"}}>{cfg('invoice.add')}</div>
                                        <AddFile/></div>
                                </RoundButton>
                            </FieldWrapper>

                            {roleBaseRates.length > 0 && <div className={classes.subLabel} style={{marginTop: '30px'}}>{cfg('invoice.providerAdded')}</div>}
                            {roleBaseRates && roleBaseRates.map((item, index) => (
                                <div style={{margin: "10px 20px 0px 10px"}}>
                                    <ItemStyle key={item.providerLevel + index}>
                                        {item.providerLevel}
                                        <ItemControl>
                                            <Cross style={{cursor:"pointer"}} onClick={() => removeProviderRate(index)}/>
                                        </ItemControl>
                                        <div style={{width: '100%'}}>
                                            <DivItemStyle style={{float: 'left'}}>{item.noOfHours} hour {item.noOfMinutes} minute</DivItemStyle>
                                            <DivItemStyle style={{float: 'right'}}>{currency} {item.rateAmount}</DivItemStyle>
                                        </div>
                                    </ItemStyle>
                                </div>
                                )
                            )}
                            

                            <FieldWrapper>
                                <QnectCheckBox
                                    formId={formId}
                                    field="discountCheckbox"
                                    checked={isDiscount}
                                    disabled={false}
                                    onChange={(event) => changeDiscount(event)}
                                />
                                {isDiscount &&
                                <>
                                    <FieldWrapper>
                                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group" value={selectedDiscountBasis} onChange={(event) => changeDiscountBasis(event.target.value)}>
                                            <FormControlLabel value="discountPercentageRadio" control={<Radio />} label="Discount %" />
                                            <FormControlLabel value="discountAmountRadio" control={<Radio />} label="Discount Amount" />
                                        </RadioGroup>
                                    </FieldWrapper>
                                    {selectedDiscountBasis === 'discountPercentageRadio' && 
                                        <FieldWrapper>
                                            <QnectTextField
                                                field="discountPercentage"
                                                value={discountPercentage}
                                                onChange={(event) => {
                                                    setErrorMessage([]);
                                                    validateDiscountLimit(event.target.value);
                                                }}
                                                formId={formId}
                                                onInput = {(e) =>{
                                                    e.target.value = _formatDecimalAmount(e.target.value);
                                                }}
                                                endAdornment={'%'}
                                                maxLength={6}
                                            />
                                        </FieldWrapper>
                                    }
                                    {selectedDiscountBasis === 'discountAmountRadio' &&
                                        <FieldWrapper>
                                            <QnectTextField
                                                field="discountAmount"
                                                value={discountAmount}
                                                onChange={(event) => {
                                                    setErrorMessage([]);
                                                    setDiscountAmount(event.target.value);
                                                }}
                                                formId={formId}
                                                onInput = {(e) =>{
                                                    e.target.value = _formatDecimalAmount(e.target.value);
                                                }}
                                                endAdornment={currency}
                                                maxLength={18}
                                            />
                                        </FieldWrapper>
                                    }
                                </>}
                            </FieldWrapper>

                            <FieldWrapper>
                                <Grid direction='row' container spacing={1}>
                                    <Grid container item sm={9}>
                                        <QnectCheckBox
                                            formId={formId}
                                            field="disbursement"
                                            checked={isDisbursement}
                                            disabled={false}
                                            onChange={(event) => changeDisbursement(event)}
                                        />
                                        <div className={classes.disbursementTooltip}>
                                            <BalloonTip>{cfg('invoice.disbursementTooltip')}</BalloonTip>
                                        </div>
                                    </Grid>
                                    {isDisbursement && <Grid container item sm={3}>
                                        <Button variant="text" endIcon={<AddCircleIcon/>} onClick={handleAddRow}>Add</Button>
                                    </Grid>}
                                </Grid>
  
                                {isDisbursement && disbursements && disbursements.map((item, idx) => (
                                    <FieldWrapper key={idx}>
                                        <Grid direction='row' container spacing={1}>
                                            <Grid container item sm={6}>
                                                <QnectTextField
                                                    formId={formId}
                                                    field="disbursementDesc"
                                                    onBlur={event => handleUpdateDisbursementFields(idx, 'disbursementDesc', event.target.value)}
                                                    value={item.disbursementDesc}
                                                    maxLength={80}
                                                />
                                            </Grid>
                                            <Grid container item sm={4}>
                                                <QnectTextField
                                                    formId={formId}
                                                    field="disbursementAmt"
                                                    onBlur={event => handleUpdateDisbursementFields(idx, 'disbursementAmt', event.target.value)}
                                                    value={item.disbursementAmt}
                                                    onInput = {(e) =>{
                                                        e.target.value = _formatDecimalAmount(e.target.value);
                                                    }}
                                                    label={currency}
                                                    maxLength={18}
                                                />
                                            </Grid>
                                            <Grid container item sm={2}>
                                                <IconButton color="primary" style={{cursor:"pointer", marginRight:11}} onClick={() => handleRemoveRow(idx)}>
                                                    <RemoveCircleIcon/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </FieldWrapper>
                                ))}
                            </FieldWrapper>

                            <FieldWrapper>
                                <QnectCheckBox
                                    formId={formId}
                                    field="qbeShare"
                                    checked={isQbeShare}
                                    disabled={false}
                                    onChange={(event) => changeQbeShare(event)}
                                />

                                {isQbeShare && 
                                    <FieldWrapper>
                                        <QnectTextField
                                            field="qbeShareRate"
                                            value={qbeShareRate}
                                            onChange={(event) => {
                                                setErrorMessage([]);
                                                validateQbeShareLimit(event.target.value);
                                            }}
                                            formId={formId}
                                            onInput = {(e) =>{
                                                e.target.value = _formatDecimalAmount(e.target.value);
                                            }}
                                            endAdornment={'%'}
                                            maxLength={6}
                                        />
                                    </FieldWrapper>
                                }
                            </FieldWrapper>

                            <FieldWrapper>
                                <QnectButton
                                    field="calculate"
                                    onClick={calculateTotalFee}
                                    disabled={roleBaseRates.length === 0}
                                />
                            </FieldWrapper>

                            <FieldWrapper>
                                <div className={classes.totalFeeRectangle}>
                                    <div style={{float: 'left'}}>
                                        <div className={classes.subLabel} style={{paddingLeft: '10px'}}>{cfg('invoice.totalFee')}</div>
                                    </div>
                                    <div style={{float: 'right'}}>
                                        <div className={classes.subLabel} style={{paddingRight: '10px'}}>{currency} {totalFee}</div>
                                    </div>
                                </div>
                            </FieldWrapper>

                            {props.country !== 'HKG' &&
                            <div>
                                <FieldWrapper>
                                    <QnectTextField
                                        field="gst"
                                        value={gst}
                                        onChange={event => {
                                            setErrorMessage([]);
                                            setGST(event.target.value);
                                        }}
                                        formId={formId}
                                        onInput = {(e) =>{
                                            e.target.value = _formatDecimalAmount(e.target.value);
                                        }}
                                        maxLength={18}
                                    />
                                </FieldWrapper>

                                <FieldWrapper>
                                    <div className={classes.totalFeeRectangle}>
                                        <div style={{float: 'left'}}>
                                            <div className={classes.subLabel} style={{paddingLeft: '10px'}}>{cfg('invoice.totalInvoiceAmount')}</div>
                                        </div>
                                        <div style={{float: 'right'}}>
                                            <div className={classes.subLabel} style={{paddingRight: '10px'}}>{currency} {totalInvoiceAmount}</div>
                                        </div>
                                    </div>
                                </FieldWrapper>
                           </div>
                            }
                        </div>
                        

                        {errorMessage && (
                            <div className={classes.errorMessage}>
                                <QnectError errorTitle={"Error"} errorMessage={errorMessage} />
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.document_section}>
                    <div className={classes.uploadDocumentTitle}>{cfg('invoice.uploadInvoice')}</div>
                    <div className={classes.Mform} ref={ref}>
                        <UploadDocument attachments={attachments} setAttachments={setAttachments}
                                        identifier={identifier} setIdentifier={setIdentifier}/>


                        <div className={classes.group_button}>
                            <FieldWrapper>
                                <div style={{float: 'left'}}>
                                    <QnectButton
                                        field="backBtn"
                                        onClick={() => history.push("/viewInvoice")}
                                    />
                                </div>
                                <div style={{float: 'right'}}>
                                    <QnectButton
                                        field="submit"
                                        onClick={() => {
                                            calculateTotalFee();
                                            setIsSubmit(true);
                                        }}
                                    />
                                </div>
                            </FieldWrapper>
                        </div>
                    </div>
                </div>
            </div>
            {showSubmitDialog &&
               <SubmitInvoiceDialog invoiceForm={invoiceForm} currency={currency}
                                 onSubmit={(onSubmit) => setShowSubmitDialog(onSubmit)} />
            }
        </Container> 
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        lang: _getLang(state),
        claim: _getClaim(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showModal: _showModal,
    hideModal: _hideModal,
    showError: _showError,
    getInvoiceRaterDetails: _getInvoiceRaterDetails,
    submitInvoice: _submitInvoice
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (ProviderCalculation));
