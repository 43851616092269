import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import AlertIcon from '../alerts/alert-icon';
import Heading from '../typography/heading';
import ActionModal from './action-modal';

const StyledActionModal = styled(ActionModal)`
    width: calc(100vw - 32px);
    max-width: 512px;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin-top: 40px;
`;

const DialogContent = styled.div`
    max-width: 100%;
    text-align: center;
`;

const DialogTitle = styled(Heading)`
    margin: 0;
    padding: 16px;
    text-align: center;
`;

export default function AlertModal({
    className,
    isOpen,
    isLoading,
    alertLevel = 'info',
    title,
    primaryAction,
    secondaryAction,
    tertiaryAction,
    onClose,
    onReleaseFocus,
    children,
}) {
    return (
        <StyledActionModal
            className={classnames('AlertModal', className)}
            {...{
                isOpen,
                isLoading,
                onClose,
                onReleaseFocus,
                primaryAction,
                secondaryAction,
                tertiaryAction,
            }}
        >
            <StyledContainer>
                <AlertIcon alertLevel={alertLevel} />

                <DialogTitle
                    size="big"
                    weight="medium"
                    color="tertiary"
                >
                    {title}
                </DialogTitle>

                <DialogContent>
                    {children}
                </DialogContent>
            </StyledContainer>
        </StyledActionModal>
    );
}

AlertModal.propTypes = {
    className: propTypes.string,
    isOpen: propTypes.bool,
    isLoading: propTypes.bool,
    alertLevel: propTypes.oneOf(['info', 'success', 'warning', 'error']),
    title: propTypes.string,
    primaryAction: propTypes.shape({
        label: propTypes.string.isRequired,
        href: propTypes.string,
        onClick: propTypes.func,
    }),
    secondaryAction: propTypes.shape({
        label: propTypes.string.isRequired,
        href: propTypes.string,
        onClick: propTypes.func,
    }),
    tertiaryAction: propTypes.shape({
        label: propTypes.string.isRequired,
        href: propTypes.string,
        onClick: propTypes.func,
    }),
    onClose: propTypes.func,
    onReleaseFocus: propTypes.func,
    children: propTypes.node,
};
