import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { ReactComponent as Back } from "../../../assets/login/back_sharp.svg";

export const Container = styled.div`
  padding-bottom: 30px;
  background-color: #FFFFFF;
`;

export const TopWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1110px;
  height: 100%;
  position: relative;
`;

export const BackWrapper = styled.div`
  margin-top: 40px;
  width: 87px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #1d4fbb;
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
`;

export const Title = styled.div`
  margin-left: 20px;
  margin-top: 10px;
  font-size: 32px;
  line-height: 48px;
  font-family: Stag;
  font-weight: 500;
  letter-spacing: 1px;
  color: #003da5;
`;

export const SubTitle = styled.div`
  color: '#000000',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 500,
  letterSpacing: 0,
  lineHeight: '24px',
  marginTop: '20px'
`;

export const BackIcon = styled(Back)`
  margin-right: 8px;
`;

export const DivItemStyle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #0A1F44;
    font-family: Inter-Regular;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    padding-bottom: 5px;
    flex-wrap: wrap;
`;

export const ItemStyle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    color: #0A1F44;
    font-family: Inter-Regular;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    
    padding-top: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid #C9CED6;
    flex-wrap: wrap;
`;
export const ItemControl = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const useCalculationStyles = makeStyles(theme => ({
  rectangle: {
    margin: '5vh auto',
    minHeight: '500px',
    width: '600px',
    borderRadius: '0 0 8px 8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '2px 2px 8px 0 rgba(20,40,75,0.2)',
    textAlign: 'center'
  },

  calculationMethodIcon: {
    fontSize: 40,
    color: '#003DA5', 
    marginTop: '30px'
  },

  calculationMethodTitle: {
    width: '100%',
    paddingBottom: '3px',
    marginTop: '-20px',
    '& h2' : {
      fontFamily: 'Stag, sans-serif',
      color: '#003DA5',
      fontSize: '28px',
      letterSpacing: '1px',
      fontWeight: '400'
    }
  },

  subLabel: {
    color: '#0A1F44',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: '20px'
  },

  subLabel1: {
    color: '#00205B',
    fontFamily: 'Inter',
    fontSize: '15px',
    letterSpacing: 0,
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: '10px'
  },

  member_input: {
    margin: "0 auto",
    width: "320px",
    maxWidth: "320px",
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
    paddingBottom: '40px',
    '& div' : {
      textAlign: 'center',
    },
    '& .MuiFormControl-root.fileDescription' : {
      marginTop: '40px',
    }
  },

  uploadDocumentTitle: {
      color: '#0A1F44',
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '16px',
      textAlign: 'center',
      paddingTop: '40px',
      paddingBottom: '10px'
    },

    document_section: {
        margin: '5vh auto',
        width: '600px',
        borderRadius: '0 0 8px 8px',
        backgroundColor: '#FFFFFF',
        boxShadow: '2px 2px 8px 0 rgba(20,40,75,0.2)',
        textAlign: 'center'
    },

    group_button: {
        margin: "0 auto",
        width: "auto",
        maxWidth: "230px",
        position: 'relative',
        display: 'flex',
        paddingBottom: "50px",
        flexDirection: 'column',
        '& div' : {
            textAlign: 'center',
        }
    },

  totalFeeRectangle: {
    height: '56px',
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#F0F5FA'
  },

  errorMessage: {
    marginBottom: "10px",
      "& .alert": {
        marginTop: "0px"
      }
  },

  whiteButtonStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  desktopTooltip: {
    position: 'absolute',
    top: '15px',
    left: 'calc(100% + 10px)',
    zIndex: 9,
    '& .imageDiv img' : {
      left: '-230px',
      top: '-15px',
    },
    '& .ToolTipDiv' : {
      top: '-22px',
      left: '-195px',
      '& .contentDiv' : {
        boxShadow: '4px 4px 12px rgba(20,40,75,0.1)',
        background: '#F1F2F4',
        color: '#0A1F44',
        minWidth: '210px',
        padding: '20px',
        '&::before' : {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          position: 'absolute',
          borderTop: '8px solid transparent',
          borderBottom: '8px solid transparent',
          borderRight: '8px solid #F1F2F4',
          left: '-8px',
          top: 'calc(50% - 10px)',
          zIndex: '9999',
        },
      },
    },
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.between("sm", "md")]: {},
    [theme.breakpoints.up("lg")]: {}
  },

  disbursementTooltip: {
    position: 'absolute',
    top: '15px',
    left: 'calc(100% + 10px)',
    zIndex: 5,
    '& .imageDiv img' : {
      left: '-190px',
      top: '-15px',
    },
    '& .ToolTipDiv' : {
      top: '-30px',
      left: '-160px',
      '& .contentDiv' : {
        boxShadow: '4px 4px 12px rgba(20,40,75,0.1)',
        background: '#F1F2F4',
        color: '#0A1F44',
        minWidth: '210px',
        padding: '20px',
        '&::before' : {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          position: 'absolute',
          borderTop: '8px solid transparent',
          borderBottom: '8px solid transparent',
          borderRight: '8px solid #F1F2F4',
          left: '-8px',
          top: 'calc(50% - 10px)',
          zIndex: '9999',
        },
      },
    },
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.between("sm", "md")]: {},
    [theme.breakpoints.up("lg")]: {}
  }

}));

export default useCalculationStyles;