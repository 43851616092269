import React, {useEffect, useState} from "react";
import QnectButton from "../qnect-ui/qnect-button";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../custom/dialog-title";
import Dialog from '../custom/dialog';
import {useDialogStyles} from "../custom/dialog-style";
import useConfig from "../qnect-ui/qnect-config";
import EditIcon from "@material-ui/icons/Edit";
import FieldWrapper from "../custom/field-wrapper";
import QnectTextField from "../qnect-ui/qnect-text-field";
import {_validateForm} from "../../utils/utility";
import DeleteIcon from "@material-ui/icons/Delete";

export const UpdateDocumentDialog = ({open = false, onClose, onOk, rowData, action}) => {

    const classes = useDialogStyles();
    const cfg = useConfig();
    const formId = 'updateForm';
    const [description, setDescription] = useState('');

    useEffect(() => {
        setDescription(rowData?.description);
    },[rowData, open]);

    const onClickOk = () => {

        if (_validateForm(formId)) {
            let obj = {
                docID: rowData?.docID,
                dDocName: rowData?.dDocName,
                docFileName: rowData?.docFileName,
                description: description
            };
            onOk(obj);
        }
    };

    return (
        <div>
            <Dialog open={open} onClose={onClose} className={classes.modal_box_container}>
                {action === 'update' &&
                    <>
                     <DialogTitle className={classes.title}
                                  title={
                                      <div>
                                        <EditIcon fontSize='large'/><br/>
                                        {cfg("Edit Document")}
                                      </div>}
                                  closable={false}
                     />
                     <DialogContent>
                        <div className={classes.modal_box_form_container}>
                          <div className='modal_box_form_field input_name' style={{textAlign: 'center'}}>
                                <strong>{cfg("Document Name")}</strong><br/>
                                {rowData?.docFileName}
                          </div>
                          <div className='modal_box_form_field input_name'>
                             <FieldWrapper>
                                  <QnectTextField
                                           formId={formId}
                                           field="fileDescription"
                                           value={description}
                                           onChange={ val => {
                                              setDescription(val.target.value)
                                           }}
                                  />
                            </FieldWrapper>
                        </div>
                    </div>
                </DialogContent>
                </>
                }
                {action === 'delete' &&
                <>
                    <DialogTitle className={classes.title}
                                 title={
                                     <div>
                                         <DeleteIcon fontSize='large'/><br/>
                                         {cfg("Delete Document")}
                                     </div>}
                                 closable={false}
                    />
                    <DialogContent>
                        <div className={classes.modal_box_form_container}>
                            <div className='modal_box_form_field input_name' style={{textAlign: 'center'}}>
                                <span>Are you sure you want to delete</span><br/>
                                <strong>{rowData.docFileName}</strong>?
                            </div>
                        </div>
                    </DialogContent>
                </>
                }
                <DialogActions className={classes.actions}>
                    <QnectButton onClick={onClose} field="cancel" />
                    <QnectButton onClick={() => onClickOk()} field="confirm"/>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UpdateDocumentDialog;
