import React, {forwardRef, useEffect} from "react";
import {
    Add,
    ArrowDownward,
    Check, ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage, LastPage, Remove,
    SaveAlt, Search, ViewColumn
} from "@material-ui/icons";
import MaterialTable from "@material-table/core";
import {FileUpload} from "@mui/icons-material";
import Button from "../../../ui/components/buttons/button";

const MaterialUiTable = ({data, columns, title, onClickAdd, addText, localization, selection, onSelectionChange, isError}) => {

    const tableIcons = {
        Add: forwardRef((props, ref) =>
            <>
                <Button color="primary" disabled={isError}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div style={{marginRight: "11px"}}>{addText}</div>
                        <Add {...props} ref={ref}/>
                    </div>
                </Button>
            </>),
        Upload: forwardRef((props, ref) =>
            <>
                <Button color="primary" >
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div style={{marginRight: "11px"}}>{addText}</div>
                        <FileUpload {...props} ref={ref}/>
                    </div>
                </Button>
            </>),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
    };

    const options = {
        actionsColumnIndex: -1,
        pageSize: 10,
        actionsCellStyle: {
            fontFamily: 'Inter-SemiBold',
            fontSize: '12px',
            color: '#0A1F44',
            textTransform: 'capitalize'
        },
        headerStyle: {
            fontFamily: 'Inter-SemiBold',
            fontSize: '12px',
            color: '#0A1F44'
        },
        draggable: false,
        selection: selection,
        selectionProps: rowData => ({
            color: 'primary'
        }),
        showTextRowsSelected: false
    }

    const getActions = () => {
      if (onClickAdd !== undefined) {
          return [
              {
                  icon: addText?.indexOf('Upload') !== -1 ? tableIcons.Upload : tableIcons.Add,
                  isFreeAction: true,
                  disabled: isError,
                  onClick: () => onClickAdd()
              }
          ]
      }
      return null;
    };

    return (
        <MaterialTable actions={getActions()} columns={columns} data={data} title={title} icons={tableIcons} options={options}
            localization={localization} onSelectionChange={onSelectionChange}/>
    );
};

export default MaterialUiTable;