import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validator from "validator";
import {v4 as uuid} from 'uuid';
import moment from "moment";
import {_removeFormatting} from "../../utils/utility";

let internalSupportedComponents = {
    TextField: {
        getValueFromChangeEvent: (args)=>{
            return args[0].target.value;
        },
        changeCallBackCaller: (callback, args)=>{
            callback(args[0]);
        },
        noRerender: false,
        errorPropName: "errorMessage"
    },
    TextAreaField: {
        getValueFromChangeEvent: (args)=>{
            return args[0];
        },
        changeCallBackCaller: (callback, args)=>{
            callback(args[0]);
        },
        errorPropName: "errorMessage",
        reRenderOption: "true"
    },
    SelectField: {
        getValueFromChangeEvent: (args)=>{
            return args[0];
        },
        changeCallBackCaller: (callback, args)=>{
            callback(args[0], args[1], args[2]);
        },
        errorPropName: "errorMessage",
        optionPropName: "options",
        reRenderOption: "true"
    },
    DateRangePicker: {
        getValueFromChangeEvent: (args)=>{
            return args[0];
        },
        changeCallBackCaller: (callback, args)=>{
            callback(args[0]);
        },
        errorPropName: "errorMessage"
    },
    DatePicker: {
        getValueFromChangeEvent: (args)=>{
            return args[1];
        },
        changeCallBackCaller: (callback, args)=>{
            callback(args, args[1]);
        }
    },
    DateField: {
        getValueFromChangeEvent: (args)=>{
            return args[0];
        },
        changeCallBackCaller: (callback, args)=>{
            callback(args[0]);
        },
        errorPropName: "errorMessage",
        reRenderOption: "true"
    },
    RadioBox: {
        getValueFromChangeEvent: (args)=>{
            return args[0].target.value;
        },
        changeCallBackCaller: (callback, args)=>{
            callback(args[0]);
        },
        optionPropName: "options",
        errorPropName: "errorMessage",
        reRenderOption: "true"
    },
    RadioCard: {
        getValueFromChangeEvent: (args)=>{
            return args[0].target.checked;
        },
        changeCallBackCaller: (callback, args)=>{
            callback(args[0]);
        },
        errorPropName: "errorMessage"
    },
    Select: {
        getValueFromChangeEvent: (args)=>{
            return args[0];
        },
        changeCallBackCaller: (callback, args)=>{
            callback(args[0]);
        },
        optionPropName: "options",
        errorPropName: "errorMessage"
    },
    LabelledCheckbox: {
        getValueFromChangeEvent: (args)=>{
            return args[0].target.checked;
        },
        changeCallBackCaller: (callback, args)=>{
            callback(args[0].target.checked);
        },
        errorPropName: "errorMessage",
        reRenderOption: "true"
    }
};

function validateComponent(v, val) {
    switch (v.type) {

        case 'required':
            if (val === null || val === undefined) {
                return false;
            }
            if (typeof val === 'boolean') {
                return val;
            }
            return val instanceof moment ? true : !validator.isEmpty(val);
        case 'email':
            return !val || validator.isEmail(val);
        case 'emaillist':
            if (!val) {
                return true;
            }
            else if (val.indexOf (";") !== -1) {
                for (const item of val.split(";")) {
                    let trimItem = item.trim();
                    if (trimItem && !validator.isEmail(trimItem)) {
                        return false;
                    }
                }
                return true;
            }
            else {
                return !val || validator.isEmail(val);
            }
        case 'currency':
            return !val || validator.isCurrency(val.replace(/,/g, ''));
        case 'currencyNoCent':
            return !val || validateCurrencyNoDigit(val.replace(/,/g, ''));
        case 'amount':
            return !val || validator.isCurrency(val.replace(/,/g, ''));
        case 'alpha':
            return !val || validator.isAlpha(val);
        case 'alphanumeric':
            return !val || validator.isAlphanumeric(val);
        case 'number':
            return !val || validator.isNumeric(val);
        case 'minimum':
            const minVal = _removeFormatting(val);
            return !minVal || validator.isNumeric(minVal) && minVal >= v.min_value;
        case 'maximum':
            const value = _removeFormatting(val);
            return !value || validator.isNumeric(_removeFormatting(value)) && value <= v.max_value;
        case 'creditcard':
            return !val || validator.isCreditCard(val);
        case 'min_length':
            return !val || val.length >= v.length;
        case 'length':
            return !val || val.length === v.length;
        case 'max_length':
            return !val || val.length <= v.length;
        case 'mindate':
            let minDate = moment(v.min_date, 'DD/MM/YYYY').subtract(1, 'days');
            return !val || minDate.isBefore( val instanceof moment ? val : moment(val, 'DD/MM/YYYY'))
        case 'maxdate':
            let maxDate = moment(v.max_date, 'DD/MM/YYYY').add(1, 'days');
            return !val || maxDate.isAfter( val instanceof moment ? val : moment(val, 'DD/MM/YYYY'))
        case 'match':
            return !val || validator.matches(val, v.pattern, v.patternScope ? v.patternScope : 'g');
        case 'nomatch':
            return !val || !validator.matches(val, v.pattern, v.patternScope ? v.patternScope : 'g');
        case 'expiryDate':
            return !val || validateDate(val);
        default:
            return true;
    }
}

const validateDate = (dateString) => {
    if (dateString.length === 5) {
        const dateArr = dateString.split('-');
        if (dateArr[0] > 12 || dateArr[0] < 1) {
            return false;
        }

        const currentYr = moment().format('YY').toString();
        if (dateArr[1] < currentYr){
            return false;
        } else if (dateArr[1] === currentYr){
            const currentMon = moment().format('MM').toString();
            if (dateArr[0] <= currentMon){
                return false;
            }
        }
    }
    return true;
}

const validateCurrencyNoDigit = (currencyString) => {
    if (currencyString) {
        const pattern = /^([0-9])+$/;
        if (!pattern.test(currencyString)) {
            return false;
        }
    }

    return true;
}


function getAllSupportedComponent(){
    return Object.assign({}, internalSupportedComponents);
}

let formIds = {};

let qnectFormValidator = {
    checkForm: (formName) => {
        let valid = true;
        let validityRes = {
            isValid: true
        };

        let allCompsInForm = formIds[formName];
        if (allCompsInForm === undefined) {
            valid = true;
        } else {
            allCompsInForm.forEach((v1) => {
                let v = v1.component;
                if (!v.props?.children.props?.disabled) {
                    if (v.isValid === false) {
                        valid = false;
                    }
                }
            });
        }
        validityRes.isValid = valid;
        return validityRes;
    },

    getAllCompsInForm: (formName) => {
        return formIds[formName];
    },

    getFirstComponentThatHasError : (formName) => {
        let allCompsInForm = formIds[formName];
        let component = null;

        if (allCompsInForm !== undefined) {
            component = allCompsInForm.find((v1) => {
                let v = v1.component;

                if(formName === 'quoteEPRForm') {
                    if(v.props.errorMessage) {
                        return true;
                    }
                }

                if(formName === 'quoteEBIForm') {
                    if(v.props.errorMessage) {
                        return true;
                    }
                }

                if (!v.props?.children.props?.disabled) {
                    if (v.isValid === false) {
                        return true;
                    }
                }

                return false
            });
        }

        return component;
    }
}

class QnectValidator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            childComponentToRender: null,
            unControlledChild: true,
            isValid: true,
            id: uuid(),
            ref : React.createRef()
        };
        this.typeOfCompnent = this.props.componentTag ? this.props.componentTag : (this.props.children.type.displayName ? this.props.children.type.displayName : this.props.children.type.name);
        this.testValidity = this.testValidity.bind(this);
    }
    get isValid() {
        return this.testValidity(this.currentChildValue).isValid;
    }

    get errorMessage() {
        return this.testValidity(this.currentChildValue).errorMessage;
    }

    get errorPropValue() {
        return this.testValidity(this.currentChildValue).errorPropValue;
    }

    get isModified() {
        return this.childModified;
    }

    componentWillReceiveProps(props){
        let freshRendered = false;
        if (this.state.unsupported !== true){
            if (this.state.unControlledChild === false){
                let isDerivedValueComing = false;
                let isDerivedErrorComing = false;

                if (!_.isEqual(this.currentChildValue, props.children.props[this.props.valueProp])
                    || !_.isEqual(this.originalVal, props.children.props[this.props.valueProp])){
                    isDerivedValueComing = true;
                }

                if (props.errorMessage !== this.state.childComponentToRender.props.errorMessage){
                    isDerivedErrorComing = true;
                }

                if (this.childModified === true || isDerivedValueComing || isDerivedErrorComing){
                    let sameValue = false;
                    if (this.currentChildValue && this.currentChildValue instanceof moment && props.children.props[this.props.valueProp] instanceof moment) {
                        let srcValue = moment(this.currentChildValue).format('DD/MM/YYYY');
                        let targetValue = moment(props.children.props[this.props.valueProp]).format('DD/MM/YYYY');
                        sameValue = _.isEqual(srcValue, targetValue);
                    }
                    else {
                        sameValue = _.isEqual(this.currentChildValue, props.children.props[this.props.valueProp]);
                    }

                    if (!sameValue) {
                        // console.log("value changed");
                        freshRendered = true;
                        if (getAllSupportedComponent()[this.typeOfCompnent].noRerender) {
                            this.baseProps[this.props.valueProp] = props.children.props[this.props.valueProp];
                            this.currentChildValue = props.children.props[this.props.valueProp];
                        }
                        else {
                            this.mountingSetup(getAllSupportedComponent()[this.typeOfCompnent].getValueFromChangeEvent, getAllSupportedComponent()[this.typeOfCompnent].changeCallBackCaller, false, props);
                        }
                        // this.testValidity(this.currentChildValue);
                    }
                    this.childModified = undefined;
                }

                if (!freshRendered) {
                    if(props.hasCustomValidation) {
                        if(this.props.errorMessage || props.errorMessage) {
                            freshRendered = true;
                            let validate = false;
                            if (this.baseProps?.errorMessage && !props.children.props.disabled) {
                                validate = true;
                            }

                            this.mountingSetup(getAllSupportedComponent()[this.typeOfCompnent].getValueFromChangeEvent, getAllSupportedComponent()[this.typeOfCompnent].changeCallBackCaller, false, props);

                            if (validate) {
                                this.testValidity(this.currentChildValue, props);
                            }



                        }
                    }

                    if(props.forceRefresh) {
                        this.mountingSetup(getAllSupportedComponent()[this.typeOfCompnent].getValueFromChangeEvent, getAllSupportedComponent()[this.typeOfCompnent].changeCallBackCaller, false, props);
                    }

                    if (props.children?.props?.label !== undefined && !_.isEqual(props.children?.props?.label, this.baseProps?.label)
                        || (props.children?.props?.disabled !== undefined && !_.isEqual(props.children?.props?.disabled, this.baseProps?.disabled))
                        || (props.children?.props?.placeholder !== undefined && !_.isEqual(props.children?.props?.placeholder, this.baseProps?.placeholder))) {
                        console.log("attribute changed");
                        freshRendered = true;
                        let validate = false;
                        if (this.baseProps?.errorMessage && !props.children.props.disabled) {
                            validate = true;
                        }
                        if (validate) {
                            this.testValidity(this.currentChildValue, props);
                        }
                        else {
                            this.mountingSetup(getAllSupportedComponent()[this.typeOfCompnent].getValueFromChangeEvent, getAllSupportedComponent()[this.typeOfCompnent].changeCallBackCaller, false, props);
                        }
                    } else if (props.children?.props?.options !== undefined && !_.isEqual(props.children?.props?.options, this.baseProps?.options)) {
                        console.log("option changed");
                        freshRendered = true;
                        let validate = false;
                        if (this.baseProps?.errorMessage) {
                            validate = true;
                        }
                        if (validate) {
                            this.testValidity(this.currentChildValue, props);
                        }
                        else {
                            this.mountingSetup(getAllSupportedComponent()[this.typeOfCompnent].getValueFromChangeEvent, getAllSupportedComponent()[this.typeOfCompnent].changeCallBackCaller, false, props);
                        }
                    } else if (getAllSupportedComponent()[this.typeOfCompnent].reRenderOption) {
                        freshRendered = true;
                        let validate = false;
                        if (this.baseProps?.errorMessage) {
                            validate = true;
                        }
                        if (validate) {
                            const res = this.testValidity(this.currentChildValue, props);

                            if(res.isValid) {
                                this.mountingSetup(getAllSupportedComponent()[this.typeOfCompnent].getValueFromChangeEvent, getAllSupportedComponent()[this.typeOfCompnent].changeCallBackCaller, false, props);
                            }
                        }
                        else {
                            this.mountingSetup(getAllSupportedComponent()[this.typeOfCompnent].getValueFromChangeEvent, getAllSupportedComponent()[this.typeOfCompnent].changeCallBackCaller, false, props);
                        }
                    }
                }
            }
        }
        if (Object.keys(this.closureValues).length > 0 && freshRendered === false){
            //match closures
            let requireRender = false;
            _.forOwn(this.closureValues, (cVariableValue, cVariable)=>{
                if (!(_.isEqual(cVariableValue, props.closures[cVariable]))){
                    requireRender = true;
                }
            });
            if (requireRender){
                this.mountingSetup(getAllSupportedComponent()[this.typeOfCompnent].getValueFromChangeEvent, getAllSupportedComponent()[this.typeOfCompnent].changeCallBackCaller, false, props);
                //also test validity if closure changes -- added if any validation dependes on closure values
                this.testValidity(this.currentChildValue);
            }
        }
    }

    componentDidMount(){
        if (internalSupportedComponents[this.typeOfCompnent] !== undefined){
            this.mountingSetup(internalSupportedComponents[this.typeOfCompnent].getValueFromChangeEvent, internalSupportedComponents[this.typeOfCompnent].changeCallBackCaller);
        } else {
            console.error("Field-QnectValidator", `${this.typeOfCompnent} is currently not supported by field-validator`);
            this.mountingSetup(null, null, true);
        }

        if (this.props.formId && this.state.unsupported !== true){
            if (formIds[this.props.formId] === undefined){
                formIds[this.props.formId] = [];
            }
            formIds[this.props.formId].push({
                id: this.state.id,
                component: this,
                ref : this.state.ref
            });
        }
    }

    mountingSetup(valueFromArgs, argsToPassToActualHandler, unsupportedFlag, nextProps){
        let toUseProps = nextProps ? nextProps : this.props;
        if (unsupportedFlag === true){
            this.setState({
                childComponentToRender: toUseProps.children,
                unsupported: unsupportedFlag
            });
        } else {
            this.closureValues = {};
            if (Object.keys(toUseProps.closures).length > 0) {
                _.forOwn(toUseProps.closures, (cVariableValue, cVariable) => {
                    this.closureValues[cVariable] = cVariableValue;
                });
            }
            this.baseProps = _.clone(toUseProps.children.props);
            let isUncontrolled = true;
            if (this.baseProps.hasOwnProperty(toUseProps.valueProp)){
                isUncontrolled = false;
                if (nextProps !== true){
                    this.originalVal = this.baseProps[toUseProps.valueProp];
                }
                this.currentChildValue = this.baseProps[toUseProps.valueProp];
                this.childModified = undefined;
            } else {
                //try with default prop
                if (this.baseProps.hasOwnProperty(toUseProps.defaultValueProp)){
                    if (nextProps !== true){
                        this.originalVal = this.baseProps[toUseProps.defaultValueProp];
                    }
                    this.currentChildValue = this.baseProps[toUseProps.defaultValueProp];
                    this.childModified = undefined;
                }
            }

            if (toUseProps.onChangeCallback) {
                let oldOnChange = this.baseProps[toUseProps.onChangeCallback];
                this.baseProps[toUseProps.onChangeCallback] = (...args) => {
                    let rArgs = valueFromArgs(args);
                    // console.log("onChangeCallback triggered value = "+rArgs);
                    this.childModified = true;
                    if (!this.absorbing) {
                        this.absorbing = true;
                        try {
                            this.baseProps[toUseProps.valueProp] = rArgs;
                            this.currentChildValue = rArgs;
                            if (!toUseProps.onBlurCallback) {
                                this.testValidity(rArgs);
                            } else {
                                this.baseProps[getAllSupportedComponent()[this.typeOfCompnent].errorPropName] = null;
                                this.setState({
                                    childComponentToRender: React.cloneElement(this.props.children, this.baseProps),
                                    isValid: true,
                                    errorMessage: null
                                });
                            }

                            if (oldOnChange) {
                                argsToPassToActualHandler(oldOnChange, args);
                            }
                        } catch (er) {
                            this.absorbing = false;
                        }
                        this.absorbing = false;
                    }
                };
            }

            if (toUseProps.onBlurCallback) {
                let oldOnBlur = this.baseProps[toUseProps.onBlurCallback];
                this.baseProps[toUseProps.onBlurCallback] = (...args) => {
                    let rArgs = valueFromArgs(args);
                    // console.log("onBlurCallback triggered value = "+rArgs);
                    this.childModified = true;
                    if (!this.absorbing) {
                        this.absorbing = true;
                        try {
                            this.baseProps[toUseProps.valueProp] = rArgs;
                            this.currentChildValue = rArgs;
                            this.testValidity(rArgs);
                            if (oldOnBlur) {
                                argsToPassToActualHandler(oldOnBlur, args);
                            }
                        } catch (er) {
                            this.absorbing = false;
                        }
                        this.absorbing = false;
                    }
                };
            }
            let theComponent = React.cloneElement(toUseProps.children, this.baseProps);
            this.setState({
                childComponentToRender: theComponent,
                unControlledChild: isUncontrolled
            });
        }
    }

    testValidity(val, extProps){
        let res = {
            isValid: true,
            errorMessage: null,
            errorPropValue: null
        };
        try {
            this.props.validators && this.props.validators.every((v)=>{
                if (validateComponent(v, val) === false){
                    res.isValid = false;
                    res.error = !!v.errorMessage;
                    if (extProps) {
                        if (extProps.lang && extProps.lang !== 'en') {
                            res.errorMessage = v['errorMessage_'+extProps.lang];
                        }
                        else {
                            res.errorMessage = v['errorMessage'];
                        }
                    }
                    else if (this.props.lang && this.props.lang !== 'en') {
                        res.errorMessage = v['errorMessage_'+this.props.lang];
                    }
                    else {
                        res.errorMessage = v.errorMessage;
                    }
                    res.errorPropValue = v.errorPropValue ? v.errorPropValue : v.errorMessage;
                    return false;
                } else {
                    return true;
                }
            });
        } catch (err) {
            console.error(err);
        }
        if (res.isValid === false){
            if (getAllSupportedComponent()[this.typeOfCompnent].errorPropName){
                this.baseProps[getAllSupportedComponent()[this.typeOfCompnent].errorPropName] = res.errorMessage;
            }
            this.setState({
                childComponentToRender: React.cloneElement(this.props.children, this.baseProps),
                isValid: false,
                errorMessage: res.errorMessage
            });
        } else {
            // if (getAllSupportedComponent()[this.typeOfCompnent].errorPropName){
            //     this.baseProps[getAllSupportedComponent()[this.typeOfCompnent].errorPropName] = null;
            // }
            // this.setState({
            //     childComponentToRender: React.cloneElement(this.props.children, this.baseProps),
            //     isValid: true,
            //     errorMessage: null
            // });
        }
        return res;
    }

    componentWillUnmount(){
        if (this.props.formId){
            _.remove(formIds[this.props.formId], (v)=>{
                return v.id === this.state.id;
            });
        }
    }

    render() {
        if (this.state.unsupported === true){
            return this.props.children;
        } else {
            return (
                <span ref={this.state.ref}>
              {
                  this.state.childComponentToRender ? this.state.childComponentToRender : ""
              }
                    {
                        getAllSupportedComponent()[this.typeOfCompnent]
                        && !getAllSupportedComponent()[this.typeOfCompnent].errorPropName && this.state.isValid === false ?
                            <div style={Object.assign({}, {color: "#D43900", fontSize: "14px", lineHeight: "20px", margin: "0px 0px 10px 0px"}, this.props.errorStyle)}>
                                {
                                    this.state.errorMessage
                                }
                            </div> : ""
                    }
        </span>
            );
        }
    }
}

QnectValidator.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element
    ]),
    validators: PropTypes.array,
    onChangeCallback: PropTypes.string,
    onBlurCallback: PropTypes.string,
    formId: PropTypes.string,
    lang: PropTypes.string,
    valueProp: PropTypes.string,
    defaultValueProp: PropTypes.string,
    errorStyle: PropTypes.object,
    closures: PropTypes.object,
    componentTag: PropTypes.string,
    errorMessage: PropTypes.string,
    handleErrorMessage: PropTypes.func
};

QnectValidator.defaultProps = {
    valueProp: "value",
    defaultValueProp: "defaultValue",
    errorStyle: {},
    closures: {}
};

export {QnectValidator, qnectFormValidator};
