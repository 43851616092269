import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import SelectCard from './common/select-card';
import SelectCardLabel from './common/select-card-label';
import RadioButton from './radio-button';

const StyledRadioButton = styled(RadioButton)`
    align-self: start;
    margin-top: 2px;
`;

const Label = styled.label`
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin: 0;
    cursor: inherit;
    padding: 10px;
    text-align: center;
`;

const RadioCard = React.forwardRef((
    {
        className,
        disabled,
        label,
        name,
        value,
        checked, // must be provided externally for the card to be styled correctly
        onChange,
        image,
        ...rest
    },
    ref
) => (
    // todo: per row - image, label text , radio button
    <SelectCard
        isDisabled={disabled}
        isSelected={checked}
        className={classnames('RadioCard', className)}
    >
        <Label>
            <SelectCardLabel>
                <div><img src={image} alt=""/></div>
                <div>{label}</div>
                <div>
                    <StyledRadioButton
                        {...rest}
                        {...{ref, disabled, name, value, checked, onChange}}
                    />
                </div>
            </SelectCardLabel>
        </Label>
    </SelectCard>
));


RadioCard.propTypes = {
    className: propTypes.string,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    label: propTypes.node,
    name: propTypes.string,
    value: propTypes.oneOfType([propTypes.string, propTypes.bool]),
    checked: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    onChange: propTypes.func,
};

RadioCard.displayName = 'RadioCard';

export default RadioCard;
