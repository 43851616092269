import propTypes from 'prop-types';
import React from 'react';

import {QnectValidator} from "./qnect-validator";
import SelectField from "../../ui/components/form-fields/select-field";
import useConfig from "./qnect-config";

const QnectSelectField = React.forwardRef((
    {
        field,
        formId,
        label,
        value,
        options,
        placeHolder,
        errorMessage,
        helperText,
        disabled,
        onChange,
        onBlur,
        ...rest
    },
    ref
) => {
    const cfg = useConfig();
    return (
        <QnectValidator
            onChangeCallback="onChange"
            formId={formId}
            field={field}
            errorMessage={errorMessage}
            validators={cfg(field, "validators")}
        >
            <SelectField
                label={label || cfg(field, "label")}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                helperText={helperText}
                errorMessage={cfg(errorMessage)}
                disabled={disabled}
                placeholder={placeHolder}
                options={options ? options : cfg(field, "options")}
                field={field}
            >
            </SelectField>
        </QnectValidator>
    )
});

QnectSelectField.propTypes = {
    field: propTypes.string,
    formId: propTypes.string,
    label: propTypes.string,
    value: propTypes.string,
    className: propTypes.string,
    helperText: propTypes.string,
    errorMessage: propTypes.string,
    onChange: propTypes.func,
    options: propTypes.arrayOf(propTypes.shape({
        value: propTypes.string,
        text: propTypes.string,
    })),
    disabled: propTypes.bool,
    onBlur: propTypes.func,
};

QnectSelectField.displayName = 'QnectSelectField';

export default QnectSelectField;
