import React from 'react';
import {connect} from 'react-redux';

import {
    _hideModal, _getModal, _getShowModal
} from '../store/system/service';

import Actions from "../ui/components/buttons/actions";
import Modal from "../ui/components/modals/modal";
import './modal-popup.css';
import styled from 'styled-components';
const StyleIcon = styled.div`
        width: 48px;
        height: 48px;
        background: #ffffff;
    `;


function ModalPopup({
    showModal,
    hideModal,
    modal
}) {
    let custom  = {};
    let {flat, size} = modal
    let Icon = null
    if(modal.icon!=null){
        Icon = modal.icon
    }

    return (
        <Modal
            bgColor={flat!=null?flat:'#fff'}
            className={"ModalBody "+modal?.className}
            onClose={modal?.onClose ? modal?.onClose : hideModal}
            isOpen={showModal}
            size={size}
            disableBackDropClick={modal.disableBackDropClick}
        >
            {
                flat!=null?<>
                    {modal?.content &&
                    <div className="ModalContent" style={custom}>
                        {modal?.content}
                    </div>}
                    </>
                    :<>
                    <div className="ModalTopBar">
                        {modal.icon!=null?<Icon className={"ModalIcon"}/>:<StyleIcon/>}
                        <div className="ModalTitle">
                            {modal?.title}
                        </div>
                    </div>
                    {modal?.content &&
                    <div className={[modal?.noPadding!=null?"ModalContentNoPadding":"ModalContent"]} style={custom}>
                        {modal?.content}
                    </div>
                    }
                        {   modal?.primaryAction &&
                        <Actions className="StyledModalActions"
                                 primaryAction={modal?.primaryAction}
                                 secondaryAction={modal?.secondaryAction}
                                 tertiaryAction={modal?.tertiaryAction}
                        />
                        }
                    </>
            }
        </Modal>
    );
}

function mapStateToProps(state) {
    return {
        showModal: _getShowModal(state),
        modal: _getModal(state),
    };
}

const mapDispatchToProps = {
    hideModal: _hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPopup);
