import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  secondaryTitle: props => ({
    margin: "0 auto",
    fontFamily: "Inter Semi-bold, Inter-Semi-bold, Inter Semibold, Inter Semi bold, Inter, sans-serif",
    fontWeight: props.lang === "zh" ? "bold" : "500",
    color: "#00205B",
    textAlign: "center",
    width: "auto",
    marginBottom: '40px',
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      lineHeight: "24px"
    }
  }),
}));

function DialogSecondaryTitle({ secondaryTitle, title, lang = 'en' }) {
  const classes = useStyles({lang});

  return (
      <div className={classes.secondaryTitle}>{title}</div>
  );
}

DialogSecondaryTitle.propTypes = {
  title: PropTypes.node,
  closable: PropTypes.bool,
  handleClose: PropTypes.func
};

export default memo(DialogSecondaryTitle);
