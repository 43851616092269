import {property} from "lodash";
import * as type from "./types";
import axios from "axios";
import {_getCpToken} from "../system/service";
import {_sendLog} from "../../utils/utility";

const authCpHeader = (state) => {
    const cpToken = _getCpToken(state);
    if (cpToken) {
        return { 'Authorization': 'Bearer ' + cpToken };
    } else {
        return {};
    }
}

export const _storeUserList = (userList) => {
    return (dispatch) => {
      dispatch({
        type: type.SET_USER_LIST,
        data: userList
      })
    };
  }

export const _storeProviderList = (providerList) => {
    return (dispatch) => {
      dispatch({
        type: type.SET_PROVIDER_LIST,
        data: providerList
      })
    };
}

export const _resetUserList = (userList) => {
    return (dispatch) => {
      dispatch({
        type: type.RESET_USER_LIST
      })
    };
}

export const _resetProviderList = (providerList) => {
    return (dispatch) => {
      dispatch({
        type: type.RESET_PROVIDER_LIST
      })
    };
}

export const _getUserList = (email, userType, showPortalUsers, entity) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const config = {
                params: {
                    email: email,
                    userType: userType,
                    showPortalUsers: showPortalUsers,
                    entity: entity
                },
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.get(process.env.REACT_APP_GET_USER_LIST, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - Admin Service - _getUserList', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _getProviderList = (country) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.get(process.env.REACT_APP_GET_PROVIDER_LIST, config)
                .then((response) => {
                    dispatch({
                        type: type.SET_PROVIDER_LIST,
                        data: response.data.providerList
                    })
                    resolve(response.data.providerList);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - Admin Service - _getProviderList', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _saveUser = (user) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 600000
            }
            let requestBody = {
                userDetails: user
            }

            axios.post(process.env.REACT_APP_SAVE_USER, requestBody, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - Admin Service - _saveUser', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}


export const _resendRegistrationEmail = (userId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 600000
            }
            let requestBody = {
                userId: userId
            }

            axios.post(process.env.REACT_APP_RESEND_REGISTRATION, requestBody, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - Admin Service - _resendRegistrationEmail', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _updateUserStatus = (userData) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 600000
            }
            let requestBody = {
                userId: userData.userId,
                status: userData.status
            }

            axios.post(process.env.REACT_APP_UPDATE_USER_STATUS, requestBody, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - Admin Service - _updateUserStatus', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}


export const _syncData = (country, type) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 600000
            }
            let requestBody = {
                country: country,
                type: type
            }

            axios.post(process.env.REACT_APP_SYNC_DATA, requestBody, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - Admin Service - _syncProviders', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _getAdmin = property ( 'admin' );
