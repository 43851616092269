import React, {useEffect, useState} from "react";
import QnectButton from "../qnect-ui/qnect-button";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../custom/dialog-title";
import DialogSecondaryTitle from "../custom/dialog-secondary-title";
import Dialog from '../custom/dialog';
import {useDialogStyles} from "../custom/dialog-style";
import useConfig from "../qnect-ui/qnect-config";
import UserDetails from "./user-details";

export const UpdateUserStatusDialog = ({open = false, onClose, onOk, isAdmin=false, providers, rowData, ...props}) => {

    const classes = useDialogStyles();
    const cfg = useConfig();
    const [userId, setUserId] = useState(null);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        setUserId(rowData?.userId);
        setStatus(rowData?.status);
    },[rowData, open]);

    const onClickOk = () => {
        let obj = {
            userId: userId,
            status : status === 'Active' ? 'D' : 'A'
        };
        onOk(obj);
    };

    return (
        <div>
            <Dialog open={open} onClose={onClose} className={classes.modal_box_container}>
                {status === 'Active' && (
                    <>
                        <DialogTitle className={classes.title}
                                     title={
                                         <div>
                                             {cfg("userDetailModals.deactivateUser.title")}
                                         </div>}
                                     closable={false} lang={props.lang}
                        />
                        <DialogSecondaryTitle title={cfg("userDetailModals.deactivateUser.subTitle")}/>
                    </>
                )}
                {status === 'Disabled' && (
                    <>
                        <DialogTitle className={classes.title}
                                     title={
                                         <div>
                                             {cfg("userDetailModals.activateUser.title")}
                                         </div>}
                                     onClose={onClose} lang={props.lang}
                        />
                        <DialogSecondaryTitle title={cfg("userDetailModals.activateUser.subTitle")}/>
                    </>
                )}
                <DialogContent>
                    <UserDetails rowData={rowData}
                                 isAdmin={isAdmin}
                                 country={props.country}
                                 cfg={cfg} />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <QnectButton onClick={onClose} field="cancel" />
                    <QnectButton onClick={() => onClickOk()} field="confirm"/>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UpdateUserStatusDialog;
