import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import {grid, gridCell} from '../../common/grid';
import Checkbox from './checkbox';

const StyledCheckBoxErrorDiv = styled.div`
    display: block;
    color: #a50000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    padding: 0px;
    margin: 10px 0px 10px 0px;
    text-align: left;
`;

const Label = styled.label`
    //margin-top: 32px;
    position: relative;
    ${grid({rows: 1, columnTemplate: ['auto', '1fr'], columnGap: '14px'})}
    & input[type=checkbox]{
        ${errorMessage=>errorMessage!==null&&errorMessage.errorMessage?'border: 1px solid #A50000;':''};
        
    }
`;

const StyledCheckbox = styled(Checkbox)`
    -ms-grid-row-align: center; /* stylelint-disable-line property-no-vendor-prefix */

    ${gridCell({row: 1, column: 1})}
`;

const LabelText = styled.span`
    display: block;
    text-align: left;

    ${gridCell({row: 1, column: 2})}

    ${StyledCheckbox}:disabled + & {
        color: ${property('theme.color.grey50')};
    }
`;

const LabelledCheckbox = React.forwardRef((
    {
        className,
        label,
        disabled,
        checked,
        onChange,
        errorMessage,
        children,
        ...rest
    },
    ref
) => (
    <React.Fragment>
        <Label errorMessage={errorMessage} className={classnames('LabelledCheckbox', className)}>
            <StyledCheckbox
                {...rest}
                ref={ref}
                {...{disabled, checked, onChange}}
            />
            <LabelText
                size="normal"
                weight="medium"
                color="primary"
                className={"LabelText"}
            >
                {label ? label : children}
            </LabelText>

        </Label>
        { errorMessage &&
        <StyledCheckBoxErrorDiv>
            {errorMessage}
        </StyledCheckBoxErrorDiv>
        }
    </React.Fragment>

));

LabelledCheckbox.propTypes = {
    className: propTypes.string,
    label: propTypes.string,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming,
    checked: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    onChange: propTypes.func,
    errorMessage: propTypes.string
};

LabelledCheckbox.displayName = 'LabelledCheckbox';

export default LabelledCheckbox;
