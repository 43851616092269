import React, {useEffect, useRef, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import FocusLock from 'react-focus-lock';
import noop from 'lodash/noop';

import {mdDownN, smDown, smUp} from '../../common/breakpoints';
import {cssIf, cssIfNot, cssIfElse} from '../../common/conditional-styles';
import usePortal from '../../common/use-portal';
import IconClose from '../icons/legacy/close';
import CircularProgress from '../progress/circular-progress';

const TRANSITION_DURATION_MS = 200;
const BORDER_RADIUS_PX = 20;

// z-index prioritises this modal over the fixed header and floating input labels.
const Backdrop = styled.div`
    box-sizing: border-box;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    transition: opacity ${TRANSITION_DURATION_MS}ms;
    z-index: 10; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    background: ${({theme}) => rgba(theme.color.backdrop, 0.8)};
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    ${cssIfNot('scIsOpen')`
        opacity: 0;
    `}

    ${smDown`
        ${cssIfNot('scIsFullWidthOnMobile')`
            padding: 16px;
        `}
    `}

    ${smUp`
        padding: 32px;
    `}
`;

const Container = styled.div`
    position: relative;
    margin: 0 auto;

    margin-top: ${({mt})=>mt}px;
    margin-bottom: 20px;
    max-width: 100%;

    ${smDown`
        width: 100%;
        max-width: 512px;
    `}

    ${cssIf('scIsFullWidthOnMobile')`
        ${smDown`
            margin: 0;
            min-height: 100%;
        `}
    `}

    &:focus {
        outline: 0;
    }

    > [data-focus-lock-disabled] {
        //height: 100%;
    }
`;

const Body = styled.div`
    box-sizing: border-box;
    position: relative;
    background: #FFFFFF;
    border-radius: 20px;
    //padding: 48px 24px;
    padding-bottom: 48px;
    overflow: hidden;
    //width: 550px;

    ${cssIfElse('scIsFullWidthOnMobile')`
        ${smDown`
            padding: 24px 24px 48px;
            width: 100%;
            min-height: 100%;
            height: auto;
        `}

        ${smUp`
            border-radius: ${BORDER_RADIUS_PX}px;
        `}
    ``
        // box-shadow: 0 0 15px -7px ${({theme}) => rgba(theme.color.black, 0.2)},
        //             0 0 38px 3px ${({theme}) => rgba(theme.color.black, 0.14)},
        //             0 0 46px 8px ${({theme}) => rgba(theme.color.black, 0.12)};
        border-radius: ${BORDER_RADIUS_PX}px;
        max-width: 100%;
    `}

    &:focus {
        outline: 0;
    }
`;

const LoadingOverlay = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    border-radius: ${BORDER_RADIUS_PX}px;
    background: ${({theme}) => rgba(theme.color.white, 0.8)};

    ${cssIfNot('scIsFullWidthOnMobile')`
        border-radius: ${BORDER_RADIUS_PX}px;
    `}
`;

const CloseButton = styled.button`
    appearance: none;
    position: absolute;
    top: 12px;
    right: 12px;
    border: 0;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    padding: 4px;

    ${cssIf('scIsFullWidthOnMobile')`
        ${smDown`
            position: fixed;
        `}
    `}

    &:hover,
    &:focus {
        background: #ffffff55;
    }

    &:focus {
        outline: 0;
    }
    ${mdDownN`
        top: 24px;
        right: 24px;
    `}
`;

// Generic modal
export default function Modal({
                                  className,
                                  isFullWidthOnMobile,
                                  isOpen,
                                  isLoading,
                                  onClose,
                                  onReleaseFocus = noop,
                                  children,
                                  size,
                                  bgColor,
                              }) {
    const [isRendered, setIsRendered] = useState(isOpen);
    const [isOpaque, setIsOpaque] = useState(isOpen);
    const containerRef = useRef(null);
    const bodyRef = useRef(null);
    const [mt, setMt] = useState(0)
    const receiveQnect = (event) => {
        if (event.data.topbar) {
            // window.parent.postMessage({msg:'react reply: hello'},'*')
            const {innerWidth, scrollY} = window;
            if (containerRef.current) {
                const popup = containerRef.current.querySelector('.ModalBody')
                containerRef.current.style.height = bodyRef.current.offsetHeight
                let calc = window.innerHeight / 2 - bodyRef.current.offsetHeight / 2 - event.data.topbar / 2 - event.data.footer / 2
                setMt( calc < 10 ? 20 : calc)
            }
        }
        // else if(offsetHeaderHeight===0||offsetFooterHeight===0){
        //     window.parent.postMessage({getOffset:true},'*')
        // }
    }
    useEffect(() => {
        window.addEventListener('message', receiveQnect, false)
        setTimeout(
            () => {
                window.parent.postMessage({getOffset: true}, '*')
            }
            , 500)

        return () => {
            window.removeEventListener('message', receiveQnect)
        }

    }, [])
    // Transition opacity when opening/closing
    useEffect(
        () => {
            if (isOpen) {
                if (isRendered) {
                    setIsOpaque(true);
                } else {
                    setIsRendered(true);
                }
            } else {
                setIsOpaque(false);

                const timeout = setTimeout(
                    () => {
                        setIsRendered(false);
                        // document.querySelector('body').style = ""
                    },
                    TRANSITION_DURATION_MS
                );

                return () => {
                    clearTimeout(timeout);
                };
            }
        },
        [!!isOpen]
    );

    // After rendering, make opaque.
    useEffect(
        () => {
            if (isRendered) {
                setIsOpaque(true);
            }
        },
        [!!isRendered]
    );

    // Prevent the body scrolling when the modal is open.
    // Hide the window's vertical scrollbar, if visible, and adjust the width of the body accordingly to prevent the
    // width of window contents changing.
    // Caveat: Fixed position elements are relative to the viewport and therefore their width will change. They should
    // use the useModalAwareness hook to inherit right padding from document.body.
    useEffect(
        () => {

            if (isRendered) {
                const {offsetWidth} = document.body;
                const {overflow, position, top, width, paddingRight} = document.body.style;
                const {innerWidth, scrollY} = window;
                const scrollbarWidth = innerWidth - offsetWidth;
                window.parent.postMessage({getOffset: true}, '*');

                Object.assign(
                    document.body.style,
                    {
                        // position: 'fixed',
                        top: `-${scrollY}px`,
                        overflow: 'hidden',
                        width: '100%',
                    },
                    scrollbarWidth && {
                        width: scrollbarWidth ? `calc(100% - ${scrollbarWidth}px)` : '100%',
                        paddingRight: `${scrollbarWidth}px`,
                    }
                );

                return () => {
                    Object.assign(
                        document.body.style,
                        {
                            position,
                            top,
                            width,
                            paddingRight,
                            overflow: 'auto'
                        }
                    );
                    window.scrollTo(0, scrollY);
                };
            }
        },
        [!!isRendered]
    );

    // Notify parents that the focus trap has been released.
    useEffect(
        () => {
            if (!isRendered) {
                onReleaseFocus();
            }
        },
        [!!isRendered]
    );

    return usePortal(
        isRendered && (
            <Backdrop
                className="Modal"
                scIsFullWidthOnMobile={isFullWidthOnMobile}
                scIsOpen={isOpaque}
                // onClick={(e)=>{
                //     if(onClose && e.target instanceof Node && !containerRef.current.contains(e.target)){
                //         onClose(e)
                //     }
                // }}
                onKeyDown={(e) => {
                    if (onClose && e.key === 'Escape') {
                        // Don't cancel things underneath
                        e.stopPropagation();
                        onClose(e);
                    }
                }}
            >
                <Container
                    ref={containerRef}
                    mt={mt}
                    reff={bodyRef}
                    className={"ModalContainerClass"}
                    scIsFullWidthOnMobile={isFullWidthOnMobile}
                >
                    <FocusLock returnFocus>
                        <Body
                            role="dialog"
                            size={size}
                            tabIndex={0}
                            ref={bodyRef}
                            className={className}
                            scIsFullWidthOnMobile={isFullWidthOnMobile}
                            id="popupContainerContent"
                        >
                            {children}
                            {!!onClose && (
                                <CloseButton className={"modalCloseButton"}
                                    scIsFullWidthOnMobile={isFullWidthOnMobile}
                                    onClick={(e) => {
                                        onClose(e)
                                        // window.removeEventListener('message',receiveQnect)

                                    }}
                                >
                                    <IconClose
                                        style={{fill: '#fff'}}
                                        size={24}
                                    />
                                </CloseButton>
                            )}
                        </Body>
                    </FocusLock>

                    {!!isLoading && (
                        <LoadingOverlay scIsFullWidthOnMobile={isFullWidthOnMobile}>
                            <CircularProgress/>
                        </LoadingOverlay>
                    )}
                </Container>
            </Backdrop>
        )
    );
}

Modal.propTypes = {
    className: propTypes.string,
    isFullWidthOnMobile: propTypes.bool,
    isOpen: propTypes.bool,
    isLoading: propTypes.bool,
    onClose: propTypes.func,
    onReleaseFocus: propTypes.func,
    children: propTypes.node,
};
