import {property} from "lodash";
import * as type from "./types";
import axios from "axios";
import {_getCountry, _getCpToken} from "../system/service";
import {_sendLog} from "../../utils/utility";

const authCpHeader = (state) => {
    const cpToken = _getCpToken(state);
    if (cpToken) {
        return { 'Authorization': 'Bearer ' + cpToken };
    } else {
        return {};
    }
}

export const _storeUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_USER,
      data: user
    })
  };
}

export const _getUserDetailById = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }

            axios.get(process.env.REACT_APP_GET_USER_DETAIL_BY_ID, config)
                .then((response) => {
                    dispatch({
                        type: type.SET_USER,
                        data: response.data.userDetails
                    })
                    resolve(response.data.userDetails);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - User Service - _getUserDetails', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _getUserDetailsFromToken = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState())
            }

            axios.get(process.env.REACT_APP_GET_USER_DETAIL_BY_TOKEN, config)
                .then((response) => {
                    dispatch({
                        type: type.SET_USER,
                        data: response.data
                    })
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - User Service - _getUserDetailsFromToken', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _addEmailAddressToOTPLockedCache = (email) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    email: email
                },
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_ADD_EMAIL_ADDRESS_TO_OTP_LOCKED_CACHE, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Corporate Portal React - User Service - _addEmailAddressToOTPLockedCache', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _checkEmailIfRegistered = (email, country) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const config = {
                params: {
                    email: email,
                    country: country
                },
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.get(process.env.REACT_APP_CHECK_EMAIL_IF_REGISTERED, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - User Service - _checkEmailIfRegistered', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _verifyUserCredentials = (email, password) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
                email: email.toLowerCase(),
                password: password,
                country: _getCountry(getState())
            };
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.post(process.env.REACT_APP_VERIFY_USER_CREDENTIALS, requestBody, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(['Supplier Portal - User Service - _verifyUserCredentials', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _verifyAdminUserCredentials = (adminEmail, password, email) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
                adminEmail: adminEmail.toLowerCase(),
                password: password,
                country: _getCountry(getState()),
                email: email.toLowerCase()
            };
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.post(process.env.REACT_APP_VERIFY_ADMIN_USER_CREDENTIALS, requestBody, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - User Service - _verifyAdminUserCredentials', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _resetUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: type.RESET_USER
    })
  };
}

export const _resendVerificationEmail = (email, country) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    email: email,
                    country: country
                },
                headers: authCpHeader(getState()),
                timeout: 60000
            }

            axios.get(process.env.REACT_APP_RESEND_VERIFICATION_EMAIL, config)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - User Service - _resendVerificationEmail', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}


export const _verifyEmail = (token, iv) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    token: token,
                    iv: iv
                },
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_VERIFY_EMAIL, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - User Service - _verifyEmail', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}


export const _resetPolicyList = () => {
  return (dispatch) => {
    dispatch({
      type: type.POLICY_LIST_RESET
    })
  };
}


export const _checkIfPolicyNumberIsRegistered = (policyNumber, hkId, name) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    policyNumber: policyNumber,
                    hkId: hkId,
                    name: name,
                    country: _getCountry(getState())
                },
                headers: authCpHeader(getState())
            }

            axios.get(process.env.REACT_APP_CHECK_IF_POLICY_NUMBER_IS_REGISTERED, config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    _sendLog(['Supplier Portal React - User Service - _checkIfPolicyNumberIsRegistered', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _getPortfolioPolicy = (policyNumber) => {
  return (dispatch, getState) => {
    let user = _getUser(getState());
    if (user.policyPortfolio && user.policyPortfolio.length > 0) {
      return user.policyPortfolio.find((item) => {
        return item.policyNumber === policyNumber;
      })
    }
    return '';
  }
}

export const _sendResetPasswordLink = (email) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let config = {
                params: {
                    email: email
                },
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_SEND_FORGET_PASSWORD_LINK, config)
                .then((response) => {
                    if (response.data === 'SUCCESS') {
                        resolve();
                    } else if (response.data === 'FAILED') {
                        reject();
                    }
                }).catch((error) => {
                    _sendLog(['Supplier Portal React - User Service - _sendResetPasswordLink', JSON.stringify(error)],
                        'error');
                    reject(error);
                }
            );
        })
    }
}

export const _resetPassword = (email, password, currentPassword) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let config = {
                headers: authCpHeader(getState()),
                timeout: 30000
            };

            let requestBody = {
                email: email,
                password: password
            };

            if (currentPassword !== null && currentPassword.trim() !== '') {
                requestBody.currentPassword = currentPassword;
            }

            axios.post(process.env.REACT_APP_RESET_PASSWORD, requestBody, config)
                .then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    _sendLog(['Supplier Portal React - User Service - _resetPassword', JSON.stringify(error)],
                        'error');
                    reject(error);
                }
            );
        })
    }
}

export const _decryptResetPasswordToken = (token, iv) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    token: token,
                    iv: iv
                },
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_DECRYPT_RESET_PASSWORD_TOKEN, config)
                .then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error);
                });
        })
    }
}

export const _logout = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let config = {
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_LOGOUT, config)
                .then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    _sendLog(['Supplier Portal React - User Service - _logout', JSON.stringify(error)],
                        'error').then();
                    reject(error);
                }
            );
        })
    }
}

export const _getUser = property ( 'user' );
