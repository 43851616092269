import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import styled from "styled-components";
import {_getLang, _hideSpinner, _showSpinner} from "../../store/system/service";
import {_getUser} from "../../store/user/service";
import Header from "../custom/header";
import useConfig from "../../utils/qnect-config";

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: "100%",
        background: "rgb(246,248,250)",
        minHeight: "calc(100vh - 64px)",
        '& .MuiFormControl-root.TextField' : {
            marginBottom: '10px',
            marginTop: '10px',
        },
        [theme.breakpoints.down("sm")]: {
            minHeight: "calc(100vh + 28px)",
        },
        [theme.breakpoints.up("lg")]: {}
    },
    topBg: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("lg")]: {
            height: "432px"
        }
    },
    topRow: {
        margin: "0 auto",
        width: "100%",
        maxWidth: "1440px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            height: "44px",
            padding: "0 12px",
            alignItems: "center",
            borderBottom: "1px solid #E1E4E8"
        },
        [theme.breakpoints.up("lg")]: {
            alignItems: "flex-end",
            height: "75px"
        }
    },
    logo: {
        [theme.breakpoints.down("sm")]: {
            width: "70px"
        },
        [theme.breakpoints.up("lg")]: {
            width: "122px",
            height: "35px"
        }
    },
    main: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px 0 rgba(0,0,0,0.04)",
        [theme.breakpoints.down("sm")]: {
            margin: "20px auto",
            padding: "20px 16px",
            height: "454px",
            width: "92%"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 auto",
            padding: "48px 70px",
            height: "fit-content",
            width: "600px"
        },
        fontFamily: "Inter-Regular",
        fontSize: "16px",
    },
    title: {
        fontFamily: "Stag-Medium",
        fontSize: "24px",
        fontWeight: "500",
        letterSpacing: "0",
        lineHeight: "28px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "0px",
            color: "#003DA5",
            fontSize: "21px"
        },
        [theme.breakpoints.up("lg")]: {
            marginBottom: "57px",
            color: "#003DA5"
        }
    },
    message: {
        color: "#0A1F44",
        fontFamily: "Inter",
        letterSpacing: "0",
        textAlign: "center",
        lineHeight: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "normal",
            "&:last-child": {
                marginTop: "4px"
            }
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "18px",
            "&:last-child": {
                marginTop: "37px"
            }
        }
    },
    image: {
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            "& svg": {
                width: "260px"
            }
        },

    },
    outerTitle: {
        [theme.breakpoints.down("sm")]: {
            margin: "20px auto",
            padding: "20px 16px",
            height: "454px",
            width: "92%",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 auto",
            width: "600px",
            height: '88px',
        },
        position: "relative",
        top: "0",
        textAlign: "center",
        fontFamily: "Stag-Medium",
        fontSize: "24px",
        backgroundColor: '#f1f2f4',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '2px solid #009AE4'
    },
    firstLine: {
        marginBottom: "17px",
        textAlign: "center",
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: '28px',
    },
}));

const RequirementsDiv = styled.div`
    margin-bottom: 20px;

    ul {
        padding-left: 32px;
    }
    
    li {
        padding-top: 8px;
    }
`;

const InvalidLink = (props) => {

    const classes = useStyles();
    const cfg = useConfig();

    return (
        <div className={classes.wrapper}>
            <Header />

            <div style={{marginTop: '60px'}}>
                <div className={classes.outerTitle} style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}>
                    {cfg('invalidLink.title')}
                </div>
                <div className={classes.main}>
                    <RequirementsDiv>
                        <div className={classes.firstLine}>{cfg(props.action === 'registration' ? 'invalidLink.registration' :
                            'invalidLink.forgotPassword')}</div>
                    </RequirementsDiv>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: _getUser(state),
        lang: _getLang(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvalidLink);
