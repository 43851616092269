import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";

import DialogTitle from "../dialog-title";
import FieldWrapper from "../field-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import QnectTextField from "../../qnect-ui/qnect-text-field";
import {_validateForm} from "../../../utils/utility";
import QnectError from "../../qnect-ui/qnect-error";


const useStyles = makeStyles(theme => ({
    dialog: {
        "& .MuiDialog-paper": {
            [theme.breakpoints.between("sm", "md")]: {
                padding: "14px 78px 52px"
            },
            [theme.breakpoints.up("lg")]: {
                padding: "14px 110px 52px",
                width: "630px"
            }
        }
    },
    message: {
        textAlign: "center",
        color: "#0a1f44",
        fontFamily: "Inter",
        [theme.breakpoints.down("sm")]: {
            marginTop: "4px",
            fontSize: "16px",
            lineHeight: "20px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "20px",
            fontSize: "18px",
            lineHeight: "25px"
        },
        whiteSpace: "pre-wrap"
    },
    input_wrapper: {
        [theme.breakpoints.down("sm")]: {
            marginTop: "31px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "39px"
        }
    },
    country: {
        [theme.breakpoints.down("sm")]: {
            marginRight: "8px",
            width: "112px",
            flexShrink: 0
        },
        [theme.breakpoints.up("sm")]: {
            marginRight: "20px",
            width: "160px"
        }
    },
    error_message: {
        margin: "8px 0",
        fontSize: "16px",
        fontFamily: "Inter",
        lineHeight: "24px",
        color: "#d54040",
        wordBreak: "keep-all",
        whiteSpace: "pre-wrap",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("sm")]: {}
    },
    help: {
        fontSize: "16px",
        lineHeight: "24px",
        color: "#003da5",
        cursor: "pointer",
        fontFamily: "Inter",
        [theme.breakpoints.down("sm")]: {
            paddingTop: "4px"
        },
        [theme.breakpoints.up("sm")]: {
            paddingTop: "22px"
        }
    },
    actions: {
        justifyContent: "space-between",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            marginTop: "44px",
            marginBottom: "44px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "60px",
            marginBottom: "60px",
            padding: "0 24px"
        }
    },
    backButton: {
        position: "absolute",
        top: "34px",
        left: "128px",
        cursor: "pointer",

        [theme.breakpoints.down("sm")]: {
            top: "23px",
            left: "43px",
            height: "20px",
            width: "20px"
        }
    },
    row: {
        color: "#0a1f44",
        textAlign: "center",
        fontFamily: "Inter",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            "&:first-child": {
                marginTop: "40px"
            },
            margin: "20px auto 0",
            fontSize: "16px",
            lineHeight: "20px"
        },
        [theme.breakpoints.up("sm")]: {
            "&:first-child": {
                marginTop: "20px"
            },
            margin: "30px auto 0",
            fontSize: "18px",
            lineHeight: "25px"
        }
    },
    icon: {
        width: "24px",
        height: "24px",
        marginRight: "4px"
    }
}));

const ForgotPasswordDialog = ({
                                  open,
                                  onClose,
                                  onSubmit,
                                  errorMessage,
                                  setErrorMessage,
                                  cfg,
                                  ...props
                              }) => {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const formId = 'forgot-password-form';


    useEffect(() => {
        if (open) {
            setErrorMessage('');
            setEmail('');
        }
    }, [open])


    return (
        <div>
            <Dialog className={classes.dialog} open={open} onClose={onClose}>
                <DialogTitle title={cfg("forgotPassword.title")} onClose={onClose} lang={props.lang}/>
                <DialogContent>
                    <div className={classes.message}>
                        {cfg("forgotPassword.message")}
                    </div>
                    <FieldWrapper>
                        <QnectTextField
                            value={email}
                            field="emailAddress"
                            formId={formId}
                            onChange={(evt) => {
                                setEmail(evt.target.value);
                                setErrorMessage('');
                            }}
                        />
                    </FieldWrapper>
                    {errorMessage && (
                        <div className={classes.error_message}>
                            <QnectError errorTitle={"Error"} errorMessage={errorMessage} />
                        </div>
                    )}
                    <div className={classes.actions}>
                        <FieldWrapper>
                            <QnectButton
                                onClick={() => {
                                    if (_validateForm(formId)) {
                                        onSubmit(email)
                                    }
                                }}>
                                {cfg("forgotPassword.submit")}
                            </QnectButton>
                        </FieldWrapper>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ForgotPasswordDialog;
