import {SET_DOCUMENT, RESET_DOCUMENT} from "./types";
import {updateObject} from "../../utils/utility";

const INITIAL_STATE = {
    identifier: '',
    documentList: [],
    showUploadedDocs: false,
    downloadDocs: [],
    hasMultiDocSelection: false
};

const updateDocumentState = (state, action) => {
    return updateObject(
        state,
        action.data
    )
}
  
const resetState = () => {
    INITIAL_STATE.identifier = "";
    INITIAL_STATE.documentList = [];
    INITIAL_STATE.downloadDocs = [];
    return INITIAL_STATE;
}

export const documentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_DOCUMENT:
            return updateDocumentState(state, action);
        case RESET_DOCUMENT:
            return resetState();
        default:
            return state;
    }
}
  


