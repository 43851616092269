import React, {useEffect, useState} from "react";
import useClaimsDetailsStyles, { BackIcon, BackWrapper, Container, Title, TopWrapper } from "./claims-details-style";
import QnectButton from "../qnect-ui/qnect-button";
import { _getClaim, _resetClaim} from "../../store/claims/service";
import {
    _getLang,
    _hideSpinner,
    _showSpinner,
    _resetClaimsToken, _resetDocumentToken
} from "../../store/system/service";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import useConfig from "../qnect-ui/qnect-config";
import DmhIcon from '../../assets/claims/Icon-dmh.svg';
import HomeIcon from '../../assets/claims/Icon-home.svg';
import MotorIcon from '../../assets/claims/Icon-motor.svg';
import PanIcon from '../../assets/claims/Icon-pan.svg';
import TravelIcon from '../../assets/claims/Icon-travel.svg';
import GenericIcon from '../../assets/claims/Icon-generic.svg';
import Hidden from "@material-ui/core/Hidden";
import FieldWrapper from "../custom/field-wrapper";
import ClaimsDocuments from "../claims-documents/claims-documents"
import QnectError from "../qnect-ui/qnect-error";

const ClaimsDetails = (props) => {
    const cfg = useConfig(props.lang);
    const classes = useClaimsDetailsStyles(props);
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
      setErrorMessage([]);
    }, []);

    const checkAnotherClaim = () => {
      props.resetClaim();
      props.resetClaimsToken();
      props.resetDocumentToken();
      props.history.push("/claims")
    }
    
    return (
      <>
        <Container>
          <div className={classes.box}>
            <TopWrapper>
              <Hidden mdDown>
                <BackWrapper onClick={() => history.push("/claims")}>
                  <BackIcon/>{cfg('backBtn.label')}
                </BackWrapper>
                <Title>{cfg('claimStatusChecking.details')}</Title>
              </Hidden>
            </TopWrapper>
          </div>
        </Container> 

        <div style={{overflowY: "auto !important"}}>
          <div className={classes.claimsdetails_header_rectangle}>
            <div className={classes.claimsdetails_header_content}>
              
                <div className={classes.claimsdetails_header_item}>
                  <img src={getImgSrc(props.claim.productType)} alt="ProductType" className={classes.product_type_icon}/>
                </div>

                {props.claim &&
                      <ClaimsDocuments claim={props.claim} cfg={cfg} />
                }

                <div className={classes.product_type_label}>
                    <label>{props.claim.productDescription}</label>
                </div>

                <div className={classes.claim_num_label}>
                    <label>{cfg('claimDetails.claimNumber')}</label>
                </div>

                <div className={classes.claim_num_label}>
                    <label>{cfg('claimDetails.entity')}</label>
                </div>

                <div className={classes.claim_num_content}>
                    <label>{props.claim.claimNumber}</label>
                </div>

                <div className={classes.claim_num_content}>
                    <label>{props.claim.entity}</label>
                </div>
            </div>
          </div>

          <div className={classes.claimsdetails_details_rectangle}>
            <div style={{display: "flex"}}>
              <div className={classes.claim_number_label}>
                  <label>{cfg('claimDetails.claimNumber')}: {props.claim.claimNumber}</label>
              </div>
              <div className={classes.claim_number_status_box}>
                <label>{props.claim.qnectStatus}</label>
              </div>
            </div>
            <div className={classes.claim_number_line}/>

            
            {cfg(props.claim.qnectStatusLong) !== '' && 
            <div>
              <div className={classes.data_label}>
                <label>{cfg('claimDetails.status')}</label>
              </div>
              <div className={classes.data_content}>
                <label>{cfg(props.claim.qnectStatusLong)}</label>
              </div>
            </div>}
            
            
            <div className={classes.data_label}>
                <label>{cfg('claimDetails.policyholder')}</label>
            </div>
            <div className={classes.data_content}>
                <label>{props.claim.clientName}</label>
            </div>
            <div className={classes.data_label}>
                <label>{cfg('claimDetails.dateOfLoss')}</label>
            </div>
            <div className={classes.data_content}>
            <label>{props.claim.dateOfLoss}</label>
            </div>
            <div className={classes.data_label}>
                <label>{cfg('claimDetails.lastUpdate')}</label>
            </div>
            <div className={classes.data_content}>
            <label>{props.claim.lastUpdate}</label>
            </div>
          </div>

          {errorMessage && (
              <div className={classes.errorMessage}>
                  <QnectError errorTitle={"Error"} errorMessage={errorMessage} />
              </div>
          )}
          <div className={classes.group_button}>
              <FieldWrapper>
                <QnectButton
                    field="checkAnother"
                    onClick={() => checkAnotherClaim()}
                />
              </FieldWrapper>
              <br/><br/><br/>
          </div>
        </div>
      </>
    );
};

const getImgSrc = (image) => {
  let pan = 'PAN, PAD, PAM';
  let dmh = 'DMH';
  let home = 'HPK, HHH';
  let motor = 'MVA';
  let travel = 'PIT, PTR, PPT, PSA';
  
  if (pan.includes(image.toUpperCase())) {
    return PanIcon;
  } else if (dmh.includes(image.toUpperCase())) {
    return DmhIcon;
  } else if (home.includes(image.toUpperCase())) {
    return HomeIcon;
  } else if (motor.includes(image.toUpperCase())) {
    return MotorIcon;
  } else if (travel.includes(image.toUpperCase())) {
    return TravelIcon;
  } else {
    return GenericIcon;
  }
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        claim: _getClaim(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    resetClaim: _resetClaim,
    resetClaimsToken: _resetClaimsToken,
    resetDocumentToken: _resetDocumentToken
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClaimsDetails));
