import React from 'react';
import Claims from "../components/claims/claims";
import Header from "../components/custom/header";
import SessionTimeout from "../components/session-timeout";

const ClaimsPage = () => {

    return (
        <React.Fragment>
            {
                <div>
                    <Header />
                    <Claims/>
                    <SessionTimeout/>
                </div>
            }
        </React.Fragment>
    )
}

export default (ClaimsPage);
