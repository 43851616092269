import React from 'react';
import ClaimsDetails from "../components/claims-details/claims-details";
import Header from "../components/custom/header";
import SessionTimeout from "../components/session-timeout";

const ClaimsDetailsPage = () => {

    return (
        <React.Fragment>
            {
                <div>
                    <Header/>
                    <ClaimsDetails/>
                    <SessionTimeout/>
                </div>
            }
        </React.Fragment>
    )
};

export default (ClaimsDetailsPage);
