import React, {useEffect, useState} from "react";
import QnectButton from "../qnect-ui/qnect-button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../custom/dialog-title";
import Dialog from '../custom/dialog';
import {useDialogStyles} from "../custom/dialog-style";
import useConfig from "../qnect-ui/qnect-config";
import UserDetails from "./user-details";

export const ResendDialog = ({open = false, onClose, onOk, isAdmin=false, providers, rowData, ...props}) => {

    const classes = useDialogStyles();
    const cfg = useConfig();
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        setUserId(rowData?.userId);
    },[rowData, open]);

    const onClickOk = () => {
        onOk(userId);
    };

    return (
        <div>
            <Dialog open={open} onClose={onClose} className={classes.modal_box_container}>
                <DialogTitle className={classes.title}
                             title={
                                 <div>
                                     {cfg("userDetailModals.resend.title")}
                                 </div>}
                             closable={false} lang={props.lang}/>
                <DialogContent>
                    <UserDetails rowData={rowData}
                                 isAdmin={isAdmin}
                                 country={props.country}
                                 cfg={cfg} />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <QnectButton onClick={onClose} field="cancel" />
                    <QnectButton onClick={() => onClickOk()} field="confirm"/>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ResendDialog;
