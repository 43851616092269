import {property} from "lodash";
import axios from "axios";
import {_getCpToken, _getClaimsToken, _getDocumentToken} from "../system/service";
import {_sendLog} from "../../utils/utility";

const authCpHeader = (state) => {
  const cpToken = _getCpToken(state);
  if (cpToken) {
      return { 'Authorization': 'Bearer ' + cpToken };
  } else {
      return {};
  }
}

const authDocHeader = (state) => {
    const claimsToken = _getClaimsToken(state);
    if (claimsToken) {
        return { 'Authorization': 'Bearer ' + claimsToken };
    } else {
        return {};
    }
}

export const _deleteFile = (docID, identifier) => {
  
    return (_dispatch, getState) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("docID", docID);
        formData.append("identifier", identifier);
        formData.append("claimsToken", _getClaimsToken(getState()));

        let config = {
          headers: authCpHeader(getState()),
          timeout: 1800000
        };
        
        axios.post(process.env.REACT_APP_REMOVE_DOCUMENT, formData, config).then(resp => {
            resolve(resp);
        }).catch(error => {
            reject(error);
        })
    })
  }
}

export const _addFile = (file, identifier) => {

  return (_dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("identifier", identifier);
      formData.append("claimsToken", _getClaimsToken(getState()));

      let config = {
        headers: authCpHeader(getState()),
        timeout: 1800000
      };

      axios.post(process.env.REACT_APP_ADD_DOCUMENT, formData, config).then(resp => {
          resolve(resp);
      }).catch(error => {
          _sendLog(['Supplier Portal React - Documents Service - _addFile', JSON.stringify(error)],
                'error');
          reject(error);
      })
    })
  }
}

export const _uploadDocuments = (documentForm) => {
  return (_dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const requestBody = {
        documentForm: JSON.stringify(documentForm),
        claimsToken: _getClaimsToken(getState())
      };

      let config = {
        headers: authCpHeader(getState()),
        timeout: 1800000
      };

      axios.post(process.env.REACT_APP_UPLOAD_DOCUMENT, requestBody, config).then(resp => {
          resolve(resp);
      }).catch(error => {
          _sendLog(['Supplier Portal React - Documents Service - _uploadDocuments', JSON.stringify(error)],
                'error');
          reject(error);
      })
    })
  }
}

export const _updateDocument = (docData, action) => {
    return (_dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 1800000
            };

            const requestBody = {
                document: docData,
                documentToken: _getDocumentToken(getState()),
                action: action,
                claim: _getClaimsToken(getState())
            };

            axios.post(process.env.REACT_APP_UPDATE_DOCUMENT, requestBody, config).then(resp => {
                resolve(resp);
            }).catch(error => {
                _sendLog(['Supplier Portal React - Documents Service - _updateDocument', JSON.stringify(error)],
                    'error');
                reject(error);
            })
        })
    }
}

export const _getDocument = property ( 'document' );
