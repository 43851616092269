import React, {useEffect, useState} from "react";
import {_getClaimDocs, _loadDocument} from "../../store/claims/service";
import {
    _getLang,
    _hideSpinner, _setDocumentToken,
    _showSpinner, _showError
} from "../../store/system/service";
import {withRouter, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {saveAs} from 'file-saver';
import {_getUser} from "../../store/user/service";
import useClaimsDetailsStyles from "../claims-details/claims-details-style";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import {base64ToArrayBuffer, tagManagerClickEvent} from "../../utils/utility";
import Button from "../../ui/components/buttons/button";
import {ReactComponent as Arrow} from "../../assets/Arrow.svg"

const ClaimsDocuments = ({claim, cfg, ...props}) =>  {
    const classes = useClaimsDetailsStyles(props);
    const [document, setDocument] = useState("");
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        props.getClaimDocs('CD', claim.productType).then((response) => {
            if (response.status === 'success' && response.documents) {
                setDocument(response.documents[0]);
                props.setDocumentToken(response.token);
            }
        });
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        tagManagerClickEvent('Menu-Click','Actions', claim.claimNumber);
    };

    const saveAsFile = (docData) => {
        setAnchorEl(null);
        tagManagerClickEvent('Menu-Click','Download claim details', claim.claimNumber);
        const ddocName = docData.dDocName;
        const fileName = docData.docFileName;
        props.showSpinner();
        props.loadDocument(ddocName).then((doc) => {
            if (doc && doc.documentBytes) {
                const data = base64ToArrayBuffer(doc.documentBytes);
                const blob = new Blob([data], {type: 'application/pdf'});
                saveAs(blob, fileName);
            }
            props.hideSpinner();
        }, () => {
            props.hideSpinner();
            props.showError(['Error in downloading document']);
        });
    };

    const viewDocs = () => {
        history.push('/viewDocuments');
        tagManagerClickEvent('Menu-Click','View documents', claim.claimNumber);
    };

    const uploadDocs = () => {
        history.push('/uploadDocuments');
        tagManagerClickEvent('Menu-Click','Upload document', claim.claimNumber);
    };

    const submitInvoice = () => {
        history.push("/viewInvoice");
        tagManagerClickEvent('Menu-Click','Submit invoice', claim.claimNumber);
    };

    return (
        <>
            <div>
                <Button color="secondary" 
                        aria-controls={open ? "simple-menu" : undefined} aria-haspopup="true" 
                        aria-expanded={open ? 'true' : undefined} 
                        onClick={(event) => {handleClick(event)}}>
                    <div className={classes.whiteButtonStyle}>
                        <div style={{marginRight: "11px"}}>{cfg('documentOptions.actions')}</div>
                        <div style={{fill: '#1D4FBB'}}><Arrow/></div>
                    </div>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    disableScrollLock={true}>
                    { document && <MenuItem onClick={() => {saveAsFile(document)}}>{cfg('documentOptions.downloadClaimDetails')}</MenuItem> }
                    { document && <Divider sx={{ my: 0.5 }}/> }
                    <MenuItem onClick={() => {viewDocs()}}>{cfg('documentOptions.viewAllDocuments')}</MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={() => {uploadDocs()}}>{cfg('documentOptions.uploadDocument')}</MenuItem>
                    { claim.hasClaimUnit && claim.isProviderAllowedInvoiceSubmission && <Divider sx={{ my: 0.5 }} /> }
                    { claim.hasClaimUnit && claim.isProviderAllowedInvoiceSubmission && <MenuItem onClick={() => {submitInvoice()}}>{cfg('documentOptions.submitInvoice')}</MenuItem> }
                </Menu>
            </div>
        </>
    );

};

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        user: _getUser(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    getClaimDocs: _getClaimDocs,
    loadDocument: _loadDocument,
    setDocumentToken: _setDocumentToken,
    showError: _showError
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClaimsDocuments));
