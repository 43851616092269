import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Back } from "../../assets/login/back_sharp.svg";

export const StyledModalDiv = styled.div`
  width: 504px;
  text-align: center;
  
  div.iconDiv {
    margin-top: 40px;
  }

  div.iconDivSuccess {
    margin-top: 40px;
    color: #00865A;
  }
  div.iconDivFail {
    margin-top: 40px;
    color: #D43900;
  }

  div.title {
    font-family: Stag-Medium;
    font-size: 24px;
    color: #003da5;
    line-height: 36px;
    margin-top: 16px;
  }

  div.subtitle {
    font-family: Stag-Medium;
    font-size: 18px;
    color: #00205B;
    line-height: 36px;
    margin-top: 18px;
  }

  div.content {
    margin-top: 41px;
    margin-bottom: 40px;
    font-family: Inter-Regular;
    font-size: 18px;
    white-space: pre-wrap
  }
  
  div.contents {
   margin-top: 41px;
   margin-bottom: 40px;
   font-size: 18px;
   white-space: pre-wrap
  }

  div.buttonDiv {
    margin-top: 20px;
    margin-left: 70px;
    margin-right: 70px;
  }
  
  div.buttonsDiv {
    margin-left: 150px;
    margin-right: 150px;
  }
  
  @media (max-width:767.95px) {
     width: auto;
     div.buttonDiv {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const BackIcon = styled(Back)`
  margin-right: 8px;
`;

export const Container = styled.div`
  padding-bottom: 30px;
  background-color: #FFFFFF;
`;

export const TopWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1110px;
  height: 100%;
  position: relative;
`;

export const BackWrapper = styled.div`
  margin-top: 40px;
  width: 87px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #1d4fbb;
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
`;

export const Title = styled.div`
  margin-left: 20px;
  margin-top: 40px;
  font-size: 40px;
  line-height: 48px;
  font-family: Stag;
  font-weight: 500;
  letter-spacing: 1px;
  color: #003da5;
`;

export const TableWrapper = styled.div`
    width: 1250px;
    font-family: 'Inter-Regular', 'Inter Regular', 'Inter', sans-serif;
    font-size: 12px;
    margin: 0 auto 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 2px 8px 0 rgba(20,40,75,0.2);
    border: 1px solid #eee;
    margin-bottom: 50px;

    @media (max-width: 1251px) and (min-width: 1024px) {
      width: 100%;
    }

`;

export const RadioBoxStyle = styled.div`
   
   margin-top: 5px;
   margin-bottom: 20px;

   .SelectCard{
        width: 133px!important;
    }

   .SelectCard span{
        font-family: Inter-Regular ;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 24px;
    }
    
    .SelectCard input:checked ~ span{
        font-family: Inter-Bold !important;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
    }
  
`;

export const StyledNameDiv = styled.div`
    color: #003DA5;
    cursor: pointer;
    font-weight: bold;
`;

export const DialogContent = styled.div`
    padding-left: 40px;
    padding-right: 40px;
`;

const useInvoiceStyles = makeStyles(theme => ({

    rectangle: {
        margin: '5vh auto',
        minHeight: '500px',
        width: '600px',
        borderRadius: '0 0 8px 8px',
        backgroundColor: '#FFFFFF',
        boxShadow: '2px 2px 8px 0 rgba(20,40,75,0.2)',
        textAlign: 'center'
    },

    page_container: {
        marginTop: '15px',
        width: '1250px',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
        [theme.breakpoints.between("1024", "1250")]: {
            width: '95%',
        },
        "& .MuiToolbar-regular" : {
            borderBottom: '1px solid #8A94A6',
            minHeight: '75px',
        },
        "& .MuiInputBase-root.MuiInput-formControl" : {
            minHeight: '42px',
            minWidth: '300px',
            padding: '8px 12px 8px 20px',
            border: '0',
            color: '#455571',
            fontFamily: 'Inter, Inter Regular, Inter-Regular, sans-serif',
            fontSize: '14px',
            lineHeight: '20px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '10px center',
            backgroundColor: '#F7F8F9',
            "& .MuiInputAdornment-positionEnd button" : {
                display: 'none',
            },
        },
        "& .MuiInputBase-root.MuiTablePagination-input" : {
            minHeight: '42px',
            border: '0',
            color: '#455571',
            fontFamily: 'Inter, Inter Regular, Inter-Regular, sans-serif',
            fontSize: '14px',
            lineHeight: '20px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '10px center',
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before" : {
            borderBottom: '0',
        },
        "& .MuiInput-underline:before" : {
            borderBottom: '0',
        },
        "& .MuiInput-underline:after" : {
            borderBottom: '0',
        },
        "& .MuiTableBody-root" : {
            fontSize: '12px',
            "& .tableTitle" : {
                color: '#0A1F44',
            },
            "& td": {
                fontFamily: 'Inter, Inter Regular, Inter-Regular, sans-serif',
                fontSize: '400',
            },
        },
        "& .MuiTableCell-root" : {
            padding: '20px 16px',
            paddingLeft: '25px'
        },
        "& .MuiTableFooter-root .MuiTablePagination-toolbar" : {
            minHeight: '75px',
        },
    },

    rowTitle: {
        display: 'flex'
    },

    toggle: {
        paddingLeft: '20px'
    },

    errorMessage: {
        marginBottom: "10px",
        "& .alert": {
            marginTop: "0px"
        }
    },

    label_box: {
      margin: '0 auto',
      height: 'auto',
      width: '600px',
      display: "flex", 
      flexWrap: 'wrap',
      marginTop: '40px',
    },

    invoicedetails_doc_label: {
      height: 'auto',
      fontFamily: 'Inter, Inter Regular, Inter-Regular, sans-serif',
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'justify',
      padding: '0px 0px 0px 15px',
      flex: '50%'
    },
    
    download_all: {
      height: 'auto',
      width: 'auto',
      borderRadius: '6px',
      textAlign: 'justify',
      overflowWrap: 'break-word'
    },

    invoicedetails_doc_rectangle: {
      margin: '0 auto',
      height: 'auto',
      width: '600px',
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      boxShadow: '2px 2px 8px 0 rgba(20,40,75,0.2)',
      padding: '25px',
      color: '#003da5'
    },

    invoicedetails_doc_column: {
      height: 'auto',
      fontFamily: 'Inter, Inter Regular, Inter-Regular, sans-serif',
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#0A1F44'
    },

    invoicedetails_doc_content: {
      flex: '80%',
      flexWrap: 'wrap',
      paddingTop: '10px',
      fontFamily: 'Inter, Inter Regular, Inter-Regular, sans-serif',
      fontSize: '14px',
      textAlign: 'justify',
      overflowWrap: 'break-word'
    },

    invoicedetails_doc_button: {
      flex: '20%',
      padding: '10px 0px 0px 15px',
    }, 

    invoice_details_rectangle: {
      margin: '0 auto',
      height: 'auto',
      width: '600px',
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      boxShadow: '2px 2px 8px 0 rgba(20,40,75,0.2)',
      padding: '25px',
      color: '#003da5'
    },

    invoice_number_label: {
      height: 'auto',
      width: 'auto',
      color: '#000000',
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 'bold',
      flex: '50%'
    },

    invoice_status_box: {
      height: 'auto',
      width: 'auto',
      borderRadius: '6px',
      background: 'rgba(0,193,204,0.08)',
      padding: '5px',
      textAlign: 'justify',
      overflowWrap: 'break-word'
    },

    invoice_line: {
      height: '4px',
      width: 'auto',
      backgroundColor: '#F1F2F4',
      marginTop: '2%'
    },

    data_label: {
      height: '21px',
      width: '390.2px',
      color: '#8A94A6',
      fontFamily: 'Inter',
      fontSize: '14px',
      letterSpacing: '0',
      lineHeight: '21px',
      paddingTop: '20px'
    },

    data_content: {
      height: 'auto',
      width: 'auto',
      color: '#0A1F44',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0',
      paddingTop: '4%',
      whiteSpace: 'pre-wrap', 
      overflowWrap: 'break-word'
    },

    totalFee_label: {
      height: 'auto',
      width: 'auto',
      color: '#003da5',
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: 'bold',
      letterSpacing: '0',
      paddingTop: '4%',
      whiteSpace: 'pre-wrap', 
      overflowWrap: 'break-word'
    },

    group_button: {
      margin: "0 auto",
      width: "auto",
      maxWidth: "370px",
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      '& div' : {
          textAlign: 'center',
      }
    }

}));

export default useInvoiceStyles;