import React, {useEffect, useState} from "react";
import styled from "styled-components";
import useAdminStyles, {StyledModalDiv} from "./admin-style";
import MaterialUiTable from "../ui/material-ui-table/material-ui-table";
import {_getUser} from "../../store/user/service";
import {_getUserList, _saveUser, _resendRegistrationEmail, _updateUserStatus, _getProviderList} from "../../store/admin/service";
import {_getCountry, _getLang, _hideSpinner, _showSpinner, 
    _showModal, _hideModal, _showError} 
    from "../../store/system/service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import useConfig from "../qnect-ui/qnect-config";
import AddEditUserDialog from "./add-edit-user-dialog";
import ResendDialog from "./resend-dialog";
import UpdateUserStatusDialog from "./update-user-status-dialog"
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import QnectButton from "../qnect-ui/qnect-button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TabPanel from "../custom/tab-panel";
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from "@material-ui/core/Typography";
import {tagManagerPopup} from "../../utils/utility";

const TitleDiv = styled.div`
    margin-top: 48px;
    font-family: Stag-Medium;
    color: #0A1F44;
    font-size: 32px;
    flex: '50%';
`;

const AdminDetail = (props) => {
    const cfg = useConfig(props.lang);
    const classes = useAdminStyles(props);
    const [openAddEditUserDialog, setAddEditUserDialog] = useState(false);
    const [openResendDialog, setResendDialog] = useState(false);
    const [openUpdateUserStatusDialog, setUpdateUserStatusDialog] = useState(false);
    const [data, setData] = useState([]);
    const [providerData, setProviderData] = useState([]);
    const [userDetail, setUserDetail] = useState(null);

    const [value, setValue] = useState(0);
    const [valueEnt, setValueEnt] = useState('ALL');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        
        if (newValue===0) {
            setValueEnt('ALL');
            props.user.showPortalUsersFilter = true;
        } else {
            props.user.showPortalUsersFilter = false;
        }

        props.user.entityFilter = getEntityFilter(props.user.showPortalUsersFilter);
        loadUserList();
    };

    const handleChangeEntity = (event, entityValue) => {
        setValueEnt(entityValue);
        props.user.entityFilter = entityValue;
        loadUserList();
    }

    useEffect(() => {
        if (['SU', 'ADM'].indexOf(props.user.userType?.toUpperCase()) !== -1) {
            props.user.entityFilter = getEntityFilter(true);
            props.user.showPortalUsersFilter = true;
        }
        loadUserList();
    }, []);

    const getEntityFilter = (showPortalUsers) => {
        if (!showPortalUsers) {
          return  props.country;
        }
        return props.country === 'HKG' ? 'ALL' : props.country;
    }

    const loadUserList = () => {
        props.showSpinner();
        props.getUserList(props.user.email, props.user.userType, props.user.showPortalUsersFilter, props.user.entityFilter).then((response) => {
            setData(response.userList);
            props.hideSpinner();
        }, (error) => {
            console.log('getUserList - error occurred', error);
            props.hideSpinner();
        })
    }

    const loadProviderList = () => {
        props.showSpinner();
        props.getProviderList().then((response) => {
            setProviderData(response);
            props.hideSpinner();
        }, (error) => {
            console.log('loadProviderList - error occurred', error);
            props.hideSpinner();
        })
    }

    const callAddUserDialog = () => {
        setUserDetail(null);
        loadProviderList();
        setAddEditUserDialog(true);
        tagManagerPopup('Add User');
    }

    const addEditUser = (userData) => {
        let modalTitle = '';
        let modalMsg = '';
        let errMsg = '';
        if (userDetail===null) {
            modalTitle = cfg('labels.addUser.modalTitle');
            modalMsg = cfg('labels.addUser.modalMsg');
            errMsg = cfg('labels.addUser.errMsg');
        } else {
            modalTitle = cfg('labels.editUser.modalTitle');
            modalMsg = cfg('labels.editUser.modalMsg');
            errMsg = cfg('labels.editUser.errMsg');
        }

        setAddEditUserDialog(false);
        props.showSpinner();
        props.saveUser(userData).then((resp) => {
            props.hideSpinner();
            if (resp.success) {
                showModalSuccess(modalTitle, modalMsg);
                loadUserList();
            } else {
                props.showError([resp.errorDesc]);
                tagManagerPopup(resp.errorDesc);
            }
        }, (error) => {
            props.hideSpinner();
            if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                props.showError([cfg('errorMessage.internalServerError')]);
                tagManagerPopup(cfg('errorMessage.internalServerError'));
            } else {
                props.showError([errMsg]);
                tagManagerPopup(errMsg);
            }
        });
    }

    const resendRegistrationEmail = (userData) => {
        setResendDialog(false);
        props.showSpinner();
        props.resendRegistrationEmail(userData).then((resp) => {
            props.hideSpinner();
            if (resp.status === 'success') {
                showModalSuccess('Email Sent', 'Registration email has been sent. Please check email spam/junk mail box if you cannot find it.');
            } else {
                props.showError(['Send mail error']);
                tagManagerPopup('Send mail error');
            }
        }, (error) => {
            props.hideSpinner();
            props.showError(['Send mail error']);
            tagManagerPopup('Send mail error');
        });
    }

    const updateUserStatus = (userData) => {
        setUpdateUserStatusDialog(false);
        props.showSpinner();
        props.updateUserStatus(userData).then((resp) => {
            props.hideSpinner();
            showUserStatusMessage(resp.status, userData.status);
            loadUserList();
        }, (error) => {
            props.hideSpinner();
            showUserStatusMessage('', userData.status);
        });
    }


    const showUserStatusMessage = (respStatus, userStatus) => {
        if (respStatus === 'success') {
            userStatus === 'D' ?  showModalSuccess('User deactivated', 'The user has been deactivated.') :
                showModalSuccess('User activated', 'The user has been activated.');
        } else {
            if (userStatus === 'D') {
                props.showError(['User deactivate error']);
                tagManagerPopup('User deactivate error');
            } else {
                props.showError(['User activate error']);
                tagManagerPopup('User activate error');
            }
        }
    }


    const showModalSuccess = (title, msg) => {
        props.showModal({
            flat: true,
            content: <StyledModalDiv>
                <div className="iconDivSuccess"><CheckCircleIcon fontSize='large'/></div>
                <div className="title">{title}</div>
                <div className="subtitle">{msg}</div>
                <div className="buttonDiv">
                    <QnectButton
                        field="close"
                        onClick={() => {props.hideModal();}}
                    />
                </div>
            </StyledModalDiv>
        });
        tagManagerPopup(title);
    }

    const ColumnAction = ({selectedRowData}) => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (e) => {
            setAnchorEl(e.currentTarget);
            
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        function callEditUserDialog(rowData) {
            setAnchorEl(null);
            setUserDetail(rowData);
            loadProviderList();
            setAddEditUserDialog(true);
            tagManagerPopup('Edit User');
        }
    
        function callResendDialog(rowData) {
            setAnchorEl(null);
            setUserDetail(rowData);
            setResendDialog(true);
            tagManagerPopup('Resend Registration Email');
        }
    
        function callUpdateUserStatusDialog(rowData) {
            setAnchorEl(null);
            setUserDetail(rowData);
            setUpdateUserStatusDialog(true);
            tagManagerPopup(rowData.status === 'Active' ? 'Deactivate User'
                : 'Activate User' );
        }

        return (
            <div>
                <Button aria-controls="simple-menu" aria-haspopup="true" 
                    aria-expanded="true" variant="outlined" 
                    disableElevation onClick={handleClick} 
                    endIcon={<KeyboardArrowDownIcon/>}>
                    Actions
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableScrollLock={true}
                >
                    <MenuItem onClick={() => {callEditUserDialog(selectedRowData)}}>Edit</MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    { selectedRowData?.status === 'Pending' &&
                    <MenuItem onClick={() => {callResendDialog(selectedRowData)}}>Resend registration email</MenuItem>
                    }
                    { selectedRowData?.status === 'Active' &&
                    <MenuItem onClick={() => {callUpdateUserStatusDialog(selectedRowData)}}>Deactivate</MenuItem>
                    }
                    { selectedRowData?.status === 'Disabled' &&
                    <MenuItem onClick={() => {callUpdateUserStatusDialog(selectedRowData)}}>Activate</MenuItem>
                    }
                </Menu>
            </div>
        )
    }

    const columns = [
        {title: 'ID', field: 'userId', sorting: false},
        {title: 'NAME', field: 'name'},
        {title: 'EMAIL', field: 'email', sorting: false},
        {title: props.user.showPortalUsersFilter === true ? 'PROVIDER NAME' : 'USER TYPE' , 
                field: props.user.showPortalUsersFilter === true ? 'providerName' : 'userTypeLabel', 
                sorting: false},
        {title: 'MOBILE NO.', field: 'mobileNo', sorting: false},
        {title: 'STATUS', field: 'status', sorting: false},
        {title: '', sorting: false, render: (rowData) => (
            <ColumnAction selectedRowData={rowData}></ColumnAction>
        )}
    ];

    const toggle = [
        <ToggleButtonGroup value={valueEnt}
                    exclusive
                    onChange={handleChangeEntity}
                    size='small'>
            <ToggleButton value='ALL'>
                {'ALL'}
            </ToggleButton>
            <ToggleButton value='HKG'>
                {'HKG'}
            </ToggleButton>
            <ToggleButton value='HGI'>
                {'HGI'}
            </ToggleButton>
        </ToggleButtonGroup>
    ]

    const MyTitle = ({text, variant, showToggle}) => (
        <div>
            <Typography variant={variant}
                style={{whitespace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: '100%'}}>
                    {text}
                    {showToggle===true && <span className={classes.toggle}>{toggle}</span>}
            </Typography>
        </div>
    );

    return (
        <div className={classes.page_container}>
            <div className ={classes.rowTitle}>
                <TitleDiv>
                    {cfg("adminDetail.title")}
                </TitleDiv>
            </div>
            <br/>
            <div>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Portal Users" {...a11yProps(0)} />
                        {props.user.userType.toUpperCase() === 'SU' &&
                            <Tab label="Admin Users" {...a11yProps(1)} />
                        }
                        </Tabs>
                    </Box>
                    <br/><br></br>
                    <TabPanel value={value} index={0}>
                        <MaterialUiTable 
                                        columns={columns} data={data}
                                        title={<MyTitle variant="h6" text='Portal Users' showToggle={['HKG', 'HGI'].indexOf(props.country) !== -1}/>}
                                        onClickAdd={() => callAddUserDialog()}
                                        addText={cfg("userDetailModals.addUser.title")}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <MaterialUiTable 
                                        columns={columns} data={data}
                                        title={<MyTitle variant="h6" text='Admin Users' showToggle={false}/>}
                                        onClickAdd={() => callAddUserDialog()}
                                        addText={cfg("userDetailModals.addUser.title")}
                        />
                    </TabPanel>
                </Box>
            </div>
            <br/>

            <AddEditUserDialog open={openAddEditUserDialog}
                            onClose={() => setAddEditUserDialog(false)}
                            onOk={(userData) => addEditUser(userData)}
                            rowData={userDetail} 
                            isAdmin={!props.user.showPortalUsersFilter}
                            providers={providerData}
                            country={props.country}>
            </AddEditUserDialog>


            <ResendDialog open={openResendDialog}
                          onClose={() => setResendDialog(false)}
                          onOk={(userData) => {resendRegistrationEmail(userData)}}
                          rowData={userDetail}
                          isAdmin={!props.user.showPortalUsersFilter}
                          providers={providerData}
                          country={props.country}>
            </ResendDialog>

            <UpdateUserStatusDialog open={openUpdateUserStatusDialog}
                          onClose={() => setUpdateUserStatusDialog(false)}
                          onOk={(userData) => {updateUserStatus(userData)}}
                          rowData={userDetail}
                          isAdmin={!props.user.showPortalUsersFilter}
                          providers={providerData}
                          country={props.country}>
            </UpdateUserStatusDialog>

            <br/>
        </div>
    );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        user: _getUser(state),
        lang: _getLang(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    getUserList:_getUserList,
    saveUser: _saveUser,
    resendRegistrationEmail: _resendRegistrationEmail,
    updateUserStatus: _updateUserStatus,
    getProviderList: _getProviderList,
    showModal: _showModal,
    hideModal: _hideModal,
    showError: _showError
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminDetail));
