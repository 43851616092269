import React, {useEffect, useState} from 'react';
import InfoBlue from '../../../assets/info_black_new.svg';
import styled from 'styled-components';
import {v4 as uuid} from 'uuid';

const ToolTipDiv = styled.div`
    display: ${({showToolTip}) => showToolTip ? 'inline' : 'none'};
    
    position: absolute;
    pointer-events: none;

  z-index: 999;

  .pointerDiv {
    border-top: 10px solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid #b3e5fc;
    position: absolute;

        top: 13px;
        left: -3px;
    }
@media (min-width:768px) {
    
    .contentDiv {
        font-family: Inter-Regular;
        font-size: 14px;
        background: rgba(10,16,27,0.65);
        color: #fff;
        //min-height: 129px;
        min-width: 200px;
        position: absolute;
        top: -40px;
        left: 0px;
        margin-top: 0px;
        padding: 10px 10px 10px 20px;
        text-align: left;
        line-height: 19px;
        white-space: pre-wrap;
    }
}    
@media (max-width:767.95px) {
    position: fixed;
    left: 20px;
    max-width: 80vw;
    top:  ${({mobileTop}) => mobileTop ? `${mobileTop}px` : 'auto'};

    & >.contentDiv {
        margin-left: 0px!important;
        font-family: Inter-Regular;
        font-size: 14px;
        background: rgba(10,16,27,0.65);
        color: #fff;
        margin-top: 0px;
        padding: 10px 10px 10px 20px;
        text-align: left;
        line-height: 19px;
        white-space: pre-wrap;
    }
}
`;

const MainDiv = styled.div`
    position: relative;
    display: inline;
    
    img {
        position: absolute;
        top: 0;
        left: 0;
    }
    @media (max-width:767.95px) {
        &.BalloonTip > div.ToolTipDiv > .contentDiv {
            margin-left: 0px!important;
            max-width: calc(100vw - 40px);
            margin-top: 30px;
        }
    }
`;


const BalloonTip = (props) => {

    const [showToolTip, updateTooltipState] = useState(false);
    const [UUID,setUUID] = useState(null);
    const [mobileTop, setMobileTop] = useState(0);

    const mouseEnter = () => {

        const img = document.querySelector(`.${UUID}>.imageDiv>img`);
        setMobileTop(img.getBoundingClientRect().top);
        updateTooltipState(true);
    };

    const mouseLeave = () => {
        updateTooltipState(false);
    };
    const [scrollTop, setScrollTop] = useState(0);
    useEffect(()=>{
        setUUID('balloonTip-'+ uuid());
    },[])
    useEffect(() => {
        function onScroll() {
            let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
            if(showToolTip){
                updateTooltipState(!showToolTip)
            }
            setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    return (
        <MainDiv className={`BalloonTip ${UUID}`}>
            <div className="imageDiv">
                <img src={InfoBlue} onMouseEnter={mouseEnter} onClick={mouseEnter} onMouseLeave={mouseLeave}/>
            </div>
            <ToolTipDiv  className={"ToolTipDiv"} mobileTop={mobileTop} showToolTip={showToolTip}>
                <div className="contentDiv">
                    {props.children}
                </div>
            </ToolTipDiv>

        </MainDiv>
    );
};

export default BalloonTip;
