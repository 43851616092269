import React, {useEffect, useRef, useState} from "react";
import {useHistory, withRouter} from "react-router-dom";
import QnectTextField from "../qnect-ui/qnect-text-field";
import VerificationCodeDialog from "../custom/login/verification-code-dialog";
import TooManyFailedAttemptsDialog from "../custom/login/too-many-failed-attempts-dialog";
import {ReactComponent as Logo} from "../../assets/login/icon_logo.svg";
import {
    _clearToken,
    _generatePortfolioToken, _getLang,
    _getShowOtpModal,
    _hideModal,
    _hideSpinner,
    _isNoLogin,
    _setCpToken,
    _setLang,
    _setOtpToken,
    _setPortfolioToken,
    _setShowOtpModal,
    _showModal,
    _showSpinner,
    _verifyOtpAndGetUserDetail,
    _getCountry, _setTimerStarted
} from "../../store/system/service";
import {connect} from "react-redux";
import useConfig from "../qnect-ui/qnect-config";
import useLoginStyles, {StyledModalDiv} from "./login-style";
import {_aboutLink, _validateForm, customReplaceAll, tagManagerPopup} from "../../utils/utility";
import QnectButton from "../qnect-ui/qnect-button";
import FieldWrapper from "../custom/field-wrapper";
import Hidden from "@material-ui/core/Hidden";
import TopNotice from '../custom/top-notice';
import {
    _addEmailAddressToOTPLockedCache,
    _checkEmailIfRegistered,
    _getUser,
    _getUserDetailsFromToken,
    _resetUser,
    _sendResetPasswordLink,
    _storeUser,
    _verifyUserCredentials
} from "../../store/user/service";
import { _resetUserList } from "../../store/admin/service";
import ForgotPasswordDialog from "../custom/login/forgot-password-dialog";
import { _resetClaim } from "../../store/claims/service";
import QbeLogo from "../../assets/QBE logo on light.png";
import ButtonWrapper from "../custom/button-wrapper";
import TabPanel from "../custom/tab-panel";
import QnectError from "../qnect-ui/qnect-error";

const Login = (props) => {
    const history = useHistory();
    const cfg = useConfig(props.lang);
    const formId = 'login-form';
    const classes = useLoginStyles(props);
    const ref = useRef(null);
    const [lang, setLang] = useState(props.lang || 'en');
    const [emailAddress, setEmailAddress] = useState("");
    const [maskedEmail, setMaskedEmail] = useState("");
    const [otpValue, setOtpValue] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [otpErrorMessage, setOtpErrorMessage] = useState("");
    const [resendTimer, setResendTimer] = useState(0);
    const [resendTimerIntervalId, setResendTimerIntervalId] = useState(null);
    const [verifyOpen, setVerifyOpen] = useState(false);
    const [reconfirmOpen, setReconfirmOpen] = useState(false);
    const [failedOpen, setFailedOpen] = useState(false);
    const [password, setPassword] = useState();
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] = useState("");
    const [outerTab, setOuterTab] = useState("M");
    const url = _aboutLink(props.country);
    const [errorMessage, setErrorMessage] = useState("");
    const timer = cfg('login.timer');

    useEffect(() => {
        setErrorMessage([]);
        props.clearToken();
        props.resetClaim();
        props.resetUser();
        props.resetUserList();
    }, []);

    const resetResendTimer = () => {
        if (resendTimerIntervalId) {
            clearInterval(resendTimerIntervalId);
        }
        // email timer
        setResendTimer(timer);
        let setTimerInterval = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer > 0) {
                    return prevTimer - 1;
                } else {
                    clearInterval(setTimerInterval);
                    return 0;
                }
            });
        }, 1000);
        setResendTimerIntervalId(setTimerInterval);
    }

    const handleClickMobileBtn = () => {
        const dom = ref.current;
        dom.style.transform = dom?.style ? "translateY(-437px)" : "";
    };

    function handleLogin() {
        if (_validateForm(formId)) {
            props.showSpinner();
            setOtpValue("");
            setOtpErrorMessage('');
            setEmailErrorMessage("");
            setPasswordErrorMessage('');
            setErrorMessage([]);
            props.verifyUserCredentials(emailAddress, password).then((response) => {
                if (response.success) {
                    if (!response.lockType) {
                        if (response.isExisting === 'true') {

                            if (response?.errorcode === 'ERR04') {
                                setErrorMessage(response.userType ? cfg("errorMessage.err04_admin") :
                                    cfg("errorMessage." + response.errorcode?.toLowerCase()));
                            } else {
                                props.user.email = emailAddress;
                                props.setCpToken(response.token);

                                let val = emailAddress.split('@')[0];
                                let regexToUse = val.length > 4 ? /^.*(?=....@)/g : /^.*(?=@)/g;
                                let emailMasked = customReplaceAll(regexToUse, emailAddress, '•');
                                setMaskedEmail(emailMasked);

                                if (response.isUserAuthenticate) {
                                    props.setPortfolioToken(response.token);
                                    props.storeUser(response.userDetails);
                                    history.push('/adminDetails');
                                } else {
                                    resetResendTimer();
                                    setVerifyOpen(true);
                                    tagManagerPopup('Please Enter the Verification Code Received')
                                }
                            }

                        } else {
                            setErrorMessage(cfg("errorMessage." + response.errorcode?.toLowerCase()));
                        }
                        props.hideSpinner();
                    } else {
                        props.showModal({
                            flat: true,
                            content: <StyledModalDiv>
                                <div className="iconDiv">
                                </div>
                                <div className="title">Email Address is Locked</div>
                                <div className="content">Please try again
                                    after {response.lockType === 'otp' ? '10 minutes' : '1 hour'}</div>
                                <div className="buttonDiv">
                                    <QnectButton
                                        field="continue"
                                        onClick={() => {props.hideModal();}}
                                    />
                                </div>
                            </StyledModalDiv>
                        });
                        props.hideSpinner();
                    }
                }
                else {
                    props.hideSpinner();
                    setErrorMessage(cfg('errorMessage.unknownError'));
                }
            }, (error) => {
                if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                    setErrorMessage(cfg('errorMessage.serverError'));
                } else {
                    setErrorMessage(error.response.statusText);
                }
                props.hideSpinner();
            });
        }
    }



    const handleResend = () => {
        if (resendTimer === 0) {
            props.showSpinner();
            setOtpValue("");
            setOtpErrorMessage('');
            props.checkEmailIfRegistered(emailAddress, props.country).then((response) => {
                if (response.locked === 'true') {
                    setVerifyOpen(false);
                    setFailedOpen(true);
                } else if (response.isExisting === 'true') {
                    props.setCpToken(response.token);
                }
                props.hideSpinner();
            });
            resetResendTimer();
        }
    }

    const handleVerifyOtp = () => {
        props.showSpinner();
        props.verifyOtpAndGetUserDetail(otpValue, props.user.email, 'login').then((response) => {
            if (response.locked) {
                setVerifyOpen(false);
                setFailedOpen(true);
            } else if (response.success) {
                props.setCpToken(response.token);
                props.setPortfolioToken(response.token);

                if (['SU', 'ADM'].indexOf(response.userDetails.userType?.toUpperCase()) !== -1) {
                    history.push('/adminDetails');
                } else {
                    history.push('/claims');
                }
                
            } else {
                setOtpErrorMessage(cfg("otp.errorMessages.invalidOTP"));
            }
            props.hideSpinner();
        }, (error) => {
            setOtpErrorMessage(cfg("otp.errorMessages.invalidOTP"));
            props.hideSpinner();
        })
    }

    const handleClose5 = () => {
        setVerifyOpen(false);
        resetData();
    };

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
        console.log('setting language as ' + newLang);
    }

    const handleCloseForgotPasswordDialog = () => {
        setOpenForgotPasswordDialog(false);
    }

    const handleForgotPasswordSubmit = (email) => {
        props.showSpinner();
        props.sendResetPasswordLink(email)
            .then(() => {
                TopNotice.show({
                    message: cfg("forgotPassword.submitSuccessMessage"),
                    duration: 3,
                });
                setOpenForgotPasswordDialog(false);
                props.hideSpinner();
            }, () => {
                TopNotice.show({
                    message: cfg("forgotPassword.errorMessages.submitFailedMessage"),
                    duration: 3,
                });
                setOpenForgotPasswordDialog(false);
                props.hideSpinner();
            });
    }

    const resetData = () => {
        setErrorMessage([]);
        props.clearToken();
        props.resetClaim();
        props.resetUser();
        props.resetUserList();
        setEmailAddress('');
        setPassword('');
    }

    return (
        <>
            <div className={classes.container}>
                <div className={ classes.bg }>
                    <Hidden mdDown>
                        <div className={ classes.Cbg_img}/>
                    </Hidden>

                    <Hidden smUp>
                        <div className={classes.Mtop}>
                            <div className={classes.Mheader}>
                                <a href={cfg(url)}>
                                    <img src={QbeLogo} alt="QbeLogo" className={classes.Mlogo}/>
                                </a>
                                {/* { props.country !== 'SGP' &&
                                    <span
                                        className={classes.Mlanguage}
                                        onClick={() => switchLanguage()}
                                    >
                                          {lang === "en"
                                              ? cfg("labels.lang_zh")
                                              : cfg("labels.lang_en")}
                                    </span>
                                } */}
                            </div>
                        </div>
                        <div className={classes.Mbuttons}>
                            {!props.noLogin && (
                                <ButtonWrapper className={classes.Mbutton}>
                                    <QnectButton
                                        onClick={() => {
                                            handleClickMobileBtn();
                                            setOuterTab("M");
                                        }}
                                    >
                                        {cfg('login.memberLogin')}
                                    </QnectButton>
                                </ButtonWrapper>
                            )}
                        </div>
                    </Hidden>

                    <div className={classes.right_content}>
                        <Hidden smDown>
                            <div className={classes.icon_wrapper}>
                                <a href={cfg(url)}>
                                    <Logo/>
                                </a>
                                {/* { props.country !== 'SGP' &&
                                    <div
                                        className={classes.language_select}
                                        onClick={() => switchLanguage()}>
                                        {lang === "en"
                                            ? cfg("labels.lang_zh")
                                            : cfg("labels.lang_en")}
                                    </div>
                                } */}
                            </div>
                        </Hidden>
                        <div className={classes.Mform} ref={ref}>
                            <div className={classes.subtitle} style={ props.lang === 'zh' ? {fontWeight: 'bold'} : {}}>
                                {cfg('login.title')}
                            </div>

                            <TabPanel value='M' index='M'>
                                <div className={classes.tab_panel}>
                                    <div className={classes.member_input}>
                                        <FieldWrapper>
                                            <QnectTextField
                                                field="loginEmailAddress"
                                                value={emailAddress}
                                                errorMessage={emailErrorMessage}
                                                onChange={e => {
                                                    setEmailAddress(e.target.value);
                                                    setEmailErrorMessage("");
                                                }}
                                                placeholder={ cfg('login.emailAddressPlaceHolder') }
                                                formId={formId}
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper>
                                            <QnectTextField
                                                className="passwordField"
                                                field="loginPassword"
                                                type="password"
                                                value={password}
                                                formId={formId}
                                                errorMessage={passwordErrorMessage}
                                                onChange={e => {
                                                    setPassword(e.target.value);
                                                    setPasswordErrorMessage('');
                                                }}
                                            />
                                        </FieldWrapper>
                                    </div>
                                    {errorMessage && (
                                        <div className={classes.errorMessage}>
                                            <QnectError errorTitle={"Error"} errorMessage={errorMessage} />
                                        </div>
                                    )}
                                    <ButtonWrapper className={classes.button_wrapper + (passwordErrorMessage || emailErrorMessage ? " loginButton" : "")}>
                                        <QnectButton
                                            field="continue"
                                            disabled={!emailAddress}
                                            onClick={handleLogin}
                                        />
                                    </ButtonWrapper>
                                    
                                </div>
                            </TabPanel>

                            <Hidden>
                                <div className={classes.forgotEmailPassword}>
                                    <span onClick={() => {
                                            setOpenForgotPasswordDialog(true);
                                            setShowHelp(false);
                                            tagManagerPopup('Forgot Password');
                                        }}>
                                        {cfg('login.forgotPassword')}
                                    </span>
                                </div>
                            </Hidden>
                        </div>
                    </div>
                </div>
            </div>

            <VerificationCodeDialog
                open={verifyOpen}
                maskedEmail={maskedEmail}
                onClose={handleClose5}
                onConfirm={handleVerifyOtp}
                onOther={() => {
                    setVerifyOpen(false);
                    setReconfirmOpen(true);
                }}
                value={otpValue}
                setValue={setOtpValue}
                errorMessage={otpErrorMessage}
                setOtpErrorMessage={setOtpErrorMessage}
                handleResend={handleResend}
                resendTimer={resendTimer}
                cfg={cfg}
                lang={props.lang}
            />

            <ForgotPasswordDialog
                open={openForgotPasswordDialog}
                onClose={handleCloseForgotPasswordDialog}
                showHelp={() => {
                    setOpenForgotPasswordDialog(false);
                    setShowHelp(true);
                }}
                errorMessage={forgotPasswordErrorMessage}
                setErrorMessage={setForgotPasswordErrorMessage}
                onSubmit={handleForgotPasswordSubmit}
                cfg={cfg}
                lang={props.lang}
            />

            <TooManyFailedAttemptsDialog
                open={failedOpen}
                onClose={() => setFailedOpen(false)}
                cfg={cfg}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        country: _getCountry(state),
        noLogin: _isNoLogin(state),
        showOtpModal: _getShowOtpModal(state),
        user: _getUser(state)
    }
}

const mapDispatchToProps = {
    setLang: _setLang,
    checkEmailIfRegistered: _checkEmailIfRegistered,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    clearToken: _clearToken,
    setOtpToken: _setOtpToken,
    setCpToken: _setCpToken,
    verifyOtpAndGetUserDetail: _verifyOtpAndGetUserDetail,
    addEmailAddressToOTPLockedCache: _addEmailAddressToOTPLockedCache,
    storeUser: _storeUser,
    showModal: _showModal,
    hideModal: _hideModal,
    setShowOtpModal: _setShowOtpModal,
    generatePortfolioToken: _generatePortfolioToken,
    setPortfolioToken: _setPortfolioToken,
    getUserDetailsFromToken: _getUserDetailsFromToken,
    resetUser: _resetUser,
    resetUserList: _resetUserList,
    resetClaim: _resetClaim,
    verifyUserCredentials: _verifyUserCredentials,
    sendResetPasswordLink: _sendResetPasswordLink,
    setTimerStarted: _setTimerStarted
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
