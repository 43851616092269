import React from 'react';

import styled from 'styled-components';
import {ReactComponent as Cross} from "../../../assets/Cross.svg";

const FileItemStyle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    color: #0A1F44;
    font-family: Inter-Regular;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    
    padding-top: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid #C9CED6;
    flex-wrap: wrap;
`;
const FileItemControl = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
const FileItemControlBorder = styled.div`
    box-sizing: border-box;
    height: 17.5px;
    width: 1px;
    border: 1px solid #E1E4E8;
    margin: 0 11px;
`;

const FileItem = ({children, className, clickEye, clickPen, clickCross}) => {
    return (
        <FileItemStyle className={className}>
            {children}
            <FileItemControl>
                {/* <Eye style={{cursor:"pointer"}} onClick={clickEye}/>
                <FileItemControlBorder/>
                <SmallPen style={{cursor:"pointer"}} onClick={clickPen}/>
                <FileItemControlBorder/> */}
                <Cross style={{cursor:"pointer", marginRight:11}} onClick={clickCross}/>
            </FileItemControl>
        </FileItemStyle>
    );
};

export default FileItem;
