import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";

export const StyledModalDiv = styled.div`
  width: 504px;
  text-align: center;
  
  div.iconDiv {
    margin-top: 40px;
  }

  div.iconDivSuccess {
    margin-top: 40px;
    color: #00865A;
  }
  div.iconDivFail {
    margin-top: 40px;
    color: #D43900;
  }

  div.title {
    font-family: Stag-Medium;
    font-size: 24px;
    color: #003da5;
    line-height: 36px;
    margin-top: 16px;
  }

  div.subtitle {
    font-family: Stag-Medium;
    font-size: 18px;
    color: #00205B;
    line-height: 36px;
    margin-top: 18px;
  }

  div.content {
    margin-top: 41px;
    margin-bottom: 40px;
    font-family: Inter-Regular;
    font-size: 18px;
    white-space: pre-wrap
  }

  div.buttonDiv {
    margin-top: 20px;
    margin-left: 161px;
    margin-right: 163px;
  }
  @media (max-width:767.95px) {
     width: auto;
     div.buttonDiv {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const useAdminStyles = makeStyles(theme => ({

    page_container: {
        marginTop: '15px',
        width: '1250px',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
        [theme.breakpoints.between("1024", "1250")]: {
          width: '95%',
        },
        "& .MuiToolbar-regular" : {
          borderBottom: '1px solid #8A94A6',
          minHeight: '75px',
        },
        "& .MuiInputBase-root.MuiInput-formControl" : {
          minHeight: '42px',
          minWidth: '300px',
          padding: '8px 12px 8px 20px',
          border: '0',
          color: '#455571',
          fontFamily: 'Inter, Inter Regular, Inter-Regular, sans-serif',
          fontSize: '14px',
          lineHeight: '20px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '10px center',
          backgroundColor: '#F7F8F9',
          "& .MuiInputAdornment-positionEnd button" : {
            display: 'none',
          },
        },
        "& .MuiInputBase-root.MuiTablePagination-input" : {
          minHeight: '42px',
          border: '0',
          color: '#455571',
          fontFamily: 'Inter, Inter Regular, Inter-Regular, sans-serif',
          fontSize: '14px',
          lineHeight: '20px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '10px center',
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before" : {
          borderBottom: '0',
        },
        "& .MuiInput-underline:before" : {
          borderBottom: '0',
        },
        "& .MuiInput-underline:after" : {
          borderBottom: '0',
        },
        "& .MuiTableBody-root" : {
          fontSize: '12px',
          "& .tableTitle" : {
            color: '#0A1F44',
          },
          "& td": {
            fontFamily: 'Inter Regular, Inter-Regular, Inter, sans-serif !important',
            fontSize: '400',
          },
        },
        "& .MuiTableCell-root" : {
          padding: '20px 16px',
        },
        "& .MuiTableFooter-root .MuiTablePagination-toolbar" : {
          minHeight: '75px',
        },
    },

    rowTitle: {
      display: 'flex'
    },

    toggle: {
      paddingLeft: '20px'
    }

}));

export default useAdminStyles;