import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import property from 'lodash/property';

import {cssIfElse} from '../../../common/conditional-styles';

const TRANSITION_DURATION_MS = 100;

const Card = styled.div`
    box-sizing: border-box;
    display: flex;
    transition: box-shadow ${TRANSITION_DURATION_MS}ms, border-color ${TRANSITION_DURATION_MS}ms;
    border: 1px solid;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 ${({theme}) => rgba(theme.color.tertiary, 0.12)};
    background: ${property('theme.color.white')};
    width: 100%;
    //min-height: 74px;

    ${({scIsSelected, scIsDisabled}) => (scIsSelected && !scIsDisabled) ? css`
        border-color: ${property('theme.color.primary90')};
        box-shadow: 4px 4px 12px 0 ${({theme}) => rgba(theme.color.tertiary, 0.2)};
    ` : css`
        border-color: ${property('theme.color.grey40')};
        box-shadow: 1px 1px 4px 0 ${({theme}) => rgba(theme.color.tertiary, 0.12)};
    `}

    ${cssIfElse('scIsDisabled')`
        cursor: not-allowed;
    ``
        cursor: pointer;

        &:hover {
            box-shadow: 5px 5px 15px 0 ${({theme}) => rgba(theme.color.tertiary, 0.2)};

            ${cssIfElse('scIsSelected')`
                border-color: ${property('theme.color.primary90')};
            ``
                border-color: ${property('theme.color.tertiary90')};
            `}
        }

        &:active {
            outline: 0;
            box-shadow: 4px 4px 12px 0 ${({theme}) => rgba(theme.color.tertiary, 0.2)};

            ${cssIfElse('scIsSelected')`
                border-color: ${property('theme.color.primary30')};
            ``
                border-color: ${property('theme.color.tertiary10')};
            `}
        }
    `}
`;

const SelectCard = React.forwardRef((
    {
        className,
        isDisabled,
        isSelected,
        children,
        ...rest
    },
    ref
) => (
    <Card
        {...rest}
        ref={ref}
        className={classnames('SelectCard', className)}
        scIsSelected={isSelected}
        scIsDisabled={isDisabled}
    >
        {children}
    </Card>
));

SelectCard.propTypes = {
    className: propTypes.string,
    isDisabled: propTypes.bool,
    isSelected: propTypes.bool,
    children: propTypes.node,
};

SelectCard.displayName = 'SelectCard';

export default SelectCard;
