import React, {useState} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {_clearToken, _getCountry, _getPortfolioToken, _getTimerId} from "../../store/system/service";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {_getUser, _logout, _resetUser} from "../../store/user/service";
import {_resetUserList} from "../../store/admin/service";
import { _storeClaim, _resetClaim} from "../../store/claims/service";
import useConfig from "../qnect-ui/qnect-config";
import LogoutDialog from "./logout-dialog";
import QbeLogo from '../../assets/QBE-logo-on-light.svg';
import LogoutIcon from '../../assets/Logout.svg';
import BlankImage from "../../assets/profile/avatar_image_placeholder.svg";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import {_aboutLink, tagManagerClickEvent, tagManagerPopup} from "../../utils/utility";
import { _resetInvoice } from "../../store/invoice/service";
import OutageBanner from "../outage-banner";

const StyledMenu = withStyles({
  paper: {
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    width: "160px",
    marginTop: "10px",
    "& .MuiList-padding": {
      padding: 0
    }
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    height: "64px",
    borderRadius: "10px",
    "&:focus": {
      backgroundColor: "#fff"
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            paddingRight: "12px",
            height: "44px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            paddingRight: "24px",
            height: "72px"
        },
        [theme.breakpoints.down("md")]: {
            backgroundColor: "#fff",
            borderBottom: "1px solid #e1e4e8"
        },
        [theme.breakpoints.up("lg")]: {
            height: "72px"
        }
    },
    back_wrapper: {
        cursor: "pointer",
        zIndex: 3,
        [theme.breakpoints.down("sm")]: {
            width: "51px",
            height: "42px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: "76px",
            height: "72px"
        },
        [theme.breakpoints.down("md")]: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        [theme.breakpoints.up("lg")]: {}
    },
    back_icon: {
        [theme.breakpoints.down("sm")]: {
            width: "24px",
            height: "24px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: "36px",
            height: "36px"
        }
    },
    title: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            height: "43px",
            lineHeight: "43px",
            fontSize: "18px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            height: "71px",
            lineHeight: "71px",
            fontSize: "24px"
        },
        [theme.breakpoints.down("md")]: {
            color: "#003da5",
            fontFamily: "Stag",
            fontWeight: "500",
            letterSpacing: "1px"
        },
        [theme.breakpoints.up("lg")]: {}
    },
    right_part: {
        display: "flex",
        alignItems: "center",
        zIndex: 3
    },
    language_wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        color: "#1D4FBB",
        fontWeight: "bold",
        fontSize: "20px",
        [theme.breakpoints.down("sm")]: {
            height: "24px",
            width: "24px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            height: "32px",
            width: "32px"
        }
    },
    profile: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "16px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginLeft: "30px"
        },
        [theme.breakpoints.up("lg")]: {
            marginLeft: "40px"
        }
    },
    avatar: {
        display: "block",
        borderRadius: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "24px",
            height: "24px"
        },
        [theme.breakpoints.up("sm")]: {
            width: "40px",
            height: "40px"
        }
    },
  name: {
    color: "white",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "6px",
      marginRight: "6px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "50px"
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "12px",
      marginRight: "12px",
      fontSize: "16px",
      letterSpacing: "0",
      lineHeight: "24px"
    }
  },
  logo: {
    display: "flex",
    cursor: "pointer"
  },

  header_container: {
    backgroundColor: '#191919',
    paddingLeft: '20px',
    minHeight: '60px',
    display: 'flex',
    position: 'relative',
  },
  top_left_container: {
    display: 'flex',
    flex: '0 0 50%',
  },
  top_right_container: {
    display: 'flex',
    flex: '0 0 50%',
    justifyContent: 'flex-end',
  },
  logo_container: {
    display: 'flex',
    alignItems: 'center',
    '& a' : {
      height: '27px',
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: '45px',
      paddingLeft: '20px',
      borderLeft: '1px solid rgba(255,255,255,0.2)',
    },
  },
  nav_container: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down("md")]: {
      '& nav' : {

        position: 'absolute',
        top: '59px',
        left: '0',
        background: 'teal',
        width: '100%',
      },
      '& label.nav-toggle-label' : {
        position: 'absolute',
        top: '21px',
        left: '20px',
        zIndex: '9',
        '& span' : {
          display: 'block',
          background: 'white',
          height: '3px',
          width: '1.5em',
          position: 'relative',
          marginBottom: '5px',
        },
      },
    },
    '& ul' : {
      listStyle: 'none',
      paddingLeft: '0',
      margin: '0',
      '& li' : {
        textDecoration: 'none',
        display: 'inline',
        color: '#fff',
        marginLeft: '35px',
        [theme.breakpoints.down("md")]: {
          display: 'block',
          marginLeft: '20px',
          marginTop: '10px',
          marginBottom: '12px',
          color: '#0A1F44',
          fontFamily: 'Inter Semibold, Inter Semi-bold, Inter Semi Bold, Inter, sans-serif'
        },
      },
      '& li:hover' : {
        paddingBottom: '15px',
        borderBottom: '4px solid #28B5F5',
      }
    }
  },
  right_side_functions_container: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& div' : {
      minWidth: '60px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cursor: 'pointer'
  },

  li: {
    cursor: 'pointer'
  },

  navDiv : {
      cursor: 'pointer'
  }
}));

const Header = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const cfg = useConfig(props.lang);

  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutOpen, setLogoutOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    tagManagerClickEvent('Icon-click', 'logout');
    tagManagerPopup('Logout');
    setLogoutOpen(true);
  };

  const logout = () => {
    setLogoutOpen(false);
    props.logout();
    props.clearToken();
    props.resetClaim();
    props.resetUser();
    props.resetUserList();
    props.resetInvoice();
    clearTimeout(props.timerId);
    history.push("/login");
  }

    const handleReload = () => {
        history.push('/reloadEvents');
    }

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

  return (
    <> 
      <div className={classes.header_container}>
        <div className={classes.top_left_container}>
          <div className={classes.logo_container}>
              <a onClick={() => {
                  window.open(cfg(_aboutLink(props.country)), '_blank');
              }}>
              <img src={QbeLogo} width="94" height="27" alt="QBE"/>
             </a>
          </div>
        </div>
        {props.portfolioToken &&
           <div className={classes.top_right_container}>
            <div className={classes.right_side_functions_container}>
              {props.user?.userType === 'SU' &&
                <div className={classes.profile} onClick={handleClick}>
                  <img className={classes.avatar} src={BlankImage} alt="avatar" />
                  <Hidden mdDown>
                      <div className={classes.name}>{props.user?.name}</div>
                  </Hidden>
              </div>
              }
              {props.user?.userType !== 'SU' &&
                <div>
                    <img src={LogoutIcon} height="24" alt="" onClick={() => handleLogout()}/>
                </div>
              }
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem onClick={() => handleReload()}>
                  <ListItemText primary="Reload Events" />
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleLogout()}>
                  <ListItemText primary="Log Out" />
                </StyledMenuItem>
              </StyledMenu>
            </div>
           </div>
        }
      </div>
        { props.country !== "" &&
           <OutageBanner />
        }

        <LogoutDialog
        open={logoutOpen}
        onClose={() => setLogoutOpen(false)}
        onLogout={() => logout()}
      />

    </>
  );
};

const mapStateToProps = (state) => {
    return {
        portfolioToken: _getPortfolioToken(state),
        country: _getCountry(state),
        user: _getUser(state),
        timerId: _getTimerId(state)
    }
}

const mapDispatchToProps = {
    clearToken: _clearToken,
    resetUser: _resetUser,
    resetUserList: _resetUserList,
    storeClaim: _storeClaim,
    resetClaim: _resetClaim,
    resetInvoice: _resetInvoice,
    logout: _logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
