import React, {useEffect, useState} from "react";
import {_checkSystemStatus, _getCountry,
    _getLang
} from "../store/system/service";
import {connect} from "react-redux";
import useConfig from "./qnect-ui/qnect-config";
import {ErrorOutline} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core";
import withRouter from "react-router-dom/es/withRouter";

const useStyles = makeStyles(theme => ({

    TopErrorContainer : {
        boxSizing: 'border-box',
        height: '43px',
        width: '100%',
        border: '1px solid #A50000',
        backgroundColor: 'rgba(165,0,0,0.1)',
        display: 'flex',

        '& span.ErrorText' : {
            height: '18px',
            color: '#191919',
            fontFamily: 'Inter',
            fontSize: '12px',
            letterSpacing: '0',
            lineHeight: '18px',
            marginTop: '12px'
        },

        '& svg.ErrorImg' : {
            height: '20px',
            marginTop: '10px',
            color: '#A50000'
        }
    }
}));

const OutageBanner = (props) => {
    const cfg = useConfig(props.lang);
    const classes = useStyles();
    const [isSystemAvailable, setIsSystemAvailable] = useState(true);

    useEffect(() => {
        props.checkSystemStatus().then((response) => {
            setIsSystemAvailable(response);
        }).catch((error) => {
            console.log('checkSystemAvailable - error occurred', error);
        });
    }, []);

    return (
        <>
            { !isSystemAvailable &&
               <div className={classes.TopErrorContainer}>
                   <ErrorOutline className="ErrorImg" alt="" fontSize='large'/>
                   <span className="ErrorText">{cfg("errorMessage.unavailable")}</span>
               </div>
            }
        </>
    );

};

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        country: _getCountry(state)
    }
};

const mapDispatchToProps = {
    checkSystemStatus: _checkSystemStatus
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OutageBanner));

