import {SET_CLAIM, RESET_CLAIM} from "./types";
import {updateObject} from "../../utils/utility";

const INITIAL_STATE = {
    productType: '',
    productDescription: '',
    claimNumber: '',
    qnectStatus: '',
    clientName: '',
    dateOfLoss: '',
    lastUpdate: '',
    token: ''
};

const updateClaimState = (state, action) => {
    return updateObject(
        state,
        action.data
    )
}

const resetClaimState = () => {
    INITIAL_STATE.productType = '';
    INITIAL_STATE.productDescription = '';
    INITIAL_STATE.claimNumber = '';
    INITIAL_STATE.qnectStatus = '';
    INITIAL_STATE.clientName = '';
    INITIAL_STATE.dateOfLoss = '';
    INITIAL_STATE.lastUpdate = '';
    INITIAL_STATE.token = '';

    return INITIAL_STATE;
}

export const claimReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CLAIM:
            return updateClaimState(state, action);
        case RESET_CLAIM:
            return resetClaimState();
        default:
            return state;
    }
}


