import React, {useEffect} from 'react';
import {Route, useLocation} from 'react-router-dom';
import {createBrowserHistory} from 'history';

import {connect} from "react-redux";
import LoginPage from "../pages/login-page";
import ClaimsPage from "../pages/claims-page";
import ClaimsDetailsPage from "../pages/claims-details-page";
import AdminPage from "../pages/admin-page";
import {_getCountry, _getLang, _getPortfolioToken, _setCountry, _setLang} from "../store/system/service";
import SecureRoute from "../utils/secure-route";
import BrokenPage from "../pages/broken-page";
import NavigationPage from "../pages/navigation-page";
import ResetPasswordPage from "../pages/reset-password-page";
import RegistrationPage from "../pages/registration-page";
import ReloadEventsPage from "../pages/reload-events-page";
import ViewDocumentsPage from '../pages/view-documents-page';
import UploadDocumentsPage from '../pages/upload-documents-page';
import ViewInvoicePage from '../pages/view-invoice-page';
import {_getUser} from "../store/user/service";
import * as TagManager from "react-gtm-module";
import {_scrollToTop, getActualPagePath} from "../utils/utility";
import ViewInvoiceDetailsPage from '../pages/view-invoice-details-page';
import SubmitInvoicePage from '../pages/submit-invoice-page';
import ResetCyberarkPasswordPage from '../pages/reset-cyberark-password-page';

let history;

export function getHistory() {
    if (!history) {
        history = createBrowserHistory();
    }

    return history;
}

const Routes = (props) => {

    const location = useLocation();

    useEffect(() => {
        if (!props.country) {
            setTimeout(() => initializeCountry());
        }
        _scrollToTop();
        tagManagerPageView();
    }, [location]);

    const initializeCountry = () => {
        props.setCountry(getCountry());
        props.setLang('en');
    };

    const getCountry = () => {
        let url = window.location.href;
        if (url) {
            url = url.toLowerCase();
            if (url.indexOf("/sg") !== -1 || url.indexOf("sg-") !== -1 || url.indexOf("#sg") !== -1) {
                return 'SGP';
            } else if (url.indexOf("/my") !== -1 || url.indexOf("my-") !== -1 || url.indexOf("#my") !== -1) {
                return 'MYS';
            } else {
                return 'HKG';
            }
        }
    };

    const hasPortfolioAccessToken = () => {
        let status = false;
        if (props.portfolioToken){
            status = true;
        }

        return status;
    }

    const tagManagerPageView = () => {
        TagManager.dataLayer({
                dataLayer: {
                    event: 'vpageview',
                    vPath: getActualPagePath(),
                    userCountry: getUserCountry(),
                    userEntity: getUserEntity(),
                    userId: props.user?.user,
                    userRole: getUserRole()
                }
        });
    };

    const getUserCountry = () => {
        if (props.country) {
          return props.country.substring(0, 2);
        } else {
            return getCountry().substring(0,2);
        }
    };

    const getUserRole = () => {
        let userType = props.user.userType;
        if (userType) {
           if (userType === 'SU') {
               return 'Super Admin';
           } else if (userType === 'ADM') {
               return 'Admin';
           }
        } else if (props.user?.providerEntity) {
            return 'Supplier';
        }
    };

    const getUserEntity = () => {
        if (props.user?.provider) {
            return props.user.provider;
        }
    };

    return (
        <>
            <Route path='/' exact component={LoginPage} />
            <Route path='/login' exact component={LoginPage} />
            <SecureRoute path='/claims' component={ClaimsPage} auth={hasPortfolioAccessToken()}/>
            <SecureRoute path='/claimsDetails' component={ClaimsDetailsPage} auth={hasPortfolioAccessToken()}/>
            <SecureRoute path='/adminDetails' component={AdminPage} auth={hasPortfolioAccessToken()}/>
            <SecureRoute path='/reloadEvents' exact component={ReloadEventsPage} auth={hasPortfolioAccessToken()}/>
            <SecureRoute path='/viewDocuments' component={ViewDocumentsPage} auth={hasPortfolioAccessToken()}/>
            <SecureRoute path='/uploadDocuments' component={UploadDocumentsPage} auth={hasPortfolioAccessToken()}/>
            <SecureRoute path='/viewInvoice' component={ViewInvoicePage} auth={hasPortfolioAccessToken()}/>
            <SecureRoute path='/invoice/:method' component={SubmitInvoicePage} auth={hasPortfolioAccessToken()}/>
            <SecureRoute path='/viewInvoiceDetails' component={ViewInvoiceDetailsPage} auth={hasPortfolioAccessToken()}/>
            <Route path='/resetCyberarkPassword' exact component={ResetCyberarkPasswordPage}/>
            <Route path='/pageUnavailable' exact component={BrokenPage}/>
            <Route path='/resetPassword' exact component={ResetPasswordPage}/>
            <Route path='/navigate' exact component={NavigationPage} />
            <Route path='/register' exact component={RegistrationPage} />
        </>
    );
}


const mapStateToProps = (state) => {
  return {
    country: _getCountry(state),
    lang: _getLang(state),
    portfolioToken: _getPortfolioToken(state),
    user: _getUser(state)
  }
}

const mapDispatchToProps = {
    setCountry: _setCountry,
    setLang: _setLang
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
