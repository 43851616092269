import React, {memo} from "react";
import FieldWrapper from "../custom/field-wrapper";
import QnectTextField from "../qnect-ui/qnect-text-field";
import {CheckBoxWrapper, StyledCheckBoxContent, useDialogStyles} from "../custom/dialog-style";
import QnectCheckBox from "../qnect-ui/qnect-check-box";
import QnectSelectField from "../qnect-ui/qnect-select-field";

const UserDetails = ({rowData, isAdmin=false, country, cfg}) => {

    const classes = useDialogStyles();

    return (
                    <div className={classes.modal_box_form_container}>
                        <div className='modal_box_form_field input_name'>
                            <FieldWrapper>
                                <QnectTextField
                                    field="nameUser"
                                    value={rowData?.name}
                                    disabled={true}
                                />
                            </FieldWrapper>
                        </div>
                        <div className='modal_box_form_field input_name'>
                            <FieldWrapper >
                                <QnectTextField
                                    field="emailUser"
                                    value={rowData?.email}
                                    disabled={true}
                                />
                            </FieldWrapper>
                        </div>
                        <div className='modal_box_form_field input_name'>
                            <FieldWrapper >
                                <QnectTextField
                                    field="mobileNoUser"
                                    value={rowData?.mobileNo}
                                    disabled={true}
                                />
                            </FieldWrapper>
                        </div>
                        {isAdmin === false &&
                        <>
                            <div className='modal_box_form_field input_name'>
                                <FieldWrapper >
                                    <QnectTextField
                                        field="providerName"
                                        label="Provider Name"
                                        value={rowData?.providerName}
                                        disabled={true}
                                    />
                                </FieldWrapper>
                            </div>
                            {country === 'HKG' &&
                            <>
                                <div className={classes.selectEntityLabel}>{cfg("entity.label")}</div>
                                <div className='modal_box_form_field input_name'>
                                    <CheckBoxWrapper>
                                        <QnectCheckBox
                                            checked={rowData?.providerHKG}
                                            disabled={true}>
                                            <StyledCheckBoxContent>{'HKG'}</StyledCheckBoxContent>
                                        </QnectCheckBox>
                                    </CheckBoxWrapper>
                                    <CheckBoxWrapper>
                                        <QnectCheckBox
                                            checked={rowData?.providerHGI}
                                            disabled={true}>
                                            <StyledCheckBoxContent>{'HGI'}</StyledCheckBoxContent>
                                        </QnectCheckBox>
                                    </CheckBoxWrapper>
                                </div>
                            </>}
                        </>}

                        {isAdmin === true &&
                        <div className='modal_box_form_field input_name'>
                            <FieldWrapper >
                                <QnectSelectField
                                    field="userType"
                                    value={rowData?.userType}
                                    disabled={true}/>
                            </FieldWrapper>
                        </div>}
                    </div>
    );

};

export default memo(UserDetails);