import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    marginTop: "24px"
  }
}));

const FieldWrapper = ({ children, className }) => {
  const classes = useStyles();

  return <div className={[classes.root, className].join(" ")}>{children}</div>;
};

FieldWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default FieldWrapper;
