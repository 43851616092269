import React, {useEffect, useState} from "react";
import { _getClaim, _getClaimDocs, _loadDocument, _resetClaim} from "../../store/claims/service";
import {
    _getLang,
    _hideSpinner,
    _showSpinner,
    _resetClaimsToken, _resetDocumentToken, _getCountry, _setDocumentToken
} from "../../store/system/service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import useConfig from "../qnect-ui/qnect-config";
import Hidden from "@material-ui/core/Hidden";
import QnectError from "../qnect-ui/qnect-error";
import useInvoiceStyles, { BackIcon, BackWrapper, Container, Title, TopWrapper } from "./invoice-style";
import {base64ToArrayBuffer, _getCurrency, _formatAmount} from "../../utils/utility";
import { _getDocument } from "../../store/documents/service";
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from "@mui/material";
import QnectButton from "../qnect-ui/qnect-button";
import { _getInvoice, _resetInvoice } from "../../store/invoice/service";
import FieldWrapper from "../custom/field-wrapper";
import {saveAs} from "file-saver";

export const ViewInvoiceDetails = (props) => {
    const cfg = useConfig(props.lang);
    const classes = useInvoiceStyles(props);
    const [errorMessage, setErrorMessage] = useState("");
    const currency = _getCurrency(props.country);
    const [docs, setDocs] = useState([]);

    useEffect(() => {
      setErrorMessage([]);
      loadDocuments();
    }, []);

    const loadDocuments = () => {
      props.showSpinner();
      props.getClaimDocs('U', props.claim.productType, props.invoice.invoiceNumber).then((response) => {
          if (response.status === 'success') {
              if (response.documents) {
                  setDocs(response.documents);
                  props.setDocumentToken(response.token);
              } else {
                  setDocs([]);
                  props.setDocumentToken('');
              }
          }
          props.hideSpinner();
      }).catch(error => {
          console.log('getClaimDocs - error occurred', error);
          props.hideSpinner();
      });
    }

    const downloadMultipleDocs = async () => {
      if (docs.length !== 0) {
        docs.forEach((rowData) => {
            saveAsFile(rowData);
        });
      }
    }

    const saveAsFile = (rowData) => {
      const ddocName = rowData.dDocName;
      const fileName = rowData.docFileName;

      props.showSpinner();
      props.loadDocument(ddocName).then((doc) => {
          if (doc) {
              const docData = base64ToArrayBuffer(doc.documentBytes);
              const blob = new Blob([docData], {type: 'application/pdf'});
              saveAs(blob, fileName);
          }
          props.hideSpinner();
      }).catch(error => {
          props.hideSpinner();
          props.showError(['Download Error', error.message || error]);
      });
    };

    const getText = (htmlString) => {
        const divContainer = document.createElement("div");
        divContainer.innerHTML = htmlString;
        return divContainer.textContent || divContainer.innerText || "";
    };

    return (
      <>
        <Container>
          <div className={classes.box}>
            <TopWrapper>
              <Hidden mdDown>
                <BackWrapper onClick={() => props.history.push("/viewInvoice")}>
                  <BackIcon/>{cfg('backBtn.label')}
                </BackWrapper>
                <Title>{cfg('invoice.details.title')} {(props.invoice.invoiceNumber)}</Title>
              </Hidden>
            </TopWrapper>
          </div>
        </Container> 

        <div style={{overflowY: "auto !important"}}>
          <div className={classes.invoice_details_rectangle}>
            <div style={{display: "flex"}}>
              <div className={classes.invoice_number_label}>
                  <label>{cfg('invoice.details.invoiceNumber')}: {props.invoice.invoiceNumber}</label>
              </div>
              <div className={classes.invoice_status_box}>
                <label>{props.invoice.status}</label>
              </div>
            </div>
            <div className={classes.invoice_line}/>

            <div>
              <div className={classes.data_label}>
                <label>{cfg('claimDetails.status')}</label>
              </div>
              <div className={classes.data_content}>
                <label>{cfg(props.invoice.statusLong)}</label>
              </div>
            </div>

            { props.invoice.rejectionReason &&
              <>
                <div className={classes.data_label}>
                  <label>{cfg('invoice.details.reason')}</label>
                </div>
                <div className={classes.data_content}>
                  <label>{props.invoice.rejectionReason}</label>
                </div>
              </>
           }
            <div className={classes.data_label}>
              <label>{cfg('claimDetails.claimNumber')}</label>
            </div>
            <div className={classes.data_content}>
              <label>{props.claim.claimNumber}</label>
            </div>

            <div className={classes.data_label}>
                <label>{cfg('invoice.details.providerInvoiceNo')}</label>
            </div>
            <div className={classes.data_content}>
                <label>{props.invoice.providerInvoiceNumber}</label>
            </div>

            <div className={classes.data_label}>
                <label>{cfg('invoice.details.calculationType')}</label>
            </div>
            <div className={classes.data_content}>
                <label>{props.invoice.type}</label>
            </div>

            <div className={classes.data_label}>
                <label>{cfg('invoice.details.dateSubmitted')}</label>
            </div>
            <div className={classes.data_content}>
                <label>{props.invoice.dateSubmitted}</label>
            </div>

            <div className={classes.data_label}>
                <label>{cfg('invoice.details.submittedBy')}</label>
             </div>
             <div className={classes.data_content}>
                <label>{props.invoice.submittedBy}</label>
             </div>

             { props.invoice.rejectionComments &&
              <>
                <div className={classes.data_label}>
                   <label>{cfg('invoice.details.rejectionComments')}</label>
                </div>
                <div className={classes.data_content}>
                   <label>{getText(props.invoice.rejectionComments)}</label>
                </div>
              </>
             }

            <div className={classes.invoice_line}/>

            <div className={classes.data_label}>
                <label>{props.country !== 'HKG' ? cfg('invoice.details.invoiceAmount') : cfg('invoice.details.invoiceFee')}</label>
            </div>
            <div className={classes.totalFee_label}>
                <label>{currency} {_formatAmount(props.invoice.totalFee, true)}</label>
            </div>
          </div>

          <div className={classes.label_box}>
            <div className={classes.invoicedetails_doc_label}>
              <label>{cfg('invoice.details.invoiceDocs')}</label>
            </div>
            <div className={classes.download_all}>
              <Button variant="text" startIcon={<DownloadIcon/>} onClick={downloadMultipleDocs} disabled={docs.length === 0}>{cfg('invoice.details.downloadAll')}</Button>
            </div>
          </div>

          <div className={classes.invoicedetails_doc_rectangle}>
            <div style={{display: "flex"}}>
              <div className={classes.invoicedetails_doc_column} style={{flex: '83%'}}>
                  <label>NAME</label>
              </div>
              <div className={classes.invoicedetails_doc_column} style={{flex: '17%'}}>
                <label>ACTIONS</label>
              </div>
            </div>

            <div className={classes.invoice_line}/>

            {docs && docs.map((item, index) => (
                <div key={"invoiceDocsDiv"+ index} style={{display: "flex", borderBottom: "3px solid #F1F2F4", paddingBottom: "10px"}}>
                  <div className={classes.invoicedetails_doc_content}>
                      <label>{item.docFileName}</label>
                  </div>
                  <div className={classes.invoicedetails_doc_button}>
                      <QnectButton
                          field="downloadDoc"
                          onClick={() => saveAsFile(item)}
                      />
                  </div>
                </div>
                )
            )}
          </div>

          {errorMessage && (
              <div className={classes.errorMessage}>
                  <QnectError errorTitle={"Error"} errorMessage={errorMessage} />
              </div>
          )}

          <div className={classes.group_button}>
              <FieldWrapper>
                <QnectButton
                    field="backBtn"
                    onClick={() => {
                      props.resetInvoice();
                      props.resetDocumentToken();
                      props.history.push("/viewInvoice")
                    }}
                />
              </FieldWrapper>
              <br/><br/><br/>
          </div>
        </div>
      </>
    );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        lang: _getLang(state),
        claim: _getClaim(state),
        document: _getDocument(state),
        invoice: _getInvoice(state),
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    resetClaim: _resetClaim,
    resetClaimsToken: _resetClaimsToken,
    resetDocumentToken: _resetDocumentToken,
    resetInvoice: _resetInvoice,
    getClaimDocs: _getClaimDocs,
    setDocumentToken: _setDocumentToken,
    loadDocument: _loadDocument
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewInvoiceDetails));
