import axios from 'axios';
import moment from 'moment';
import {qnectFormValidator} from "../components/qnect-ui/qnect-validator";
import {v4 as uuid} from 'uuid';
import * as TagManager from "react-gtm-module";


export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
}

export function _formatNumber(value) {
    return value.replace(/\D/g, "");
}

export function _formatAmount(amount, isFormatted) {
    if (amount) {
        if (isFormatted) {
            // format amount with 2 decimal places
            let result = _valueOf(amount).toFixed(2) || '0.00';
            // format amount with comma
            return _formatDecimalAmount(result) || '0.00';
        } else {
            // format amount without comma
            let result = parseFloat(_removeDecimalFormatting(amount));
            return result.toFixed(2) || '0.00';
        }
    }
    return amount;
}

export function _formatDecimalAmount(amount, locale, prefix, minFractionDigit = 0, maxFractionDigit = 2) {
    // format amount with comma
    let value = _removeDecimalFormatting(amount);
    
    if(_isNotDecimalChar(value)){
        return "";
    }

    let _prefix = prefix;
    let leftValue = value;
    let rightValue = "";
    if(value.indexOf('.') > -1){
        leftValue = value.substring(value.indexOf('.'), 0);
        rightValue = value.substring(value.indexOf('.'));
    }

    return (_prefix || "") + Intl.NumberFormat(locale || "en", {
        maximumFractionDigits: maxFractionDigit,
        minimumFractionDigits: minFractionDigit
    }).format(leftValue).concat(rightValue);
}

export function _valueOf(formattedAmount) {
    let value = _removeFormatting(formattedAmount);

    if (_isNotDecimalChar(value)) {
        return NaN;
    } else {
        return Number(value);
    }
}

export function _removeFormatting(value) {
    if (!!value) {
        return "".concat(value).replace(/[^0-9.]/g, "");
    } else {
        return "";
    }
}

export function _removeDecimalFormatting(value) {
    // format amount without comma, and restrict the input to 2 decimal places
    if (!!value) {
        value = "".concat(value).replace(/[^0-9.]/g, "");
        value = value.replace(/\.{2,}/g,"."); //Only keep the first one, clear the extra
        value = value.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
        value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); //can only enter two decimals 
        return value;
    } else {
        return "";
    }
}

export function _isNotDecimalChar(value) {
    if (!value || isNaN(value)) {
        return true;
    } else {
        return false;
    }
}

let scrollTimer = null;
export function _scrollToComponent(ref) {
    const isIE = /*@cc_on!@*/!!document.documentMode;

    // prevent multiple call to scroll.
    if(!scrollTimer){
        scrollTimer = setTimeout(function(){
            if(isIE){
                window.scrollTo(0, ref?.current?.offsetTop);
            } else {
                window.scrollTo({top: ref?.current?.offsetTop, behavior: 'smooth'});
            }
            scrollTimer = null;
        }, 500);
    }
}

export function _scrollTo(y) {

    // prevent multiple call to scroll.
    if(!scrollTimer){
        scrollTimer = setTimeout(function(){
            window.scrollTo(0, y);

            scrollTimer = null;
        }, 500);
    }
}


export function _scrollToTop() {
    const isIE = /*@cc_on!@*/!!document.documentMode;

    // prevent multiple call to scroll.
    if(!scrollTimer){
        if(isIE){
            window.scrollTo(0, 0);
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
        scrollTimer = null;
    }
}


export async function _sendLog(message, type) {
    return new Promise(function (resolve, reject) {
        if (process.env.REACT_APP_SEND_LOG && message) {
            let data = ''
            if (Array.isArray(message)) {
                data = message.join(', ')
            } else {
                data = message;
            }
            axios.post(process.env.REACT_APP_SEND_LOG + (!type ? 'error' : type), {'data': data},
                {headers: {}, shouldIntercept: 'false'})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(error => {
                    resolve();
                });
        }
    })
}

export const onChangeExpiryDateHandler = (event, setExpiryDateMethod) => {
    let newExpiryDate = event.target.value.trim();
    if (newExpiryDate) {
        if (newExpiryDate.length === 4 && newExpiryDate.indexOf("-") === -1) {
            newExpiryDate = newExpiryDate.substring(0, 2) + '-' + newExpiryDate.substring(2);
        }
    }

    setExpiryDateMethod(newExpiryDate);
};


export const _validateForm = (formId) => {
    let valid = true;

    if (qnectFormValidator.checkForm(formId).isValid !== true) {
        valid = false;
    }

    return valid;
}

export const _refreshFormField = (formId, field) => {
    qnectFormValidator.refreshItem(formId, field);
}

export const customReplaceAll = (regex, stringToBeChanged, changeToThisCharacter) => {
    let matchedString = stringToBeChanged.match(regex);
    if (matchedString[0]) {
        let changedString = matchedString[0].replaceAll(/./g, changeToThisCharacter);
        return stringToBeChanged.replaceAll(regex, changedString);
    }
}

export const _getCurrency = (country) => {
    switch (country) {
        case "HKG":
            return "HKD";
        case "SGP":
            return "SGD";
        case "MYS":
            return "MYR";
        default:
            return "";
    }
}

export const _formatDate = (date, format = 'DD/MM/YYYY') => {
    let dateString = moment(date).format(format);
    return dateString === 'Invalid date' ? '' : dateString;
}

export const _masked = (val) => {
    if (val) {
        let mask_char = '•';
        let mask_no = 4;
        if (val.length <= mask_no) {
            return val;
        }
        else {
            return ('' + val).slice(0, 0 - mask_no).replace(/./g, mask_char)
                + ('' + val).slice(0 - mask_no);
        }
    }
    else {
        return val;
    }
}

export const _validateNric = (nricString) => {
    if (!nricString || nricString === '') {
        return false;
    }

    if (nricString.length !== 9) {
        return false;
    }

    let total;
    let numericNric;
    const first = nricString[0];
    const last = nricString[nricString.length - 1];

    if (first !== 'S' && first !== 'T') {
        return false;
    }

    numericNric = nricString.substr(1, nricString.length - 2);

    if (isNaN(numericNric)) {
        return false
    }

    total = modNric(numericNric);

    let outputs;
    if (first === 'S') {
        outputs = [ 'J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A' ];
    }
    else {
        outputs = [ 'G', 'F', 'E', 'D', 'C', 'B', 'A', 'J', 'Z', 'I', 'H' ];
    }

    return last === outputs[total % 11];
}

function modNric(numericNric) {
    const multiples = [ 2, 7, 6, 5, 4, 3, 2 ];

    let count = 0, total = 0;
    while (numericNric !== 0) {
        total += (numericNric % 10) * multiples[multiples.length - (1 + count++)];
        numericNric /= 10;
        numericNric = Math.floor(numericNric);
    }
    return total;
}

export const _generateUuid = () => {
    return uuid();
}

export const _convertStringDateToMomentObject = (dateString, format = 'DD/MM/YYYY') => {
    if (typeof dateString === 'string' || dateString instanceof String){
        return moment(dateString, format);
    }
    return dateString;
}

export const _termsOfUseLink = (country) => {
    return 'links.termsOfUse_' + country;
}

export const _privacyPolicyLink = (country) => {
    return 'links.privacyPolicy_' + country;
}

export const _aboutLink = (country) => {
    return 'links.aboutQbe_' + country?.toLowerCase();
}

export const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const data = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        data[i] = binaryString.charCodeAt(i);
    }
    return data;
};

export const createUniqueIdentifier = () => {
    return 'RAND-' + _generateUuid().substring(0, 18);
}

export const getActualPagePath = () => {
    let pagePath = window.location.href.substring(window.location.origin.length);
    // exclude parameters
    if (pagePath.indexOf('?') !== -1) {
        return pagePath.split('?')[0];
    }
    return pagePath;
}

export const tagManagerPopup = (popupTitle, claimNumber, fileCount) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'popup',
            popupPath: getActualPagePath(),
            popupTitle: popupTitle,
            claimNumber: claimNumber,
            file: fileCount
        }
    });
}

export const tagManagerClickEvent = (clickEvent, category, claimNumber) => {
    TagManager.dataLayer({
        dataLayer: {
            event: clickEvent,
            eventCategory: category,
            claimNumber: claimNumber
        }
    });
}

export const formatDate = (date, format = 'DD/MM/YYYY hh:mm A') => {
    let dateString = moment(date).format(format);
    return dateString === 'Invalid date' ? '' : dateString;
}

export const sortDateFunction = (val1, val2, fieldName) => {
    let date1 = moment(val1[fieldName],'DD/MM/YYYY hh:mm A').toDate();
    let date2 = moment(val2[fieldName],'DD/MM/YYYY hh:mm A').toDate();
    return (date2 - date1);
}

