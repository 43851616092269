import React from 'react';
import AdminDetail from "../components/admin/admin-detail";
import Header from "../components/custom/header";
import SessionTimeout from "../components/session-timeout";

const AdminPage = () => {

    return (

        <div>
            <Header />
            <AdminDetail />
            <SessionTimeout />
        </div>
    )
}

export default (AdminPage);
