import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    _getCountry,
    _hideModal,
    _hideSpinner,
    _showError,
    _showModal,
    _showSpinner
} from "../../../store/system/service";
import useConfig from "../../qnect-ui/qnect-config";
import {_formatAmount} from "../../../utils/utility";
import {StyledModalDiv} from "../invoice-style";
import QnectButton from "../../qnect-ui/qnect-button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {_submitInvoice} from "../../../store/invoice/service";

const SubmitInvoiceDialog = ({invoiceForm, currency, onSubmit,...props}) => {
    const cfg = useConfig(props.lang);

    const ConfirmInvoiceModal = () => {
        props.showModal({
            flat: true,
            content: <StyledModalDiv>
                     <div className="title">{cfg('invoice.confirmInvoiceAmount')}</div>
                     <div className="contents">{cfg('invoice.confirmInvoiceAmountDesc')} <strong>{currency} {_formatAmount(invoiceForm.totalInvoiceAmount, true)}</strong>?</div>
                     <div className="buttonsDiv">
                       <div style={{float: 'left'}}>
                         <QnectButton field="No" onClick={props.hideModal} />
                      </div>
                      <div style={{float: 'right'}}>
                        <QnectButton field="Yes" onClick={submitInvoice}/>
                        </div>
                    </div>
                     </StyledModalDiv>
            });
        onSubmit(false);
        return null;
    };

    const submitInvoice = () => {
        props.showSpinner();
        props.submitInvoice(invoiceForm).then(resp => {
            props.hideSpinner();
            if (resp?.data === 'success') {
                showModalSuccess(cfg('invoice.invoiceSubmission'), cfg('invoice.submitInvoiceSuccessful'));
            } else if (resp?.data === 'timeout') {
                props.hideModal();
                showModalSuccess(cfg('invoice.invoiceSubmission'), cfg('invoice.submitInvoiceTimeout'));
            } else {
                props.hideModal();
                props.showError([cfg('invoice.submitInvoiceFailure')]);
            }
        }).catch(error => {
            props.hideSpinner();
            props.showError([cfg('errorMessage.internalServerError'), error.message || error]);
        });
    };

    const showModalSuccess = (title, msg) => {
        props.showModal({
            flat: true,
            content: <StyledModalDiv>
                <div className="iconDivSuccess"><CheckCircleIcon fontSize='large'/></div>
                <div className="title">{title}</div>
                <div className="subtitle">{msg}</div>
                <div className="buttonDiv">
                    <QnectButton
                        field="close"
                        onClick={() => {
                            props.hideModal();
                            props.history.push("/viewInvoice");
                        }}
                    />
                </div>
            </StyledModalDiv>
        });
    };

    return (
        <ConfirmInvoiceModal />
    );
    
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state)
    }
};

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showModal: _showModal,
    hideModal: _hideModal,
    showError: _showError,
    submitInvoice: _submitInvoice
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubmitInvoiceDialog));
///export default memo(connect(mapStateToProps, mapDispatchToProps)(SubmitInvoiceDialog));
