import {combineReducers} from 'redux';
import {systemReducer} from "./system/reducers";
import {claimReducer} from "./claims/reducers";
import {userReducer} from "./user/reducers";
import {documentReducer} from "./documents/reducers";
import {invoiceReducer} from "./invoice/reducers";

const reducer = combineReducers({
    sys: systemReducer,  // state in systemReducer will be visible through system.xxx
    user: userReducer,
    claim: claimReducer,
    document: documentReducer,
    invoice: invoiceReducer
    
});

export default reducer;
