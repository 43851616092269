import config from "../../config/config"

const useConfig = (lang) => {
    const language = lang ? lang : 'en';

    const cfg = (field, item) => {
        if (field) {
            if (item) {
                let path = field;
                let key = item;
                let pathItems = path.split(".");
                let configItem = config;
                pathItems.map(item => (
                    configItem = configItem ? configItem[item] : configItem
                ));
                if (configItem && language && language !== 'en'
                    && configItem[key + "_" + language]) {
                    return configItem[key + "_" + language];
                } else {
                    return configItem ? configItem[key] : "";
                }
            }
            else {
                let value;
                if (field.indexOf(".") === -1) {
                    if (language && language !== 'en') {
                        value = config[field + "_" + language];
                    } else {
                        value = config[field];
                    }
                } else {
                    let path = field.substring(0, field.lastIndexOf("."));
                    let key = field.substring(field.lastIndexOf(".") + 1);
                    let pathItems = path.split(".");
                    let configItem = config;
                    pathItems.map(item => (
                        configItem = configItem ? configItem[item] : configItem
                    ));
                    if (configItem && language && language !== 'en'
                        && configItem[key + "_" + language]) {
                        value = configItem[key + "_" + language];
                    } else {
                        value = configItem ? configItem[key] : "";
                    }
                }
                return value ? value : field;
            }
        }

        return "";
    }

    return cfg;
}

export default useConfig;
