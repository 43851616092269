import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    _hideModal,
    _hideSpinner,
    _setCountry,
    _setCpToken,
    _setLang,
    _showModal,
    _showSpinner,
    _verifyOtpAndGetUserDetail
} from "../store/system/service";
import useConfig from "../components/qnect-ui/qnect-config";
import Login from "../components/login/login";
import {_getUser, _storeUser, _verifyEmail} from "../store/user/service";
import ResetOtpDialog from "../components/reset/reset-otp-dialog";
import {customReplaceAll, tagManagerPopup} from "../utils/utility";
import ResetPassword from "../components/reset/reset-password";
import InvalidLink from "../components/custom/invalid-link"


const RegistrationPage = (props) => {
    const params = new URLSearchParams(props.history.location.search);
    const [loading, setLoading] = useState( false );
    const [verifyTokenCalled, setVerifyTokenCalled] = useState(false);
    const [verifyOpen, setVerifyOpen] = useState(false);
    const [maskedEmail, setMaskedEmail] = useState("");
    const [otpValue, setOtpValue] = useState("");
    const [otpErrorMessage, setOtpErrorMessage] = useState("");
    const [isLinkValid, setLinkValid] = useState(true);

    const cfg = useConfig();

    if (!loading) {
        setLoading(true);
    }

    useEffect(() => {
        if (verifyTokenCalled) {
            let token = params.get('token');
            let iv = params.get('iv');

            props.showSpinner();

            props.verifyEmail(token, iv).then((response) => {
                if (response.status === 'SUCCESS') {
                    let emailAddress = response.email;
                    props.user.email = emailAddress;
                    props.user.userType = response.userType;
                    props.storeUser(props.user);
                    props.setCpToken(response.token);
                    props.setCountry(response.country);

                    let val = emailAddress.split('@')[0];
                    let regexToUse = val.length > 4 ? /^.*(?=....@)/g : /^.*(?=@)/g;
                    let emailMasked = customReplaceAll(regexToUse, emailAddress, '•');
                    setMaskedEmail(emailMasked);
                    setVerifyOpen(true);
                    setLinkValid(true);
                    tagManagerPopup('Please Enter the Verification Code Received')

                } else {
                    setLinkValid(false);
                }

                props.hideSpinner();
            }, () => {

                setLinkValid(false);
                props.hideSpinner();
            });

        }
    }, [verifyTokenCalled]);

    const handleVerifyOtp = () => {
        props.showSpinner();
        props.verifyOtpAndGetUserDetail(otpValue, props.user.email, 'register').then((response) => {
            if (response.success) {
                props.setCpToken(response.token);
                setVerifyOpen(false);
                props.hideSpinner();
            } else {
                props.hideSpinner();
                setOtpErrorMessage(cfg("otp.errorMessages.invalidOTP"));
            }
        }, () => {
            props.hideSpinner();
            setOtpErrorMessage(cfg("otp.errorMessages.invalidOTP"));
        })
    }

    if (params.get('token')) {
        if (!verifyTokenCalled) {
            setVerifyTokenCalled(true);
        }

        if (!isLinkValid) {
            return (
                <><InvalidLink action='registration' /></>
            );
        } else {
            return (
                <>
                    <ResetPassword
                        isRegistration={true}/>
                    <ResetOtpDialog
                        open={verifyOpen}
                        maskedEmail={maskedEmail}
                        onClose={() => setVerifyOpen(false)}
                        onConfirm={handleVerifyOtp}
                        value={otpValue}
                        setValue={setOtpValue}
                        errorMessage={otpErrorMessage}
                        setOtpErrorMessage={setOtpErrorMessage}
                        cfg={cfg}
                        lang={props.lang}
                    />
                </>
            );
        }
    } else {
        return (
            <React.Fragment>
                {
                    (!props.match.params.section && <Login/>)
                }
            </React.Fragment>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        user: _getUser(state)
    }
}

const mapDispatchToProps = {
    setLang: _setLang,
    verifyEmail: _verifyEmail,
    verifyOtpAndGetUserDetail: _verifyOtpAndGetUserDetail,
    storeUser: _storeUser,
    setCpToken: _setCpToken,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showModal: _showModal,
    hideModal: _hideModal,
    setCountry: _setCountry
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
