import {SET_INVOICE, RESET_INVOICE} from "./types";
import {updateObject} from "../../utils/utility";

const INITIAL_STATE = {
    invoiceNumber: '',
    type: '',
    providerInvoiceNumber: '',
    totalFee: '',
    status: '',
    statusLong: '',
    dateSubmitted: '',
    submittedBy: '',
    rejectionReason: '',
    rejectionComments: ''
};

const storeInvoiceState = (state, action) => {
    return updateObject(
        state,
        action.data
    )
}

const resetState = (state) => {
    return INITIAL_STATE;
}

export const invoiceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_INVOICE:
            return storeInvoiceState(state, action);
        case RESET_INVOICE:
            return resetState();
        default:
            return state;
    }
}


