import React, {useEffect, useRef, useState} from "react";
import {withRouter, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {_getUser} from "../../store/user/service";
import {
    _getCountry,
    _getLang, _hideModal,
    _hideSpinner, _showError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import {_validateForm, tagManagerPopup} from "../../utils/utility";
import useConfig from "../qnect-ui/qnect-config";
import FieldWrapper from "../custom/field-wrapper";
import QnectButton from "../qnect-ui/qnect-button";
import QnectSelectField from "../qnect-ui/qnect-select-field";
import {_syncData} from "../../store/admin/service";
import {StyledModalDiv} from "../admin/admin-style";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import useReloadStyles from "./reload-events-style";
import {BackIcon, BackWrapper, TopWrapper} from "../claims-details/claims-details-style";
import Hidden from "@material-ui/core/Hidden/Hidden";
import QnectError from "../qnect-ui/qnect-error";


const ReloadEvents = props => {
    const cfg = useConfig(props.lang);
    const history = useHistory();
    const classes = useReloadStyles(props);
    const ref = useRef(null);
    const formId = 'reloadForm';
    const [table, setTable] = useState("");
    const [country, setCountry] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setErrorMessage([]);
        getCountries();
    },[]);

    const getCountries = () => {
        let options = [];
        let obj = {};

        if (props.country === 'HKG') {
            obj['optionLabel'] = 'HKG';
            obj['optionValue'] = 'HKG';
            options.push(obj);

            obj = {};
            obj['optionLabel'] = 'HGI';
            obj['optionValue'] = 'HGI';
            options.push(obj);
        } else {
            obj['optionLabel'] = props.country;
            obj['optionValue'] = props.country;
            options.push(obj);
        }

        return options;
    }

    const reloadEvent = () => {
        if (_validateForm(formId)) {
            props.showSpinner();
            setErrorMessage([]);
            props.syncData(country, table).then((resp) => {
                props.hideSpinner();
                if (resp && resp.status === 'success') {
                    if (table === 'providers') {
                        showModalSuccess('Success', 'Providers have been successfully reloaded for ' + country);
                    } else {
                        showModalSuccess('Success', 'Invoice rater have been successfully reloaded for ' + country);
                    }
                } else {
                    props.showError(['Sync Error']);
                }
            }).catch(error => {
                props.hideSpinner();
                props.showError(['Sync Error', error.message || error]);
            });
        }
    }

    const showModalSuccess = (title, msg) => {
        props.showModal({
            flat: true,
            content: <StyledModalDiv>
                <div className="iconDivSuccess"><CheckCircleIcon fontSize='large'/></div>
                <div className="title">{title}</div>
                <div className="subtitle">{msg}</div>
                <div className="buttonDiv">
                    <QnectButton
                        field="close"
                        onClick={() => {props.hideModal();}}
                    />
                </div>
            </StyledModalDiv>
        });
        tagManagerPopup(title);
    }

    return (
       <div>
           <div className={classes.box}>
               <TopWrapper>
                   <Hidden mdDown>
                       <BackWrapper onClick={() => history.push("/adminDetails")}>
                           <BackIcon/>{cfg('backBtn.label')}
                       </BackWrapper>
                   </Hidden>
               </TopWrapper>
           </div>

        <div style={{overflowY: "auto !important"}}>
            <div className={classes.claims_rectangle}>
                <div className={classes.claims_title}>
                    <h2>{cfg('Reload Events')}</h2>
                </div>
                <div className={classes.Mform} ref={ref}>
                    <div className={classes.member_input}>
                        <FieldWrapper >
                            <QnectSelectField
                                formId={formId}
                                field="reloadTable"
                                value={table}
                                onChange={(val) => setTable(val)}
                            />
                        </FieldWrapper>
                    </div>
                    <div className={classes.member_input}>
                        <FieldWrapper >
                            <QnectSelectField
                                formId={formId}
                                field="reloadEntity"
                                value={country}
                                options={getCountries()}
                                onChange={(val) => setCountry(val)}
                            />
                        </FieldWrapper>
                    </div>
                    {errorMessage && (
                        <div className={classes.errorMessage}>
                            <QnectError errorTitle={"Error"} errorMessage={errorMessage} />
                        </div>
                    )}
                    <div>
                        <FieldWrapper>
                            <QnectButton
                                field="sync"
                                onClick={() => reloadEvent()}
                            />
                        </FieldWrapper>
                    </div>
                </div>
            </div>
        </div>
       </div>
    );
}

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        user: _getUser(state),
        lang: _getLang(state)
    };
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    syncData: _syncData,
    showModal: _showModal,
    hideModal: _hideModal,
    showError: _showError
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReloadEvents));
