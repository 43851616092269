export const SHOW_ERROR = 'SHOW_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_LANG = 'SET_LANG';
export const SET_OTP_TOKEN = 'SET_OTP_TOKEN';
export const SET_CP_TOKEN = 'SET_CP_TOKEN';
export const SET_CLAIMS_TOKEN = 'SET_CLAIMS_TOKEN';
export const SET_NO_LOGIN = 'SET_NO_LOGIN';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_DOCUMENT_TOKEN = 'SET_DOCUMENT_TOKEN';
export const SET_SHOW_OTP_MODAL = 'SET_SHOW_OTP_MODAL';
export const SET_PORTFOLIO_TOKEN = 'SET_PORTFOLIO_TOKEN';
export const RESET_STATE = 'SYSTEM_RESET_STATE';
export const RESET_PORTFOLIO_TOKEN = 'RESET_PORTFOLIO_TOKEN';
export const RESET_CLAIMS_TOKEN = 'RESET_CLAIMS_TOKEN';
export const RESET_DOCUMENT_TOKEN = 'RESET_DOCUMENT_TOKEN';
export const SET_PAGE_BROKEN = 'SET_PAGE_BROKEN';
export const SET_ADMIN_LOGIN = 'SET_ADMIN_LOGIN';
export const SET_USER = 'SET_USER';
export const SET_TIMER_STARTED = 'SET_TIMER_STARTED';