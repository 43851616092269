import React, { memo } from "react";
import styled from "styled-components";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Close } from "../../assets/Close.svg";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  title: props => ({
    margin: "0 auto",
    fontFamily: "Stag",
    fontWeight: props.lang === "zh" ? "bold" : "500",
    color: "#003DA5",
    letterSpacing: "1px",
    textAlign: "center",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "24px"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "24px",
      lineHeight: "36px"
    }
  }),
  closeWrapper: {
    width: "16px",
    height: "16px",
    position: "absolute",
    top: "21px",
    right: "21px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "24px",
      height: "24px"
    }
  }
}));

const StyledClose = styled(Close)`
  width: 32px;
  height: 32px;
`;

function CustomizedDialogTitle({ title, closable = true, onClose, lang = 'en' }) {
  const classes = useStyles({lang});

  return (
    <DialogTitle>
      <div className={classes.title}>{title}</div>
      {closable && (
        <div className={classes.closeWrapper} onClick={onClose}>
          <StyledClose />
        </div>
      )}
    </DialogTitle>
  );
}

CustomizedDialogTitle.propTypes = {
  title: PropTypes.node,
  closable: PropTypes.bool,
  handleClose: PropTypes.func
};

export default memo(CustomizedDialogTitle);
