import React, {useEffect, useState} from 'react';
import ResetPassword from "../components/reset/reset-password";
import {_decryptResetPasswordToken, _getUser, _storeUser} from "../store/user/service";
import {connect} from "react-redux";
import {_hideModal, _hideSpinner, _setCountry, _setCpToken, _showModal, _showSpinner} from "../store/system/service";
import InvalidLink from "../components/custom/invalid-link"


const ResetPasswordPage = (props) => {
    const params = new URLSearchParams(props.history.location.search);
    const [isLinkValid, setLinkValid] = useState("");

    useEffect(() => {
        let token = params.get('token');
        let iv = params.get('iv');

        props.showSpinner();
        props.decryptResetPasswordToken(token, iv).then((response) => {
            if (response.status === 'SUCCESS') {
                props.user.email = response.email;
                props.user.userType = response.userType;
                props.setCpToken(response.token);
                props.storeUser(props.user);
                props.setCountry(response.country);
                setLinkValid(true);
            } else {
                setLinkValid(false);
            }
            props.hideSpinner();
        }, () => {

            setLinkValid(false);
            props.hideSpinner();
        });
    }, []);

    const getPwComponent = () => {

        if (!isLinkValid) {
             return  <InvalidLink action='reset password' />;
        } else {
            return  <ResetPassword
                    isRegistration={false}/>;
        }
    }

    return (
        <div>
            { getPwComponent() }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: _getUser(state)
    }
}

const mapDispatchToProps = {
    decryptResetPasswordToken: _decryptResetPasswordToken,
    setCpToken: _setCpToken,
    storeUser: _storeUser,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showModal: _showModal,
    hideModal: _hideModal,
    setCountry: _setCountry
};


export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
