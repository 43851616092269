import React from 'react';
import styled from 'styled-components';
import {TextField as MuiTextField} from '@material-ui/core';
import property from 'lodash/property';

const StyledMuiTextField = styled(MuiTextField)`
    &.MuiFormControl-root {
        width: 100%;

        .MuiFormLabel-root {
            color: ${property('theme.color.tertiary90')};
            font-family: ${property('theme.typography.text.fontFamily')};
        }

        .Mui-focused {
            color: ${property('theme.color.primary')};
        }

        .MuiInputBase-root {
            border-radius: 0;
            background-color: ${property('theme.color.grey30')};
            color: ${property('theme.color.black')};
            font-family: ${property('theme.typography.text.fontFamily')};
            font-weight: ${property('theme.typography.text.fontWeight.medium')};
        }
        .MuiInputBase-root.Mui-disabled {
            color: #000 !important;
            cursor: default;
            background-color: #C9CED6;
        }

        .MuiFilledInput-underline::before {
            border-bottom-color: ${property('theme.color.tertiary90')};
        }

        .MuiFilledInput-underline::after {
            border-bottom-color: ${property('theme.color.secondary')};
        }

        .MuiFormHelperText-root {
            margin-top: 0;
            padding: 8px 0 0;
            color: ${property('theme.color.tertiary90')};
            font-family: ${property('theme.typography.text.fontFamily')};
        }
    }

    .MuiFormLabel-root.Mui-error,
    .MuiFormHelperText-root.Mui-error {
        color: ${property('theme.color.error')};
    }

    .MuiFilledInput-underline.Mui-error::after {
        border-bottom-color: #A50000;
    }
`;

const TextInput = React.forwardRef((props, ref) => (
    <StyledMuiTextField
        {...props}
        inputRef={ref}
    />
));

TextInput.displayName = 'TextInput';

export default TextInput;
